import axios from 'axios'
export default{
    namespaced: true,
    state: {
        selectedItem: { item: -1},
        deletedStudent: '',
        dialogSetTerm: false,
        currentTerm: null,
        termRegistrationData: null,
        backdateAcademicTermId: null,
        dialogAddEditSubjects: false,
        editedSubject: {},        
        dialogPostReports: false,
        postReportsAcademicTermId: null,
        formClassesNotPosted: [],
        dialogTermAssesments: false,
    },

    getters: {
        getSelectedItem (state) {
            return state.selectedItem
        },
       
        getDeletedStudent(state){
           return state.deletedStudent
        },

        getDialogSetTerm (state) {
            return state.dialogSetTerm
        },

        getCurrentTerm (state) {
            return state.currentTerm
        },

        getTermRegistrationData (state) {
            return state.termRegistrationData
        },

        getBackdateAcademicTermId (state) {
            return state.backdateAcademicTermId
        },

        getDialogAddEditSubjects (state) {
            return state.dialogAddEditSubjects
        },

        getEditedSubject (state) {
            return state.editedSubject
        },

        getDialogPostReports (state) {
            return state.dialogPostReports
        },

        getPostReportsAcademicTermId (state) {
            return state.postReportsAcademicTermId
        },

        getFormClassesNotPosted (state) {
            return state.formClassesNotPosted
        },

        getDialogTermAssesments (state) {
            return state.dialogTermAssesments
        }
    },

    mutations: {
        setSelectedItem (state, value) {
            state.selectedItem = value
        },

        setDeletedStudent(state, value){
            state.deletedStudent = value
        },

        setDialogSetTerm (state, value) {
            state.dialogSetTerm = value
        },

        setCurrentTerm (state, value) {
            state.currentTerm = value
        },

        setTermRegistrationData (state, value) {
            state.termRegistrationData = value
        },

        setBackdateAcademicTermId (state, value) {
            state.backdateAcademicTermId = value
        },

        setDialogAddEditSubjects (state, value) {
            state.dialogAddEditSubjects = value
        },

        setEditedSubject (state, value) {
            state.editedSubject = value
        },

        setDialogPostReports (state, value) {
            state.dialogPostReports = value
        },

        setPostReportsAcademicTermId (state, value) {
            state.postReportsAcademicTermId = value
        },

        setFormClassesNotPosted (state, value) {
            state.formClassesNotPosted = value
        },

        setDialogTermAssesments (state, value) {
            state.dialogTermAssesments = value
        }
    },

    actions: {
        getTermRegistrations(){
            let url = '/api/term-registration';
            return axios.get(url);
        },

        deleteTermRegistration({ getters }){
            let url = '/api/term-registration';
            return axios.delete(url, {
                data: getters.getDeletedStudent
            })
        },

        getCurrentTerm () {
            let url = '/api/current-term';
            return axios.get(url);
        },

        getNextTerm () {
            let url = '/api/next-term';
            return axios.get(url);
        },

        postCurrentTerm ({ getters }) {
            let url = '/api/current-term';
            return axios.post(url, getters.getCurrentTerm);
        },

        postTermRegistration ({ getters }) {
            let url = '/api/term-registration';
            return axios.post(url, getters.getTermRegistrationData);
        },

        getAvailableTerms () {
            const url = '/api/term-history';
            return axios.get(url);
        },

        backdateTerm ({ getters }) {
            const url = '/api/backdate-term';
            return axios.post(url, {academic_term_id: getters.getBackdateAcademicTermId});
        },

        getSubjects () {
            const url = '/api/subjects';
            return axios.get(url);
        },

        postSubject ({ getters }) {
            const url = '/api/subjects';
            return axios.post(url, getters.getEditedSubject);
        },

        deleteSubject ({ getters }) {
            const url = '/api/subject';            
            return axios.delete(url, { data: { id: getters.getEditedSubject.id }})
        },

        getTermReports  ({ getters }) {
            const url = '/api/term-reports';
            return axios.get(url, {
                params: {
                    academic_term_id: getters.getPostReportsAcademicTermId
                }
            })
        },

        postTermReports ({ getters }) {
            const url = 'api/term-reports';
            return axios.post(url, {
                academic_term_id: getters.getPostReportsAcademicTermId,
                form_classes_not_posted: getters.getFormClassesNotPosted,
                posted: 1
            })
        },

        getTermAssesmentsTerms () {
            const url = '/api/settings-term-assesments-terms';
            return axios.get(url);
        },

        getTermAssesmentsSubjects () {
            const url = '/api/settings-term-assesments-subjects';
            return axios.get(url);
        },

        getTermAssesmentsFormClasses () {
            const url = '/api/settings-term-assesments-form-classes';
            return axios.get(url);
        },

        promoteStudents () {
            const url = '/api/promote';
            return axios.post(url);
        }
    }
}
<template>
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="350"                
                elevation="2"
                outlined                
            >
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>Application Settings</v-toolbar-title>
                </v-toolbar>
                <v-list>                    
                    <v-list-item-group
                        v-model="selectedItem.item"
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="display(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon
                                    color="primary"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        
        <SetTerm/>
        <AddEditSubjects/>
        <PostReports/>
        <TermAssesments/>
    </v-row>        
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SetTerm from './SettingsSetTerm';
import AddEditSubjects from './SettingsAddEditSubjects';
import PostReports from './SettingsPostReports'
import TermAssesments from './SettingsTermAssesments.vue';
export default {
    components: {
        SetTerm,
        AddEditSubjects,
        PostReports,
        TermAssesments,
    },

    data: function () {
        return {
            items: [
                {
                    text: 'Current Term', 
                    icon: 'mdi-calendar-cursor', 
                    value: 'advanceTerm'
                },
                {
                    text: 'Add / Edit Subjects', 
                    icon: 'mdi-bookshelf',
                    value: 'editSubjects'
                },
                {
                    text: 'Post Reports', 
                    icon: 'mdi-upload-lock', 
                    value: 'postReports'
                },
                {
                    text: 'Term Assesments Configuration', 
                    icon: 'mdi-pencil-box-multiple', 
                    value: 'termAssesments'
                }
            ],
            loading: false,
            overlay: false,
            snack: false,
            snackText: '',
            settings:[
                { value: 'advanceTerm', display: this.setDialogSetTerm },
                { value: 'editSubjects', display: this.setDialogAddEditSubjects },
                { value: 'postReports', display: this.setDialogPostReports },
                { value: 'termAssesments', display: this.setDialogTermAssesments },
            ]
        }        
    },

    computed: {
        ...mapGetters({
            selectedItem: 'settings/getSelectedItem',
            dialogTermAssesments: 'settings/getDialogTermAssesments',
        })
    },

    watch: {
       //
    },
    methods: {
        ...mapActions({
            getTermRegistrations: 'settings/getTermRegistrations',
        }),

        ...mapMutations({
            setDeletedStudent: 'settings/setDeletedStudent',
            setDialogSetTerm: 'settings/setDialogSetTerm',
            setSelectedItem: 'settings/setSelectedItem',
            setDialogAddEditSubjects: 'settings/setDialogAddEditSubjects', 
            setDialogPostReports: 'settings/setDialogPostReports',  
            setDialogTermAssesments: 'settings/setDialogTermAssesments',         
        }),

        display(item){
            this.settings.forEach(setting => {
                item.value === setting.value ? setting.display(true) : setting.display(false);
            })
        },

        async initializeTermRegistration(){
            this.loading = true;
            try {
                let response = await this.getTermRegistrations();
                this.termRegistrations = response.data;
                this.loading = false;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        closeDialog(){
            this.dialog = false;
            this.setSelectedItem({item: -1});
        },
        save () {
            this.snack = true;
            this.snackText = 'Data Saved'
        },
        open () {
            this.snack = true;
            this.snackText = 'Dialog opened'
        }
    }
}
</script>
<template>
    <tr>
        <td>
            <DatePicker
                v-bind:initialDate="{ date: record.date }"
            ></DatePicker>
        </td>
        <td>
            <v-select
                v-model="record.assesment_type"
                :items="assesmentTypes"
                outlined
                dense
                hide-details
            ></v-select>
        </td>
        <td>
            <v-select
                v-model="record.form_class_id"
                :items="formClasses"
                outlined
                dense
                small-chips
                hide-details
            ></v-select>
        </td>
        <td>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :close-on-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Edit Subjects
                    </v-btn>
                </template>

                <v-card>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="subjects"
                            hide-default-footer
                            :items-per-page="itemsPerPage"
                            fixed-header
                            show-select
                            dense
                            height="250"
                        ></v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            depressed
                            @click="menu=!menu"
                            color="primary"
                        >
                            Save and Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <!-- <v-select
                v-model="record.subjects"
                :items="subjects"
                item-text="title"
                item-value="id"
                small-chips
                multiple
                deletable-chips
                outlined
                dense
            >
                <template v-slot:selection="{ index }">
                    <span v-if="index === 0">
                        {{ record.subjects.length }} subjects
                    </span>
                </template>
            </v-select> -->
        </td>
    </tr>
</template>

<script>
import DatePicker from './DatePicker.vue'
export default { 
    components: {
        DatePicker,
    },

    props: {
        record: Object,
        subjects: Array,
        formClasses: Array,
    },

    data: function () { 
        return {
            assesmentTypes: [
                'Forthnightly Test',
                'Mid Term',
                'Interim Test'
            ],
            headers: [
                { text: 'Subject', value: 'title'},
                { text: 'Max Mark', value: 'max_mark'}
            ],
            itemsPerPage: -1,
            menu: false,
        }
    },
}
</script>
<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="600"
    >
        <v-card
            class="pa-4"
        >
            <v-data-table
                :headers="headers"
                :items="subjects"
                :search="search"
                fixed-header
                height="50vh"
                :loading="loading"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Subjects</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            class="mx-8"
                            clearable
                        ></v-text-field>

                        <v-dialog
                            v-model="dialogAddSubject"
                            max-width="500px"
                            persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    outlined                                    
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                >
                                    Add Subject
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form 
                                        v-model="valid"
                                        ref="form"                                        
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"                                                
                                                >
                                                    <v-text-field
                                                        v-model="editedSubject.title"
                                                        label="Subject Title"
                                                        :rules="rules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col
                                                    cols="12"                                                
                                                >
                                                    <v-text-field
                                                        v-model="editedSubject.abbr"
                                                        label="Subject Abbreviation"
                                                        :rules="rules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>    
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog
                            v-model="dialogDelete"
                            max-width="600px"
                            persistent
                        >
                            <v-card>
                                <v-card-title class="text-body-1">
                                    Delete {{ editedSubject.title }} ?
                                </v-card-title>
                                <v-card-actions class="px-6">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey darken-1"
                                        @click="closeDelete"
                                        outlined
                                        small
                                    >Cancel</v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="deleteSubjectConfirm"
                                        small
                                    >Delete</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        outlined
                        color="grey darken-2"
                        x-small                        
                        class="mr-2"
                        @click="editSubject(item)"
                    >
                        <v-icon
                            small 
                            left
                        >
                            mdi-pencil
                        </v-icon>
                        Edit
                    </v-btn>

                    <v-btn
                        outlined
                        x-small
                        @click="deleteSubject(item)" 
                        color="grey darken-2"                      
                    >
                        <v-icon
                            small                           
                            left
                        >
                            mdi-delete
                        </v-icon>
                        Delete
                    </v-btn>    

                </template>
            </v-data-table>

            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>

            <v-snackbar
                v-model="snackbar"
                timeout="5000"
                :color="snackColor"
            >
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>

            <v-card-actions class="">
                <v-spacer></v-spacer>
                <v-btn
                    @click="closeAddEditSubjects"
                    depressed
                    small
                >Close</v-btn>
                
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    created () {
        this.initialize();
    },

    data: () => ({
        subjects: [],
        headers: [
            {text: 'Subject', value: 'title'},
            {text: 'Abbr', value: 'abbr'},
            {text: 'Actions', value: 'actions', width: '200', sortable: false},
        ],
        dialogAddSubject: false,
        dialogDelete: false,
        search: '',
        editedIndex: -1,
        editedSubject: {
            id: '',
            title: '',
            abbr: ''
        },
        defaultItem: {
            id: '',
            title: '',
            abbr: ''
        },
        loading: false,
        valid: false,
        rules: [v =>  !!v || 'Field cannot be blank'],
        overlay: false,
        snackbar: false,
        snackText: '',
        snackColor: '',
    }),

    computed: {
        ...mapGetters({
            dialog: 'settings/getDialogAddEditSubjects',
        }),

        formTitle () {
            return this.editedIndex === -1 ? 'New Subject' : 'Edit Subject'
        },
    },

    methods: {
        ...mapActions({
            getSubjects: 'settings/getSubjects',
            postSubject: 'settings/postSubject',
            delete: 'settings/deleteSubject',            
        }),

        ...mapMutations({
            setEditedSubject: 'settings/setEditedSubject',
            setDialog: 'settings/setDialogAddEditSubjects',
            setSelectedItem: 'settings/setSelectedItem',
        }),

        async initialize () {
            this.loading = true;
            try {
                let response = await this.getSubjects();                
                this.subjects = response.data;               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
        },

        editSubject (item) {
            this.editedIndex = this.subjects.indexOf(item);
            this.editedSubject = Object.assign({}, item);
            this.dialogAddSubject = true;            
        },

        deleteSubject (item) {
            this.editedIndex = this.subjects.indexOf(item);
            this.editedSubject = Object.assign({}, item);
            this.dialogDelete = true;           
        },

        async deleteSubjectConfirm () {
            this.setEditedSubject(this.editedSubject);
            this.snackText = `${this.editedSubject.title} deleted`;
            this.closeDelete();

            this.$nextTick(() => {
                this.overlay = true;
            }) 

            try {
                let response = await this.delete();               
                this.snackColor = "primary"; 
                console.log(response);
                response = await this.getSubjects();                
                this.subjects = response.data; 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackText = "Error Occured";
                this.snackColor = "red";
            }            
            
            this.overlay = false;

            this.$nextTick(() => {
                this.snackbar = true;
            })
            
        },

        close () {
            this.dialogAddSubject = false;
            
            this.resetValidation();
            this.$nextTick(() => {
                this.editedSubject = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedSubject = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            })
        },

        async save () {
            let response = null;
            this.$refs.form.validate();
            // console.log(`Valid: ${this.valid}`);
            if(!this.valid) return;
            this.close();
            this.overlay = true;
            try {
                this.setEditedSubject(this.editedSubject);
                response = await this.postSubject();
                this.snackText = "Subject Saved";
                this.snackColor = "primary";                
                response = await this.getSubjects();                
                this.subjects = response.data; 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackText = "Error Occured";
                this.snackColor = "red";
            }
            this.overlay = false;
            this.$nextTick(() => {
                this.snackbar = true;
            })
        },

        resetValidation () {
            this.$refs.form.resetValidation();
        },

        closeAddEditSubjects () {
            this.setSelectedItem({item: -1});
            this.setDialog(false);
        }
    }
}
</script>
<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
</template>

<script>
export default {
    props: {
        label: String,
        model: String,
        initialDate: String,
    },

    created: function () {
      //
    },

    data: function () {
        return {
            menu: false,
            // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date: this.initialDate,
        }
    },

    watch: {
        date: {
            handler (val) {
                this.$emit('date-selected', { model: this.model, date: val})
            },
        },

        initialDate: {
          handler (val) {
            this.date = val
          }
        }
    }
}
</script>
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        editedStudent: {},
        dataGeneral: {},
        dataPersonal: {},
        dataFamily: {},
        dataMedical: {},
        dataFiles: {},
        classRegistration: {},        
    },
    getters: {
        getEditedStudent(state){
            return state.editedStudent
        },

        getDataGeneral (state) {
            return state.dataGeneral
        },

        getDataPersonal (state) {
            return state.dataPersonal
        },

        getDataFamily (state) {
            return state.dataFamily
        },

        getDataMedical (state) {
            return state.dataMedical
        },

        getDataFiles (state) {
            return state.dataFiles
        },

        getClassRegistration (state) {
            return state.classRegistration
        }
    },
    mutations: {
        setEditedStudent(state, value){
            state.editedStudent = value
        },

        setDataGeneral (state, value) {
            state.dataGeneral = value
        },

        setDataPersonal (state, value) {
            state.dataPersonal = value
        },

        setDataFamily (state, value) {
            state.dataFamily = value
        },

        setDataMedical (state, value) {
            state.dataMedical = value
        },
        
        setClassRegistration (state, value) {
            state.classRegistration = value
        }

    },
    actions: {      

        postStudent({ getters }){
            const url = '/api/student';
            return axios.post(url, getters.getEditedStudent);
        },

        deleteStudent({ getters }){
            const url = '/api/student';
            return axios.delete(url, { data: getters.getEditedStudent });
        },

        getStudentStatus(){
            const url = '/api/student-status';
            return axios.get(url);
        },

        getDataPersonal ({ getters }){
            let url = '/api/student-data-personal';
            if(getters.getEditedStudent.student_id)
            url += '/' + getters.getEditedStudent.student_id;
            return axios.get(url);
        },

        postDataPersonal ({ getters }) {
            const url = '/api/student-data-personal';            
            return axios.post(url, getters.getDataPersonal);
        },        

        getDataMedical ({ getters }) {
            let url = '/api/student-data-medical';
            if(getters.getEditedStudent.student_id)
            url += '/' + getters.getEditedStudent.student_id;
            return axios.get(url);
        },

        postDataMedical ({ getters }) {
            const url = '/api/student-data-medical';
            return axios.post(url, getters.getDataMedical);
        },

        getDataFamily ({ getters }) {
            let url = '/api/student-data-family';
            if(getters.getEditedStudent.student_id)
            url += '/' + getters.getEditedStudent.student_id;
            return axios.get(url);
        },

        postDataFamily ({ getters }) {
            const url = '/api/student-data-family';
            return axios.post(url, getters.getDataFamily);
        },

        getReligiousGroups () {
            const url = '/api/religions';
            return axios.get(url);
        },

        getEthnicGroups () {
            const url = '/api/ethnic-groups';
            return axios.get(url);
        },

        postClassRegistration ({ getters }) {
            const url = '/api/student-class-registration';
            return axios.post(url, getters.getClassRegistration)
        },

        getHouses () {
            const url = '/api/houses';
            return axios.get(url);
        }
    }
}    
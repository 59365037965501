<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
    >
        <v-card>
            <v-card-title
                class="text-h5 white--text primary"
                
            >
                Signature
            </v-card-title>
            <v-card-text class="mt-4">
                <v-text-field
                    v-model="principalSignature"
                    outlined
                    hide-details
                    style="font-family: 'Brush Script MT', cursive; font-size: 24px "
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    depressed
                    @click="updateRecord"
                    class="mr-4"
                >
                    Sign Report
                </v-btn>
                <v-btn
                    depressed
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        dialog: Boolean,
        signature: String,
    },

    data: function () {
        return {
            principalSignature: null,
        }
    },

    watch: {
        dialog: {
            handler (val) {
                if(val && this.signature){
                    this.principalSignature = this.signature
                }
            }
        }
    },

    computed: {        
        ...mapGetters({
            studentRecord: 'termReports/getStudentRecord',
        })
    },

    methods: {
        ...mapActions({
            postStudentDetails: 'termReports/postStudentTermDetails',
        }),

        ...mapMutations({
            setStudentRecord: 'termReports/setStudentRecord',
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaved: 'termReports/setSaved',
            setSaveError: 'termReports/setError',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating',
            setOverlay: 'termReports/setOverlay',
            setLoading: 'termReports/setLoading',
        }),

        async updateRecord(){
            console.log('updating...');
            this.setUpdating(true);
            this.setSavedToDb(false);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            this.setOverlay(true);
            this.setLoading(true);
            let snackbar = {};
            this.$emit('close-signature-dialog');
            try{
                this.studentRecord.principal_signature = this.principalSignature
                await this.postStudentDetails();
                this.$emit('set-signature', this.principalSignature)
                snackbar.color = "primary";
                snackbar.text = "Report Signed Successfully.";
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                this.setUpdating(false);
            } catch (error) {
                console.log(error.response);
                this.setSaveStatus('Error Occured');
                snackbar.color = "red";
                snackbar.text = "An error occured, report not signed";
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                //this.setErrorResponse(error);
            }
            this.setOverlay(false);
            this.setLoading(false);
            this.$emit('display-snackbar', snackbar)

        },

        close () {
            this.$emit('close-signature-dialog');
        },

        
    }
}
</script>
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        dialogEmployeeNew: false,
        editedIndex: -1,
        employees: [],        
        statuses: [],
        snack: false,
        snackText: '',        
        editedEmployee: {},
        deletedEmployee: {},  
    },
    getters: {
        getDialogEmployeeNew(state){
            return state.dialogEmployeeNew
        },
        getEditedIndex(state){
            return state.editedIndex
        },
        getEmployees(state){
            return state.employees
        },
        getDeletedEmployee(state){
            return state.deletedEmployee
        },
        getEmployeeStatuses(state){
            return state.statuses
        },
        getSnack(state){
            return state.snack
        },
        getSnackText(state){
            return state.snackText
        },        
        getEditedEmployee(state){
            return state.editedEmployee
        },
         
        
    },
    mutations: {
        setDialogEmployeeNew(state, value){
            state.dialogEmployeeNew = value
        },
        setEditedIndex(state, value){
            state.editedIndex = value
        },
        setEmployees(state, value){
            state.employees = value
        },
        setDeletedEmployee(state, value){
            state.deletedEmployee = value
        },
        setEmployeeStatuses(state, value){
            state.statuses = value
        },
        setSnack(state, value){
            state.snack = value
        },
        setSnackText(state, value){
            state.snackText = value
        },        
        setEditedEmployee(state, value){
            state.editedEmployee = value
        }, 
    },

    actions: {
        getEmployees(){
            let url = '/api/employees';
            return axios.get(url);
        },

        getTeachers () {
            const url = '/api/teachers';
            return axios.get(url);
        },

        getEmployeeStatuses(){
            let url = 'api/employee-statuses';
            return axios.get(url);
        },

        deleteEmployee({ getters }){
            let url = 'api/employee';
            return axios.delete(url, { data: getters.getDeletedEmployee });
        },

        postEmployee({ getters }){
            let url = 'api/employee';
            return axios.post(url, getters.getEditedEmployee);
        },
    }
}
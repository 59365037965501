import axios from 'axios'
export default{
    namespaced: true,
    state: {
        employeePersonal: null,
        employeeId: null,  
        employeeEmploymentDetails: null, 
        employeeQualifications: [],
        employeeFileId: null,    
    },

    getters: {
       getEmployeePersonal (state) {
        return state.employeePersonal
       },

       getEmployeeId (state) {
        return state.employeeId
       },

       getEmployeeEmploymentDetails (state) {
        return state.employeeEmploymentDetails
       },

       getEmployeeQualifications (state) {
        return state.employeeQualifications
       },

       getEmployeeFileId (state) {
        return state.employeeFileId
       },

    },

    mutations: {
       setEmployeePersonal (state, value) {
        state.employeePersonal = value
       },

       setEmployeeId (state, value) {
        state.employeeId = value
       },

       setEmployeeEmploymentDetails (state, value) {
        state.employeeEmploymentDetails = value
       },

       setEmployeeQualifications (state, value) {
        state.employeeQualifications = value
       },

       setEmployeeFileId (state, value) {
        state.employeeFileId = value
       }
    },

    actions: {
        postEmployee ({ getters }) {
            const url = '/api/employee'
            return axios.post(url, getters.getEmployeePersonal)
        },
        
        getEmployeePersonal ({ getters }) {
            const url = '/api/employee'
            return axios.get(url, {
                params: {
                    employee_id: getters.getEmployeeId
                }
            })
        },

        getEmploymentDetails ({ getters }) {
            const url = '/api/employee-employment-details';
            return axios.get(url, {
                params: {
                    employee_id: getters.getEmployeeId
                }
            })
        },

        postEmployeeEmploymentDetails ({ getters }) {
            const url = '/api/employee-employment-details';
            return axios.post(url, getters.getEmployeeEmploymentDetails)
        },

        getEmployeePosts () {
            const url = '/api/employee-posts';
            return axios.get(url);
        },

        getFormClasses () {
            const url = '/api/form-classes-list';
            return axios.get(url)
        },

        getSubjects () {
            const url = '/api/subjects';
            return axios.get(url)
        },

        getEmployeeQualifications ({ getters }) {
            const url = '/api/employee-qualifications';
            return axios.get(url, {
                params: {
                    employee_id: getters.getEmployeeId
                }
            })
        },

        postEmployeeQualifications ({ getters }) {
            const url = '/api/employee-qualifications';
            return axios.post(url, {
                qualifications: getters.getEmployeeQualifications,
                employee_id: getters.getEmployeeId
            })
        },

        getQualifications () {
            const url = '/api/qualifications';
            return axios.get(url);
        },

        getFiles ({ getters }) {
            const url = '/api/employee-files';
            return axios.get(url, {
                params: {
                    employee_id: getters.getEmployeeId
                }
            })
        },

        deleteFile ({ getters }) {
            const url = '/api/employee-files';
            return axios.delete(url, {
                data: {
                    id: getters.getEmployeeFileId
                }
            })
        }
    }
}
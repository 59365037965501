<template>
    <v-container class="pb-0">
        <v-card width="640" class="mx-auto" flat> 
        <v-data-table
            :items="termMarkRecords"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer                                                 
        >
            <template v-slot:item="props">
                <Row
                    v-bind:studentRecord="props.item"
                    v-bind:grade="grade"
                />
            </template>
        </v-data-table>
        </v-card>
        

    </v-container>
</template>

<script>
import Row from './MarkSheetRow';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    components: {
        Row,
    },

    props: {
        formClassAssesment : {
            type: String,
            default: null,
        }
    },

    data: () =>({
        loading: false,
        height: "52vh",
        pagination: false,
        footer: false,                
        headers: [],
    }),
    
    watch: {
        termMarkRecords: function () {
            this.initialize();
        }
    },

    computed:{
        ...mapGetters({            
            termMarkRecords: 'markSheet/getTermMarkRecords',
            lessonSelected: 'markSheet/getLessonSelected',
            term: 'app/getTerm',
            assesmentCriteria: 'markSheet/getAssesmentCriteria',
        }),

        grade () {
            if(
                this.lessonSelected.form_class_id == '1st Year' && 
                (
                    this.lessonSelected.subjectCode == 6 ||
                    this.lessonSelected.subjectCode == 7 ||
                    this.lessonSelected.subjectCode == 8 ||
                    this.lessonSelected.subjectCode == 9
                )
            ) return true;

            if(
                this.lessonSelected.form_class_id != '1st Year' && 
                (
                    this.lessonSelected.subjectCode == 7 ||
                    this.lessonSelected.subjectCode == 8 ||
                    this.lessonSelected.subjectCode == 9
                )
            ) return true;

            return false;
        },

      
    },

    methods: { 
       ...mapMutations({
        //
       }),

       ...mapActions({
            //
       }),

       initialize () {
        // console.log("initializing mark sheet table")
        this.setHeaders();
       },

       setHeaders () {
            this.headers = [];
            this.headers.push({text: '#', value: 'count', width: '60', sortable: false})
            if (
               this.formClassAssesment == 'Grade'
            ) {
                this.headers.push({text: 'Name', value: 'name', width: '200',  sortable: false})
                this.assesmentCriteria.forEach(criteria => {
                    this.headers.push({text: criteria.detail, value: 'name',  width: '120', sortable: false});
                });
                return;
            }
            
            if(
                this.lessonSelected.form_class_id == '1st Year' &&
                (
                    this.lessonSelected.subjectCode == 6 ||
                    this.lessonSelected.subjectCode == 7 ||
                    this.lessonSelected.subjectCode == 8 ||
                    this.lessonSelected.subjectCode == 9
                )
            ){
                this.headers.push({text: 'Name', value: 'name', width: '300', sortable: false})
                this.headers.push({text: 'Grade', value: 'grade', align: 'center', width: '120', sortable: false,})
                this.headers.push({text: ' ', value: 'actions', align: 'center', sortable: false})
                return;
            }
            
            if( 
                this.lessonSelected.form_class_id != '1st Year' &&
                (
                    this.lessonSelected.subjectCode == 7 ||
                    this.lessonSelected.subjectCode == 8 ||
                    this.lessonSelected.subjectCode == 9
                )
            )  {
                this.headers.push({text: 'Name', value: 'name', width: '300',  sortable: false})
                this.headers.push({text: 'Grade', value: 'grade', align: 'center', width: '120', sortable: false,})
                 this.headers.push({text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'})
                return;
            }
            
            this.headers.push({text: 'Name', value: 'name', width: '200',  sortable: false})
            this.headers.push({text: 'Max Mark', value: 'maxMark', align: 'center', width: '90', sortable: false,})
            this.headers.push({text: 'Earned', value: 'examMark', align: 'center', width: '90', sortable: false,})
            this.headers.push( {text: '%', value: 'percentage', align: 'center', width: '90', sortable: false,})

            this.headers.push({text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'})

            // console.log(this.headers)
            
       }
        
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    ::v-deep .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
        padding-right: 24px
    }
    th span{
        display: flex;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 0.8rem;
        font-weight: bold;
        color: rgb(80,80,80);
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd){
        background-color: rgb(250, 250, 250);
    }
</style>
<template>
    <v-container class="pt-0 pb-0">
        <v-row
            align='center'
        >
            <v-col cols="4">
                <v-card 
                    tile
                    width="230"
                    class="py-0"
                    flat
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0"
                        two-line
                    >
                        <v-list-item-group color="primary">
                            
                            <v-list-item>                                
                                <v-list-item-content
                                    class="pa-0 mb-3"
                                >
                                    <v-list-item-title>
                                        <div class="pr-1 font-weight-light">Subject:</div>
                                        <div class="font-weight-bold" style="white-space: break-spaces">{{ lessonSelected.subjectTitle }}</div>                                    
                                        <div class="font-weight-bold" style="white-space: break-spaces">{{ markSheetTestTitle }}</div>                                    
                                    </v-list-item-title>                                    
                                </v-list-item-content> 
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>                    
                    
                </v-card>                
            </v-col>
            <v-col
                class="d-flex align-end mb-2"
                cols="5"
            >
                <div class="d-flex pa-1">
                    <v-card flat width="80">
                        <v-text-field
                            label="Class"
                            class="mr-1 caption centered-input small-input"
                            dense
                            outlined                        
                            v-model="lessonSelected.className"
                            hide-details
                            disabled
                        ></v-text-field>
                    </v-card>
                    <v-card flat width="90">
                        <v-text-field
                            label="Year"
                            class="mr-1 caption centered-input"
                            dense
                            outlined
                            v-model="academicYear"
                            hide-details
                            disabled
                        ></v-text-field>
                    </v-card>
                    <v-card flat width="70">
                        <v-text-field
                            label="Term"
                            v-model="term"
                            class="mr-1 caption centered-input small-input"
                            dense
                            outlined
                            hide-details
                            disabled
                        ></v-text-field>
                    </v-card>
                </div>
            </v-col>                         
            <v-col
                cols="3"
            >
                <v-btn  
                    color="primary"
                    dark
                    @click="nextSubjectClass"
                >Next Subject</v-btn>
            </v-col>          
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    watch: {
        studentSelected: {
            handler: function (val) {
                //console.log('marksheetheader:', val);
                this.studentName = val.studentName;
                if(val.picture === null){
                    this.imageSrc = null;
                }
                else{
                    this.imageSrc = "/img/students/" + val.picture;                    
                }
            },
            deep: true,
        },
        
        studentsRegistered: {
            handler: function (val) {
                this.classSummary[1].value = val;
            }
        },
        studentsMarksEntered: {
            handler: function (val) {
                this.classSummary[2].value = val;
            }
        }
    },

    computed: {
        ...mapGetters({
            academicYear: 'app/getAcademicYear',
            term: 'app/getTerm',
            lessonSelected: 'markSheet/getLessonSelected',
            studentsTotal: 'markSheet/getTotalStudents', 
            markSheetTest: 'markSheet/getTest'           
        }),        
           
        markSheetTestTitle () {
            if(this.markSheetTest) return this.markSheetTest.title;
            return null;
        }
    },

    data(){
        return {                     
            nameLabel: "Name",
            imageSrc: '',
            studentName: '',          
        }
    },

    methods: {
        ...mapMutations({
            setOverlay: 'markSheet/setMarkSheetOverlay',
            setTermMarkRecords: 'markSheet/setTermMarkRecords', 
            setDisplayLessons: 'markSheet/setDisplayLessons',           
        }),


        nextSubjectClass(){
            this.setTermMarkRecords([]);
            this.setOverlay(true);
            this.setDisplayLessons(true);
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input{
        text-align: center;
    }
    .small-input >>> input{
        /* width: 35px;; */
    }
    .v-list-item{
        min-height: 0;
    } 
    .term-name >>> input{
        width: 100px !important
    }  
</style>
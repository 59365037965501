<template>    
    <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="1100"
                
            >
                <v-card-title
                    class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                >
                    <v-progress-circular
                        indeterminate
                        v-show="saving"
                        size="16"
                        width="3"
                        class="ml-3"
                        color="secondary"
                    ></v-progress-circular>                
                
                    <v-icon
                        small
                        color="secondary"
                        v-show="saved"
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>

                    <v-tooltip 
                        top               
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                color="secondary"
                                v-show="savedToDb"
                                class="ml-3"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-cloud-check-outline
                            </v-icon>                                
                        </template>
                        <span>Record Saved</span>
                    </v-tooltip>

                    <v-icon
                        small
                        color="red"                   
                        v-show="saveError"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>

                    <span
                        class="secondary--text ml-4"
                    >{{ saveStatus }}</span>

                    <v-spacer></v-spacer>
                    <span>Edit / View Term Reports</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="close"
                        v-if="admin"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn> 
                </v-card-title>
                <v-card-text
                class="pb-0"
                >
                    <v-row>
                        <StudentDetails
                            v-bind:admin="admin"
                            v-bind:teacherClasses="teacherClasses"
                            v-bind:fortnightlyAssesment="fortnightlyAssesment"
                            @close="close"
                        />
                        <subject-records
                            v-bind:admin="admin"
                            v-bind:fortnightlyAssesment="fortnightlyAssesment"
                            v-bind:formClassAssesment="formClassAssesment"
                        ></subject-records>
                        <behaviour-records
                            v-bind:admin="admin"
                        ></behaviour-records>
                    </v-row>
                </v-card-text>
                
                <v-overlay
                    absolute
                    :value="overlay"
                    color="grey"
                    opacity="0.6"
                >
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="primary"
                    ></v-progress-circular>
                    <v-expand-transition>                            
                        <v-card
                            width="300"
                            class="mx-auto"
                            transition="scroll-y-transition"
                            v-show="expand"
                        >
                            <v-toolbar
                                color="primary"
                                dark
                            >
                                <v-toolbar-title>Select Class</v-toolbar-title>
                            </v-toolbar>
                                <div style="max-height: 400px; width: 300px; overflow-y: auto">    
                                <v-list light >
                                    <v-list-group
                                        v-for="item in formClasses"
                                        :key = "item.title"
                                        v-model="item.active"
                                        :prepend-icon="item.action"
                                        no-action
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    {{ item.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item
                                            v-for="subItem in item.items"
                                            :key="subItem.title"
                                            @click="setSelectedClass(subItem)"                                       
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ subItem.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                            </div>
                        </v-card>
                    </v-expand-transition>
                </v-overlay>
                                    
            </v-card>
        </v-col>

        <v-bottom-sheet 
            v-model="sheet" 
            inset
            persistent
        >
            
            <v-sheet class="text-center px-10" height="200px">
                <v-row justify="end">
                    <div>
                        <v-btn
                            class="mt-1"
                            text
                            color="blue darken-3"
                            @click="closeBottomSheet"
                        >close</v-btn>
                    </div>
                </v-row>                
                <v-form ref="form">
                    <v-textarea                                       
                        :label="sheetTitle"                        
                        :hint="hint"
                        rows="3"                                           
                        counter                                             
                        no-resize
                        v-model="studentRecord[sheetModel]"
                        autofocus                        
                        persistent-hint
                        :maxlength="maxLength"                                                                                                                 
                    >                        
                    </v-textarea>
                </v-form>                    
            </v-sheet>
        </v-bottom-sheet>           
    </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import StudentDetails from './EditViewTermReportsStudentDetails';
import SubjectRecords from './EditViewTermReportsSubjectRecords';
import BehaviourRecords from './EditViewTermReportsBehaviour';
export default {
    components: {
        StudentDetails,
        SubjectRecords,
        BehaviourRecords,
    },
    created(){
        this.initialize();        
    },
    data: () => ({       
       error: false,
       errorMessage: '',       
       rules: [
            value => {
                if( value.length <= 215){
                    
                    return true;
                }
                else{
                    return 'Max 215 characters';
                }
            }
        ],
        formClasses: [],
        comments: '', 
        admin: false,
        teacherClasses: [], 
        teacherAssignedClass: {},    
        fortnightlyAssesment: false, 
        formClassAssesment: null,     
    }),
    computed: {
        ...mapGetters({            
            overlay: 'termReports/getOverlay',
            loading: 'termReports/getLoading',
            expand: 'termReports/getExpand',
            academicTermId: 'app/getAcademicTermId',
            academicYearId: 'app/getAcademicYearId',
            getUser: 'auth/getUser',
            sheet: 'termReports/getSheet',
            sheetTitle: 'termReports/getSheetTitle',
            sheetModel: 'termReports/getSheetModel',
            studentRecord: 'termReports/getStudentRecord',
            saveStatus: 'termReports/getSaveStatus',
            saving: 'termReports/getSaving',
            saveError: 'termReports/getError',
            saved: 'termReports/getSaved',
            savedToDb: 'termReports/getSavedToDb',
            updating: 'termReports/getUpdating', 
            formClassAssignment: 'termReports/getFormClassAssignment',
            employeeId: 'termReports/getEmployeeId', 
            maxLength: 'termReports/getSheetMaxLength',
        }),
        
        user () {
            return JSON.parse(this.getUser);
        },

        hint () {
            return `Max ${this.maxLength} charcters`;
        }
    },

    watch: {
        updating: {
            handler (value) {
                if(!value){ 
                    setTimeout(() => {
                        this.setSaved(false);
                        this.setSaveStatus('');
                        this.setSavedToDb(true); 
                    }, 1500);  
                }
            }
        }
    },
    
    methods: {
        async initialize(){
            this.setOverlay(true);
            this.setExpand(false);
            this.setLoading(true);
            const { data } = await this.getForthnightlyAssesments(); 
            if(data.length != 0) this.fortnightlyAssesment = true;
            await this.setFormClasses();           
            
            await this.parseUserPermissions(this.user);
                   
        },

        parseUserPermissions ({ permissions }) {
            permissions.forEach(permission => {
                if(permission.user_permission_id == 9)
                this.admin = true;
            });

            if(this.admin){
                this.setLoading(false)
                setTimeout(() => this.setExpand(true));
                           
            }
            else{
                this.setSelectedClass(this.teacherAssignedClass);
            }

            
        },

        ...mapMutations({
            setOverlay: 'termReports/setOverlay',
            setLoading: 'termReports/setLoading',
            setExpand: 'termReports/setExpand',
            setSelectedFormClass: 'termReports/setSelectedClass',
            setAcademicTermId: 'termReports/setAcademicTermId',
            setAcademicYearId: 'termReports/setAcademicYearId',
            setEmployeeId: 'termReports/setEmployeeId',
            setStudentRecord: 'termReports/setStudentRecord',
            setStudentsRecords: 'termReports/setStudentsRecords',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setPagination: 'termReports/setPagination',
            setFormClassAssignment: 'termReports/setFormClassAssignment',
            setFormDeanAssignments: 'termReports/setFormDeanAssignments',
            setCurrentPage: 'termReports/setCurrentPage',
            setSheet: 'termReports/setSheet',
            setSaveStatus: 'termReports/setSaveStatus',            
            setSaved: 'termReports/setSaved',
            setSavedToDb: 'termReports/setSavedToDb',
            setSubjects: 'termReports/setSubjects',
            setCourseWorkOnly: 'termReports/setCourseWorkOnly',
            setEmployees: 'termReports/setEmployees',
            setEmployeePost: 'termReports/setEmployeePost',
            setSelectedFormClassMarkSheet: 'markSheet/setSelectedFormClass',
        }),

        ...mapActions({
            getFormClassesList: 'termReports/getFormClassesList',
            getStudentTermDetails: 'termReports/getStudentTermDetails',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            getFormTeacherAssigment: 'termReports/getFormTeacherAssignment',
            getFormDeanAssignment: 'termReports/getFormDeanAssignments',
            getSubjects: 'timetable/getSubjects',
            getTermConfiguration: 'termReports/getTermConfiguration',
            getEmployees: 'employees/getEmployees', 
            getEmployeePost: 'termReports/getEmployeePost',
            getForthnightlyAssesments: 'markSheet/getForthnightlyAssesments',
            getTermAssesmentAssignment: 'markSheet/getTermAssesmentAssignment',

        }),

        async setFormClasses(){
            try {
                const promiseEmployees = this.getEmployees();
                const promiseFormClasses = this.getFormClassesList();

                const [ 
                    { data: teacherClasses }, 
                    { data: employees},
                ] = await Promise.all([
                    promiseFormClasses, 
                    promiseEmployees,
                ]);
                 
                this.mapFormClasses(teacherClasses);
                 
                this.mapTeacherClasses(teacherClasses);
                this.setEmployees(employees);

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        mapTeacherClasses (data) {
            data.forEach(record => {
                if(!this.teacherClasses.includes(record.form_class_id)){
                    this.teacherClasses.push(record.form_class_id);
                }
            })
        },

        mapFormClasses (data) {
            if(data.length > 0){                    
                    let firstYearClasses = [];
                    let secondYearClasses = [];
                    let std1Classes = [];
                    let std2Classes = [];
                    let std3Classes = [];
                    let std4Classes = []; 
                    let std5Classes = []; 

                    data.forEach(record => {
                        let coTeachers = null;
                        let classTitle = null;

                        if(record.employee_id == this.user.employee_id){
                            this.teacherAssignedClass = {
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            }
                        }

                        if(record.coTeachers){
                            let coTeachersArray = [];
                            coTeachersArray.push(record.first_name[0] + ". " + record.last_name);
                            record.coTeachers.forEach(coTeacher => {
                                if(coTeacher.employee_id == this.user.employee_id){
                                    this.teacherAssignedClass = {
                                        form_class_id: record.form_class_id,
                                        employee_id: record.employee_id
                                    }
                                }
                                coTeachersArray.push(coTeacher.name);
                            })
                            coTeachers = coTeachersArray.join('/'); 
                        }
                        
                        classTitle = coTeachers ? coTeachers : record.first_name[0] + ". " + record.last_name;

                        switch(record.form_class_id){
                            case "1st Year":
                            firstYearClasses.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                            case "2nd Year":
                            secondYearClasses.push({
                               title: classTitle,
                               form_class_id: record.form_class_id,
                               employee_id: record.employee_id
                            });
                            break;
                            case "Std 1":
                            std1Classes.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                            case "Std 2":
                            std2Classes.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                            case "Std 3":
                            std3Classes.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                            case "Std 4":
                            std4Classes.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                            case "Std 5":
                            std5Classes.push({
                                title: classTitle,
                                form_class_id: record.form_class_id,
                                employee_id: record.employee_id
                            });
                            break;
                        }                
                    });
                    this.formClasses.push({
                        title: '1st Year Infants',
                        action: 'mdi-school',                
                        items: firstYearClasses
                    });
                    this.formClasses.push({
                        title: '2nd Year Infants',
                        action: 'mdi-school',                
                        items: secondYearClasses
                    });
                    this.formClasses.push({
                        title: 'Standard 1',
                        action: 'mdi-school',                
                        items: std1Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 2',
                        action: 'mdi-school',                
                        items: std2Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 3',
                        action: 'mdi-school',                
                        items: std3Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 4',
                        action: 'mdi-school',                
                        items: std4Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 5',
                        action: 'mdi-school',                
                        items: std5Classes
                    });
                                             
                }
        },

        async setSelectedClass(formClass){
            this.setExpand(false);
            setTimeout(() => this.setLoading(true), 1000);           
            this.setSelectedFormClass(formClass);            
            this.setAcademicTermId(this.academicTermId);
            this.setAcademicYearId(this.academicYearId);
            this.setEmployeeId(this.user.employee_id);
            // this.setSelectedFormClass(formClass);            
            // this.setAcademicTermId(this.academicTermId);
            // this.setAcademicYearId(this.academicYearId);
            // this.setEmployeeId(this.user.employee_id);
            try {
                this.setSelectedFormClassMarkSheet(formClass.form_class_id);
                const { data: dataTermAssesmentAssignment } = await this.getTermAssesmentAssignment();
                this.formClassAssesment = dataTermAssesmentAssignment?.detail;
                
                const formTeacherAssignmentPromise = this.getFormTeacherAssigment();
                const studentTermDetailsPromise = this.getStudentTermDetails();
                const getSubjectsPromise = this.getSubjects();
                
                const { data } = await formTeacherAssignmentPromise;
                let formClassAssignments = [];
                if(data.id){
                    formClassAssignments.push(data.form_class_id);
                    this.setFormClassAssignment(formClassAssignments);
                }
                
                const { data: dataSubjects } = await getSubjectsPromise;
                this.setSubjects(dataSubjects);                
                
                            
                const { data: dataTermDetails } = await studentTermDetailsPromise;
                this.setStudentRecord(dataTermDetails[0]);
                this.setStudentsRecords(dataTermDetails);
                this.makePagination(dataTermDetails);
                
                          
                const { data: dataTermMarks } = await this.getStudentTermMarks();
                this.parseStudentTermMarks(dataTermMarks);
                this.setSavedToDb(true); 
                
                
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setLoading(false);
            setTimeout(() => this.setOverlay(false));            
        },

        parseStudentTermMarks (data) {
            let termMarks = [];
            data.forEach(value =>{
                if(!this.fortnightlyAssesment){
                    value.percentage = null;

                    if(value.mark){
                        let percentage = (value.mark / value.max_mark) * 100;
                        value.percentage = percentage.toFixed();
                    }
                }
                
                if(value.grade){
                    value.max_mark = "Grade";
                    value.mark = value.grade
                }
                termMarks.push(value)
            })
            this.setStudentTermMarks(termMarks);
        },

        async setStudentRecordDisplay(){
            try {
                const { data: dataTermDetails } = await this.getStudentTermDetails();
                this.makePagination(dataTermDetails);
                if(dataTermDetails.data.length > 0){                    
                    this.setStudentRecord(dataTermDetails.data[0]);
                }
                
                const { data: dataTermMarks } = await this.getStudentTermMarks();
                this.setStudentTermMarks(dataTermMarks);
                
                this.setLoading(false);
                setTimeout(() => this.setOverlay(false));
            } catch (error) {
                console.log(error);
            }
        },
        makePagination(data){
            let pagination = {
                current_page: 1,
                last_page: data.length,
                next_page: 2,
                prev_page: null,
            }
            this.setPagination(pagination);
        },
        close(){
            this.setOverlay(true);
            this.setExpand(true);
        },
        closeBottomSheet(){
            this.setSheet(false);            
                     
        }
    }

}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 15px;
    }
</style>
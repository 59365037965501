<template>
    <v-dialog
        :value="dialog"
        max-width="780px"
        persistent
    >
        <v-card 
            ref="form"
            style="overflow:hidden"
        >
            <v-card-title style="position:relative">
                <span class="headline">Profile</span>
                <v-btn
                    color="red"
                    absolute
                    top
                    right
                    text
                    fab
                    small
                    @click="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-row 
                justify="center"
                class="mx-0 mb-4"
            >
            
                <v-card
                    flat 
                    width="250"
                    class="px-3 d-flex align-center flex-column"  
                >
                    <v-card height="100" flat>
                        <v-row>
                            <v-col class="">
                                    <v-text-field
                                        label="Registration #"                            
                                        outlined                            
                                        dense
                                        class="mt-4 record-summary"                            
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat>
                        <v-row>
                            <v-col>
                                <v-card
                                    outlined
                                    class="mx-auto d-flex justify-center align-center"
                                    max-width="200"
                                >
                                    <v-img>
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>

                                <v-card
                                    v-if="src"
                                    outlined
                                    class="mx-auto"
                                    width="200"
                                    height="230"
                                >
                                    <v-img 
                                        :src="src"
                                        height="228"
                                        position
                                    >
                                    </v-img>
                                </v-card>
        
                                <v-card
                                    v-else
                                    outlined
                                    class="mx-auto d-flex justify-center align-center"
                                    width="200"
                                    height="200"
                                >
                                    <v-card-text
                                        class="my-auto" style="text-align:center"
                                    >
                                        Picture
                                    </v-card-text>
                                </v-card>
                            </v-col>    
                        </v-row>    
                    </v-card>
                    

                    <v-row v-if="admin">
                        <v-col class="pb-0">
                            <v-file-input
                                :value="pictureFile"
                                text
                                dense
                                outlined
                                @change="uploadPicture"
                                placeholder="Add/Change Picture"
                                prepend-icon="mdi-camera"
                                hide-details
                            >
                            </v-file-input>
                        </v-col>
                    </v-row>

                    <v-row v-if="pictureFile">
                        <v-col>
                            <v-card
                                class="mx-auto d-flex justify-center align-center"
                                width="200"
                            >
                                <v-progress-linear
                                    v-model="progress"
                                    color="blue-grey"
                                    height="25"
                                    
                                    reactive
                                >
                                    <strong>{{ progress }} %</strong>
                                </v-progress-linear>
                            </v-card>
                        </v-col>
                    </v-row>
                    
                    <v-alert v-if="pictureUploadMessage" border="left" color="primary" dark>
                        {{ pictureUploadMessage}}
                    </v-alert>  

                    <v-row>
                        <v-col>
                            <v-card>
                                <v-btn
                                    outlined
                                    color="primary"
                                    block
                                    @click="dialogDocuments=true"
                                >Add/View Documents</v-btn>
                            </v-card>
                        </v-col>
                    </v-row>     

                    
                </v-card>
                
                <v-card
                    width="500"
                    flat                
                    tile
                    style="border-left: thin solid rgba(0, 0, 0, 0.12)"
                    class=""                                    
                >
                    <v-sheet elevation="6">
                        <v-tabs
                            v-model="tab"
                            background-color="secondary"
                            color="primary"
                        >
                            <v-tabs-slider color="primary"></v-tabs-slider>
                            <v-tab
                                v-for="(item, i) in tabItems"
                                :key="i"
                                light
                            >
                                {{ item.section }}
                            </v-tab>
                        </v-tabs>
                    </v-sheet>

                    <v-tabs-items v-model="tab">
                        <v-tab-item style="overflow-y:auto; height: 70vh">
                            <ProfilePersonal
                                :initial-record="record"
                            />
                        </v-tab-item>
                        <v-tab-item style="overflow-y:auto; height: 70vh">
                            <ProfileEmployment
                                :initial-record="recordEmployment"
                                :employee-posts="employeePosts"
                                :form-classes="formClasses"
                                :subjects="subjects"
                            />
                        </v-tab-item>
                        <v-tab-item style="overflow-y:auto; height: 70vh">
                            <ProfileQualifications
                                :qualifications="qualifications"
                                :initial-employee-qualifications="employeeQualifications"
                            />
                        </v-tab-item>
                        <!-- <v-tab-item style="overflow-y:auto; height: 70vh">
                            <ProfileDocuments
                                :employee-id="employeeId"
                                :initial-documents="documents"
                            />
                        </v-tab-item> -->
                    </v-tabs-items>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="update"
                        >
                            Update Information
                        </v-btn>
                    </v-card-actions>    
                    
                </v-card>
                
            </v-row>        
            
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>

            <v-overlay
                width="780"
                v-model="dialogDocuments"
                absolute
            >
                <ProfileDocuments
                    :employee-id="employeeId"
                    :initial-documents="documents"
                    v-on:close="dialogDocuments = false"
                />
            </v-overlay>

            <v-snackbar
                v-model="snackbar.display"
                :color="snackbar.color"
            >
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar.display = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>     
    </v-dialog>     
</template>

<script>
import ProfilePersonal from './ProfilePersonal.vue';
import ProfileEmployment from './ProfileEmployment.vue';
import ProfileQualifications from './ProfileQualifications.vue';
import ProfileDocuments from './ProfileDocuments.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    components: {
        ProfilePersonal,
        ProfileEmployment,
        ProfileQualifications,
        ProfileDocuments,
    },

    props: {
        selectedEmployeeId: {
            type: Number,
            default: null
        }
    },

    mounted () {
        this.initialize();
    },

    data(){
        return {    
            dialog: true,
            overlay: false,
            snackbar: {
                text: null,
                color: null,
                display: false,
            },
            tab: null,
            tabItems: [
                { section: 'Personal Information', disabled: false},  
                { section: 'Employment Information', disabled: false}, 
                { section: 'Qualifications Information', disabled: false},
                // { section: 'Documents', disabled: false}
            ],
            record: {},
            recordEmployment: {},
            updateHandler:{
                0: this.updatePersonal,
                1: this.updateEmploymentDetails,
                2: this.updateQualifications,
            },
            employeePosts: [],
            formClasses: [],
            subjects: [],
            qualifications: [],
            employeeQualifications: [],
            picture: false,
            pictureSrc: '',
            pictureFile: null,
            pictureUploadMessage: null,
            progress: 100,
            src: '',
            documents: [],
            dialogDocuments: false,
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
        }),

        employeeId () {
            if(this.selectedEmployeeId) return this.selectedEmployeeId
            return JSON.parse(this.user).employee_id
        },

        admin () {
            const { permissions } =  JSON.parse(this.user)
            let admin = false;
            permissions.forEach(permission => {
                if(permission.user_permission_id == 9) admin = true
            })
            return admin
        }
    },

    methods: {
        ...mapActions({
            postEmployee: 'profile/postEmployee',
            getEmployeePersonal: 'profile/getEmployeePersonal',
            getEmploymentDetails: 'profile/getEmploymentDetails',
            postEmployeeEmploymentDetails: 'profile/postEmployeeEmploymentDetails',
            getEmployeePosts: 'profile/getEmployeePosts',
            getFormClasses: 'profile/getFormClasses',
            getSubjects: 'profile/getSubjects',
            getEmployeeQualifications: 'profile/getEmployeeQualifications',
            postEmployeeQualifications: 'profile/postEmployeeQualifications',
            getQualifications: 'profile/getQualifications',
            getDocuments: 'profile/getFiles',
        }),

        ...mapMutations({
            setEmployeePersonal: 'profile/setEmployeePersonal',
            setEmployeeId: 'profile/setEmployeeId',
            setEmployeeEmploymentDetails: 'profile/setEmployeeEmploymentDetails',
            setEmployeeQualifications: 'profile/setEmployeeQualifications',
        }),

        async initialize () {
            this.overlay = true;
            this.setEmployeeId(this.employeeId);
            try {
                const promiseEmployeePersonal = this.getEmployeePersonal();
                const promiseEmploymentDetails = this.getEmploymentDetails();
                const promiseEmployeePosts = this.getEmployeePosts();
                const promiseFormClasses = this.getFormClasses();
                const promiseSubjects = this.getSubjects();
                const promiseQualifications = this.getQualifications();
                const promiseEmployeeQualifications = this.getEmployeeQualifications();
                const promiseEmployeeDocuments = this.getDocuments();

                const [
                    { data: dataEmployeePersonal },
                    { data: dataEmploymentDetails},
                    { data: dataEmployeePosts },
                    { data: dataFormClasses },
                    { data: dataSubjects },
                    { data: dataQualifications },
                    { data: dataEmployeeQualifications },
                    { data: dataEmployeeDocuments }
                ] = await Promise.all([
                    promiseEmployeePersonal,
                    promiseEmploymentDetails,
                    promiseEmployeePosts,
                    promiseFormClasses,
                    promiseSubjects,
                    promiseQualifications,
                    promiseEmployeeQualifications,
                    promiseEmployeeDocuments
                ])
                
                this.record = {...dataEmployeePersonal}
                this.src = this.record.picture
                this.setEmployeePersonal(this.record)
                this.mapRecordEmployment(dataEmploymentDetails)
                this.mapEmployeePosts(dataEmployeePosts)
                this.mapFormClasses(dataFormClasses)
                this.mapSubjects(dataSubjects)
                this.qualifications = [...dataQualifications];
                this.mapEmployeeQualifications(dataEmployeeQualifications);
                this.documents = [...dataEmployeeDocuments]
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
            this.overlay = false;
        },

        mapEmployeeQualifications (data) {
            let employeeQualifications = [];
            Object.keys(data).forEach(key => {
                let detail = data[key] == "" ? true : data[key];
                employeeQualifications[key] = detail
            })
            this.employeeQualifications = employeeQualifications;
        },

        mapRecordEmployment (data) {
            const { teacher_class, subjects_taught, ...otherDetails } = data;
            let teacherClasses = [];
            let subjectsTaught = [];
            if(teacher_class){
                teacherClasses = teacher_class.split(",");
            }
            if(subjects_taught){
                subjectsTaught = subjects_taught.split(",")
            }
            this.recordEmployment = { ...otherDetails, teacher_class:teacherClasses, subjects_taught: subjectsTaught }
            this.setEmployeeEmploymentDetails(this.recordEmployment)
        },

        mapEmployeePosts (data) {
            data.map(value => {
                value.text = value.post;
                value.value = value.id;
                return value;
            })
            this.employeePosts= [...data]
        },

        mapFormClasses (data) {
            this.formClasses = [];
            data.forEach(value => {
                this.formClasses.push(value.id)
            })
        },

        mapSubjects (data) {
            this.subjects = data.map(value => {
                return value.title
            })
        },

        close () {
            this.$emit('main-menu');
        },

        async update () {
            this.overlay = true;
            this.snackbar.text = "Data Saved Succesfully."
            this.snackbar.color = "primary"
            await this.updateHandler[this.tab]();
            this.overlay = false;
            this.snackbar.display = true;
        },

        async updatePersonal () {
            console.log('updating personal...')
            try {
                await this.postEmployee();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
                this.snackbar.text = "An Error Occured"
                this.snackbar.color = "red"
            }
            
        },

        async updateEmploymentDetails () {
            console.log('updating employment details...')
            try {
                await this.postEmployeeEmploymentDetails();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
                this.snackbar.text = "An Error Occured"
                this.snackbar.color = "red"
            }
        },

        async updateQualifications () {
            try {
                await this.postEmployeeQualifications();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
                this.snackbar.text = "An Error Occured"
                this.snackbar.color = "red"
            }
        },

        async uploadPicture (file) {
            this.progress = 0;
            this.pictureFile = file;
            if(!this.pictureFile){
                // this.pictureUploadMessage = "Please select a file!";
                return;
            }
            this.pictureUploadMessage = null;

            try {
                const { data } = await UploadService.upload(
                this.pictureFile, 
                this.employeeId,
                'picture',
                'employees',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    } 
                )
                const { url } = data;
                this.src = url;
                // this.studentRecord.picture = data.picture;
                // this.$emit('update-students');
                // console.log(data);
                
            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error); 
            }
            this.pictureFile = null;
            
        },

    }
}
</script>

<style scoped>
    ::v-deep .v-tab{
        max-width: 150px;
    }

    ::v-deep .v-btn--fab.v-size--small.v-btn--absolute.v-btn--top{
        top: 4px;
        right: 4px;
    }

    ::v-deep .record-summary input{
        font-weight: bold;
    }

    ::v-deep .theme--light.v-file-input .v-file-input__text--placeholder{
        font-size: 0.8rem;
    }

    ::v-deep .v-tabs-bar.secondary .v-tab{
        color: #581717;
    }
</style>
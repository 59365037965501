<template>
    <v-col
        class="py-0"
    >

        <v-card
            flat
            :width="cardWidth"
            align="center"
            justify="center"
            color="grey lighten-5"
            dark
        >
            <span
                class="mr-3 text-subtitle-1 primary--text font-weight-black"
            > {{ studentName }}</span>
            <span
                class="text-subtitle-1 primary--text font-weight-black"
            >{{ classId }}</span>
        </v-card>

        <v-card
            :width="cardWidth"
            flat
            color="grey lighten-5"
            dark
        >
            <v-row
                class="pt-2"
            >
                <v-col
                    align="center"
                    justify="center"
                    v-for="item in academicPeriod"
                    :key="item.title"
                    class="pa-0"
                >
                    <div
                        class="text-caption primary--text"
                    >{{item.title}}</div>
                    <div
                        class="text-caption primary--text"
                    >{{item.value}}</div>
                </v-col>
            </v-row>
        </v-card>

        <v-card
            :width="cardWidth"
            flat
        >
            <div>
                <v-list dense>
                    <v-list-item
                        v-for="item in recordSummary"
                        :key="item.title"
                        class="px-0"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                class="text-caption"
                            >
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-card
                            width="120"
                            flat
                        >

                             <v-text-field
                                v-if="!item.editable"
                                disabled
                                filled
                                dense
                                hide-details
                                v-model="studentRecord[item.model]"
                                height="30"
                                class="text-caption pa-0"
                                type="number"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-else-if="!item.select"
                                :disabled = "item.disabled"
                                :filled = "item.disabled"
                                @blur="updateRecord"
                                dense
                                hide-details
                                v-model="studentRecord[item.model]"
                                height="30"
                                class="text-caption pa-0"
                                type="number"
                                outlined
                            ></v-text-field>

                            <v-select
                                v-if="item.select"
                                :disabled = "item.disabled"
                                :filled = "item.disabled"
                                :items = "item.items"
                                v-model="studentRecord[item.model]"
                                hide-details
                                dense
                                @input="updateRecord"
                                small-chips
                                deletable-chips
                                outlined
                            >
                                
                            </v-select>

                            <v-text-field
                                v-if="item.check"
                            ></v-text-field>

                        </v-card>
                    </v-list-item>
                </v-list>
            </div>
        </v-card>


        <v-card
            :width="cardWidth"
            flat
            class="d-flex justify-space-between mt-2"
        >

            <v-btn
                small
                color="primary"
                text
                @click="searchStudent"
                class="text-caption"
                outlined
                width="100"
            >
                <v-icon
                    left
                >mdi-account-search-outline</v-icon>
                Search
            </v-btn>
                <v-btn
                small
                color="primary"
                text
                @click="reportCard"
                class="caption"
                outlined

            >
                <v-icon
                    left
                >mdi-file-pdf-outline</v-icon>
                Report Card
            </v-btn>
        </v-card>

        <v-card
            :width="cardWidth"
            flat
            class="d-flex justify-space-between mt-4"
        >

            <v-btn
                :class="btnClass"
                width="15"
                :dark="dark"
                :color="buttonColor"
                @click="previousRecord"
                :disabled="!pagination.prev_page"
            >
                PREV
            </v-btn>

            <v-btn
                class="text-caption pa-2"
                width="15"
                height="40"
                outlined
                disabled
                text
            >
                <span class="primary--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>
            </v-btn>

            <v-btn
                :class="btnClass"
                width="15"
                :dark="dark"
                :color="buttonColor"
                @click="nextRecord"
                :disabled="!pagination.next_page"
            >
                NEXT
            </v-btn>

        </v-card>

        <v-card
            :width="cardWidth"
            flat
            class="mt-3"
        >
            <v-btn 
                color="primary" 
                depressed 
                block
                @click="$emit('close')"
            >
                Next Class
            </v-btn>
        </v-card>

        <v-overlay
            absolute
            :value="overlay"
            color="grey"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="studentsRecords"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>

        <v-dialog
            v-model="reportViewer"
            max-width="700px"
            persistent
        >
            <v-card
            height="90vh"
            style="position:relative;"
            >
                <iframe
                    ref="pdf"
                    style="width:100%; height:92%"
                    :src="src"
                ></iframe>
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        depressed 
                        color="primary"
                        @click="closeReport"
                    >
                        <v-icon left>mdi-close</v-icon>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        admin: Boolean,
        teacherClasses: Array,
        fortnightlyAssesment: Boolean,
    },

    mounted: function () {
        this.academicPeriod[0].value = this.academicYear;
        this.academicPeriod[1].value = this.term;
    },

    computed: {
        ...mapGetters({
            academicYear: 'app/getAcademicYear',
            term: 'app/getTerm',
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',
            formClassAssignment: 'termReports/getFormClassAssignment',
            studentRecord: 'termReports/getStudentRecord',
            pagination: 'termReports/getPagination',
            currentPage: 'termReports/getCurrentPage',
            studentsRecords: 'termReports/getStudentsRecords',
        }),

        studentName(){
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        classId(){
            return this.studentRecord.form_class_id;
        },


        buttonColor(){
            return 'primary';
        },

    },
    watch: {
        studentRecord: {
            handler(){
                this.initialize();
            }
        },

        'studentRecord.promoted_to': {
            handler (val) {
                this.recordSummary.forEach(value => {
                    if(
                        value.model == 'repeating' &&
                        this.formClassAssignment.includes(this.studentRecord.form_class_id) &&
                        this.studentRecord.term == 3
                    ){
                        value.disabled = false;
                    }
                })
                if(val){
                    this.recordSummary.forEach(value => {
                        if(
                            value.model == 'repeating'
                        ){
                            value.disabled = true;
                        }
                    })
                }
            }
        },

        'studentRecord.repeating': {
            handler (val) {
                this.recordSummary.forEach(value => {
                    if(
                        value.model == 'promoted_to' &&
                        this.formClassAssignment.includes(this.studentRecord.form_class_id) &&
                        this.studentRecord.term == 3
                    ){
                        value.disabled = false;
                    }
                })
                if(val){
                    this.recordSummary.forEach(value => {
                        if(value.model == 'promoted_to'){
                            value.disabled = true;
                        }
                    })
                }
            }
        }

    },

    data: function () {

        return {
            academicPeriod: [
                { title: 'Academic Year', value: '' },
                { title: 'Term', value: '' },
            ],
            btnClass: 'text-caption pa-2  white--text',
            dark: false,
            overlay: false,
            expand: false,
            search: '',
            headers: [
                { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
                { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
                { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
                { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
            ],
            options: {
                itemsPerPage: -1,
            },
            reportViewer: false,
            reportViewerOverlay: false,
            src: '',
            grades: [
                'Excellent',
                'Very Good',
                'Good',
                'Satisfactory',
                'Fair',
                'Poor'
            ],
            cardWidth: "250",
            recordSummary: [
                {
                    title: 'Student\'s ID',
                    model: 'student_id',
                    select: false,
                    disabled: true,
                    editable: false,
                    display: true,
                },
                {
                    title: 'Total Sessions',
                    model: 'total_sessions',
                    select: false,
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Sessions Absent',
                    model: 'sessions_absent',
                    select: false,
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Sessions Late',
                    model: 'sessions_late',
                    select: false,
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Participation',
                    model: 'participation',
                    select: false,
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Project',
                    model: 'project',
                    select: false,
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Progressing well towards promotion',
                    model: 'promotion_progress',
                    select: true,
                    items: ['Yes', 'No'],
                    disabled: false,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Promoted To',
                    model: 'promoted_to',
                    select: true,
                    items: this.teacherClasses,
                    disabled: true,
                    editable: true,
                    display: true,
                },
                {
                    title: 'Repeating',
                    model: 'repeating',
                    select: true,
                    items: this.teacherClasses,
                    disabled: true,
                    editable: true,
                    display: true,
                },
            ]
        }

    },
    methods: {
        ...mapActions({
            getStudentTermDetails: 'termReports/getStudentTermDetails',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            postStudentDetails: 'termReports/postStudentTermDetails',
        }),
        ...mapMutations({
            setOverlay: 'termReports/setOverlay',
            setLoading: 'termReports/setLoading',
            setPagination: 'termReports/setPagination',
            setStudentRecord: 'termReports/setStudentRecord',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setCurrentPage: 'termReports/setCurrentPage',
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaved: 'termReports/setSaved',
            setSaveError: 'termReports/setError',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating',
        }),
        initialize () {
            if(
                this.formClassAssignment.includes(this.studentRecord.form_class_id) ||
                this.admin
            ){
                this.recordSummary.forEach(element => {
                    element.disabled = false;
                    if(
                        this.studentRecord.term != 3 && 
                        (element.model == 'promoted_to' || element.model == 'repeating')){
                            element.disabled = true;
                    }
                })
            }
            else{
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })
            }
        },
        async updateRecord(){
            this.setUpdating(true);
            this.setSavedToDb(false);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            try{
                await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                this.setUpdating(false);
            } catch (error) {
                console.log(error.response);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                //this.setErrorResponse(error);
            }

        },
        previousRecord(){
            this.recordNavigation(this.pagination.prev_page);
        },
        nextRecord(){
            this.recordNavigation(this.pagination.next_page);
        },
        async recordNavigation(pagination){
            this.setOverlay(true);
            this.setLoading(true);
            this.makePagination(pagination);
            this.setStudentRecord(this.studentsRecords[--pagination])

            try {
                const { data } = await this.getStudentTermMarks();
                this.parseStudentTermMarks(data);
            } catch (error) {
                console.log(error)
            }

            this.setOverlay(false);
            this.setLoading(false);

        },

        parseStudentTermMarks (data) {
            let termMarks = [];
            data.forEach(value =>{
                 if(!this.fortnightlyAssesment){
                    value.percentage = null;

                    if(value.mark){
                        let percentage = (value.mark / value.max_mark) * 100;
                        value.percentage = percentage.toFixed();
                    }
                }

                if(value.grade){
                    value.max_mark = "Grade";
                    value.mark = value.grade
                }
                termMarks.push(value)
            })
            this.setStudentTermMarks(termMarks);
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;

            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },
        searchStudent(){
            this.overlay = true;
            setTimeout(() => {this.expand = true});
        },
        viewRecord(item){
            this.expand = false
            setTimeout(() => {this.overlay = false}, 1000);
            let recordIndex = this.studentsRecords.indexOf(item)
            this.recordNavigation(++recordIndex);
        },
        cancelSearch(){
            this.expand = false
            setTimeout(() => {this.overlay = false}, 1000);
        },
        reportCard(){
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    this.reportViewerOverlay = false;
                }
            })
            let student_id = this.studentRecord.student_id;
            let academic_term_id = this.studentRecord.academic_term_id;
            this.src = process.env.VUE_APP_API_URI +
            `/api/report-card?academic_term_id=${academic_term_id}&form_class_id=${this.studentRecord.form_class_id}&student_id=${student_id}&employee_id=${this.studentRecord.employee_id}`;
        },
        closeReport(){
            this.src = '';
            this.reportViewer = false;
        },

        classSelect () {
            return ""
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    .v-btn__content{
        font-size: 0.8em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
    }

    ::v-deep .v-select__selections{
        height: 20px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-select__slot{
        padding-bottom: 4px;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections{
        min-height: 20px;
    }

    ::v-deep .v-chip.v-size--small{
        font-size: 11px;
        margin-bottom: 12px;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
        padding: 0px;
    }

    ::v-deep .v-list-item__title{
        overflow:auto;
        white-space: wrap;
    }


</style>
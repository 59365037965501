import axios from 'axios'

export default {
    namespaced: true,

    state: {
        userEmail: null,
        authenticated: sessionStorage.getItem('authenticated') || false,
        user: sessionStorage.getItem('user') || null,        
        overlay: false,        
        loginErrors: '',
        admin: sessionStorage.getItem('admin') || false,
        editPrincipalComment: sessionStorage.getItem('editPrincipalComment') || false, 
        editTermReport: false, 
        step: 1,
        userName: '',   
        resetPassword: false, 
        credentials: {}, 
        newPassword: '',
        resetEmployeeId: '', 
    },

    getters: {
        getUserEmail (state) {
            return state.userEmail
        },

        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },

        getOverlay(state){
            return state.overlay
        },

        getLoginErrors(state){
            return state.loginErrors
        },
        
        getAdmin (state) {
            return state.admin
        },

        getEditTermReport(state){
            return state.editTermReport
        },

        getStep(state){
            return state.step
        },

        getUserName(state){
            return state.userName
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getCredentials(state){
            return state.credentials
        },

        getNewPassword(state){
            return state.newPassword
        },

        getResetEmployeeId(state){
            return state.resetEmployeeId
        },

        getEditPrincipalComment (state) {
            return state.editPrincipalComment
        }
    },

    mutations: {
        setUserEmail (state, value) {
            state.userEmail = value
        },
        
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setOverlay (state, value){
            state.overlay = value
        }, 

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setAdmin (state, value) {
            state.admin = value
        },

        setEditTermMark(state, value){
            state.editTermReport = value
        },

        setStep(state, value){
            state.step = value
        },

        setUserName(state, value){
            state.userName = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setCredentials(state, value){
            state.credentials = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setResetEmployeeId(state, value){
            state.resetEmployeeId = value
        },

        setEditPrincipalComment (state, value) {
            state.editPrincipalComment = value
        }
        
    },

    actions: {
        getUser({ getters }){           
            let url = '/api/user-employee/' + getters.getUserName;
            return axios.get(url);
        }, 

        serverError({ commit }, err ){            
            if(err.response){
                console.log(err.response)
                var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'data');                                     
                if( hasErrors && err.response.data.errors.message.length != 0){
                    commit('setLoginErrors', err.response.data.errors.message[0]);
                }
                else{
                    commit('setLoginErrors', err.response.statusText);
                }                    
                commit('setAuthenticated', false);
                commit('setOverlay', false);
            }            
       },

       async signIn({ getters, dispatch, commit }){
            try{
                await axios.get('/sanctum/csrf-cookie');
                return axios.post('/api/employee-login', getters.getCredentials)
            } catch (error) {
                dispatch('serverError', error);               
                commit('setOverlay', false);
            }            

        },

        async changePassword({ getters }){           
            let url = '/api/employee-change-password';
            return await axios.post(url, {
                "name" : getters.getUserName,
                "password" : getters.getNewPassword,
            })            
        }, 
        
        resetPassword({ getters }){
            let url = '/api/reset-employee-password'
            return axios.post(url, {
                employee_id : getters.getResetEmployeeId
            })
        },
    }
}
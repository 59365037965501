<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1">
            {{ markRecord.name }}
        </td>
        <td v-if="!grade && !assesmentCriteriaCol">
            <v-text-field
                v-model="markRecord.max_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100
                class="mt-2"                
                disabled
                filled                
                            
            ></v-text-field>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            ></v-btn>    
        </td>

        <td v-if="!grade && !assesmentCriteriaCol">
            <v-text-field
                v-model="markRecord.mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                :max="markRecord.max_mark"                
                class="mt-2"
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
            ></v-text-field>
             <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="setAttendance(assesment, true)"
                        block
                        height="20"
                        class="my-2"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ assesment.button }}
                    </v-btn>
                </template>
                <span>{{ assesment.tooltip }}</span>
             </v-tooltip>        
        </td>

        <td v-if="!grade && !assesmentCriteriaCol">

           <v-text-field
                v-model="percentage"                
                outlined
                dense
                hide-details                
                filled
                disabled
                class="mt-2"                         
            >
            </v-text-field>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            ></v-btn>
        </td> 
        
        <td v-if="grade && !assesmentCriteriaCol">
            <v-select
                v-model="markRecord.grade"
                :items="gradingScheme"
                item-text="grade"
                item-value="grade"
                hide-details
                outlined
                dense
                @change="recordChanged"
            >
                <template v-slot:selection="{ item }">
                    <v-chip
                        v-if="chip"
                        close
                        @click:close="closeChip"
                    >
                        {{ item.grade }}
                    </v-chip>
                </template>

                <template
                    v-slot:item="data"
                >
                    {{ data.item.grade }} : {{ data.item.range }}
                </template>
            </v-select>
        </td>

        <template
            v-if="assesmentCriteriaCol"
        >
            <td 
                v-for="criteria in assesmentCriteria"
                :key="criteria.id"
            >
                <v-select
                    v-model="markRecord.grades[criteria.id]"
                    :items="gradingSchemeCriteria"
                    item-text="grade"
                    item-value="grade"
                    hide-details
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    @change="recordChanged()"
                >
                    <!-- <template v-slot:selection="{ item }">
                        <v-chip
                            v-if="chip"
                            close
                            @click:close="closeChip"
                        >
                            {{ item.grade }}
                        </v-chip>
                    </template> -->

                    <template
                        v-slot:item="data"
                    >
                        {{ data.item.grade }} : {{ data.item.detail }}
                    </template>
                </v-select>
            </td>
        </template>

        <td>
            <v-tooltip 
                bottom                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="primary"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saveStatus.saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="saveStatus.error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saveStatus.saving"
                size="16"
                width="3"
            ></v-progress-circular>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            ></v-btn>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object, 
        grade: Boolean,
    },

    created() {
        this.initialize(); 
    }, 

         
    data() {
        return {                               
            markRecord: {},
            saveStatus: {
                saved: false,
                saving: false,
                error: false,
            },     
            assesment: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'mark',
                modelAttendance: 'attendance',                
            },
            gradingScheme: [
                {grade: 'A', range: "90-100"},
                {grade: 'B', range: "80-89"},
                {grade: 'C', range: "70-79"},
                {grade: 'D', range: "60-69"},
                {grade: 'R', range: "0-59"}
            ],
            gradingSchemeCriteria: [
                {grade: "1", detail: 'Makes attempt but does not meet expectations'},
                {grade: "2", detail: 'Shows capacity to meet expectations but needs explicit support in some areas'},
                {grade: "3", detail: 'Shows a general capacity to meet expectations though some support may still be required'},
                {grade: "4", detail: 'Consistently meets expectations and sometimes exceeds them'},
                {grade: "5", detail: 'Generally exceeds expectations and ready for additional challenges'},
            ],
            chip: false,
            assesmentCriteriaCol: false,
            testGrade: "2",       
        }
    },
    computed: {
        ...mapGetters({            
            getUser: 'auth/getUser',
            lessonSelected: 'markSheet/getLessonSelected',
            termMarksRecords: 'markSheet/getTermMarkRecords',
            test: 'markSheet/getTest',
            selectedTeacher: 'markSheet/getSelectedTeacher',
            assesmentCriteria: 'markSheet/getAssesmentCriteria',
        }),

        user () {
            return JSON.parse(this.getUser);
        },

        percentage () {
            if(this.markRecord.max_mark && this.markRecord.mark)
            return ((this.markRecord.mark / this.markRecord.max_mark) * 100).toFixed(0);

            // if(this.maxMark && this.markRecord.mark)
            // return (this.markRecord.exam_mark / this.maxMark) * 100;

            return null;
        }
                
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'markSheet/setUpdatedTermMarkRecord',
            setStudentSelected: 'markSheet/setStudentSelected',            
            setDisplayPresetComments: 'markSheet/setDisplayPresetComment',
            setSelectedRecordIndex: 'markSheet/setSelectedRecordIndex',
        }),

        ...mapActions({
            save: 'markSheet/save',
        }),

        initialize(){
            this.markRecord = {...this.studentRecord};
            if(this.studentRecord.grades){
                this.markRecord.grades = {...this.studentRecord.grades}
            }
            
            if(this.markRecord.grade) this.chip = true;
            this.initializeAttendance(this.assesment, this.studentRecord.attendance);           
            this.savedDataCheck();  

            if(this.assesmentCriteria.length > 0){
                this.assesmentCriteriaCol = true;
            }  
        },

        initializeAttendance(assesment, attendance){
            assesment.attendance = attendance;
            switch(attendance){
                case 1:                    
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';
                    assesment.disable = false;
                    break;
                case 2:                    
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    assesment.disable = true;
                    break;
                // case 3:                    
                //     assesment.button = 'NW';
                //     assesment.tooltip = 'No Work';
                //     assesment.disable = true;
                //     break;
            }
        },

        async recordChanged(){
            this.chip = true;

            if(!this.recordBlankCheck()){                       
                this.setSaveStatus();

                let employeeID = this.user.employee_id;

                if(this.selectedTeacher){
                    employeeID = this.selectedTeacher.id
                }
                
                this.setUpdatedRecord({
                    ...this.markRecord,                    
                    "employee_id" : employeeID, 
                    // "grades" : this.grades
                    // test,                  
                });

                try {
                    await this.save();
                    this.setSaveStatus('saved')
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.setSaveStatus('error')
                }
            }                
        },
        recordBlankCheck(){            
            let blank = true;
            Object.keys(this.markRecord).forEach(key => {
                if(!blank) return;

                if(
                    key == 'mark' ||
                    key == 'attendance' ||
                    key == 'grade' 
                ){
                    if(
                        this.markRecord[key] != this.studentRecord[key]
                    ){
                        blank = false;
                    }
                }

                if( key == 'grades'){
                    Object.keys(this.markRecord[key]).forEach(i => {
                        if(this.studentRecord[key][i] != this.markRecord[key][i]){
                            blank = false;
                        }
                    })
                }
            })            
            return blank;
        },

        setSaveStatus (state = 'saving') {
            Object.keys(this.saveStatus).forEach(key => {
                this.saveStatus[key] = false;
                if(key == state) this.saveStatus[key] = true;
            })
        },
        
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saveStatus.saved = true;
        },
        recordSelected(){
            this.setStudentSelected(this.markRecord.name);
        },        
        
        setDisabledFields(){
            let formLevel = this.lessonSelected.formLevel;
            if(formLevel < 6){
                this.disableApplication = false;
                this.disablePreparedness = false;
                this.disableCodedComment = true;
                this.disableCodedComment1 = true; 
            }
            else{
                this.disableApplication = true;
                this.disablePreparedness = true;
                this.disableCodedComment = false;
                this.disableCodedComment1 = false; 
            } 
        },
        showPresetComments(){ 
            let index = this.termMarksRecords.indexOf(this.studentRecord);
            this.setSelectedRecordIndex(index);          
            this.setDisplayPresetComments(true);
        },

        setAttendance(assesment, update = false){
            switch(assesment.attendance){
                case 1:
                    assesment.attendance = 2;
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    this.markRecord[assesment.modelAssesment] = null;
                    this.markRecord[assesment.modelAttendance] = 2
                    assesment.disable = true;
                    break;
                case 2:
                    assesment.attendance = (assesment.test === 2) ? 3 : 1;
                    if(assesment.test === 2){
                        assesment.attendance = 3;
                        assesment.button = 'NW';
                        assesment.tooltip = 'No Work';
                        this.markRecord[assesment.modelAssesment] = null;
                        this.markRecord[assesment.modelAttendance] = 3
                        assesment.disable = true;
                    }
                    else{
                        assesment.attendance = 1;
                        assesment.button = 'P';
                        assesment.tooltip = 'Present';
                        this.markRecord[assesment.modelAttendance] = 1;
                        assesment.disable = false;
                    }
                    break;
                case 3:
                    assesment.attendance = 1;
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';                    
                    this.markRecord[assesment.modelAttendance] = 1
                    assesment.disable = false;
                    break;
            }
            if(update) this.recordChanged();
        },
        
        closeChip () {
            this.markRecord.grade = null;
            this.chip = false;
        }
    }
}
</script>

<style scoped>
    .subject-comments{
        position: relative;
    }
    .subject-comments button{
        position: absolute;
        bottom: 5px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }

    /* ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    } */

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections{
        min-height: 28px;
        /* max-width: 60px; */
    }

    ::v-deep .v-select__selections input{
        max-height: 0;
        padding: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
        padding: 0;
    }

    ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
        padding-left: 0;
        margin-left: 0;
    }
</style>


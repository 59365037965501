<template>
    <v-card
        width="400"
        flat
        class="mx-auto mt-3 px-4"
    >
        <v-row 
            v-for="(item, i) in fields"
           :key="i"
        >
            <v-col>
                <v-checkbox 
                    v-if="item.type == 'checkbox'"
                    v-model="employeeQualifications[item.id]"                           
                    :label="item.label"
                    class="mt-0"
                    style="font-size: 0.7rem"
                    :value="item.detail"
                    @change="update"
                ></v-checkbox>

                <v-text-field
                    v-else-if="item.type == 'text'"
                    v-model="employeeQualifications[item.id]"  
                    :ref="item.ref"
                    :label="item.label"
                    @blur="update"
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    props: {
        initialRecord: {
            type: Object,
            default: () => {
                return {}
            }
        },
        qualifications: {
            type: Array,
            default: () => {
                return []
            }
        },
        initialEmployeeQualifications: {
            type: Array,
            default: () => {
                return []
            }
        },
    },

    created: function () {
        this.initialize();
    },

    data() {
        return {
            fields: [],
            employeeQualifications: this.initialEmployeeQualifications,
        }
    },

    methods: {
        ...mapMutations({
            setEmployeeQualifications: 'profile/setEmployeeQualifications',
        }),
        initialize () {
            this.fields = this.qualifications.map(value =>  {
                if(value.details === 0) {
                    return {
                        id: value.id,
                        label: value.title,
                        type: 'checkbox',
                        value: value.detail
                    }
                }
                return {
                    id: value.id,
                    label: value.title,
                    type: 'text',
                    value: value.detail
                }
            })
        },

        update () {
            this.setEmployeeQualifications([...this.employeeQualifications]);
        }
    }
}
</script>
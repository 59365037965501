<template>
    <v-col
        class="pa-0"        
    >
        <v-row>
            <v-card
                width="500"
                class="mx-3 mt-3 pa-1"
                flat
                outlined                                
            >
                <v-data-table
                    :headers="headers"
                    :items="studentTermMarks"
                    dense                    
                    fixed-header
                    height="320"
                    disable-pagination
                    hide-default-footer
                    calculate-widths 
                    :no-data-text="noDataText" 
                >
                    <template v-slot:item="props">
                        <subject-row
                            v-bind:record="props.item"
                            v-bind:fortnightlyAssesment="fortnightlyAssesment"
                        ></subject-row>
                    </template>
                    
                </v-data-table>

                <v-overlay
                    absolute
                    :value="overlay"
                    color="grey"
                    opacity="0.6"
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>

                <!-- <v-overlay
                    absolute
                    :value="overlayDisabled"
                    color="grey"
                ></v-overlay>     -->
            </v-card>
        </v-row>

        <v-row
            class="mt-5 ml-2"                                
            align="center"
        >
            <v-col class="pa-0">
                <v-card
                    height="240"
                    width="200"                    
                    flat                    
                >
                    <v-form ref="form">
                        <v-textarea                                       
                            label="Teacher's Comments"                           
                            dense
                            rows="4"
                            :value="value"                        
                            counter                                            
                            class="text-caption"                                              
                            no-resize
                            maxlength="400"
                            v-model="studentRecord.teacher_comment"                            
                            @blur="saveTeacherComment"
                            @click="openBottomSheet('form-teacher')"
                            :disabled="disabled"
                            :filled="disabled"                     
                        >                        
                        </v-textarea>
                    </v-form>
                </v-card>
            </v-col>
            <v-col class="pa-0">
                <v-card
                    height="100"
                    width="200"                    
                    flat                    
                >
                    <v-form ref="form">
                        <v-textarea                                       
                            label="Principal's Comments"                           
                            dense
                            rows="3"
                            :value="value"                        
                            counter                                            
                            class="text-caption"                                              
                            no-resize
                            maxlength="100"
                            v-model="studentRecord.principal_comment"                            
                            @blur="saveTeacherComment"
                            @click="openBottomSheet('principal')"
                            :disabled="!principalAccess"
                            :filled="!principalAccess"                     
                        >                        
                        </v-textarea>
                    </v-form>
                </v-card>

                <v-card
                    flat
                    height="40"
                    width="200"
                    class="mt-3"
                >
                    <v-text-field
                        v-model="studentRecord.principal_signature"
                        disabled
                        hide-details=""
                        height="20"
                        filled
                        style="font-family: 'Brush Script MT', cursive; font-size: 20px "
                    ></v-text-field>  
                </v-card>

                <v-card
                    flat
                    height="80"
                    width="200"
                    class="pt-4 mt-3"
                >
                    <v-btn
                        text
                        outlined
                        block
                        color="primary"
                        @click="signReport"
                        :disabled="!principalAccess"
                        :filled="!principalAccess" 
                    >
                        Sign Report
                    </v-btn>
                </v-card>
            </v-col>                     
        </v-row>

        <Signature
            :dialog="dialogSignature"
            :signature="principalSignature"
            v-on:display-snackbar="displaySnackbar"
            v-on:close-signature-dialog="closeSignatureDialog"
            v-on:set-signature="setSignature"
        ></Signature>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
        >
            {{ snackbar.text }}
            <template
                v-slot:action="{ attrs }"
            >
                <v-btn 
                    color="white" 
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SubjectRow from './EditViewTermReportsSubjectRow';
import Signature from './EditViewTermReportsSignature';
export default {
    components: {
        SubjectRow,
        Signature
    },
    
    props: {
        admin: Boolean,
        fortnightlyAssesment: Boolean,
        formClassAssesment: {
            type: String,
            default: null
        }
    },

    data: () => ({
        headers: [           
            
        ],             
        studentAverage: '',        
        value: ' ',       
        isValid: true, 
        record: {},
        subjectRecord: [],
        disabled: true,
        principalAccess: false,
        dialogSignature: false, 
        snackbar: {
            display: false,
            text: '',
            color: 'primary'
        },
        noDataText: 'No Data Available',
        principalSignature: null,
    }),       
    watch: {
        studentRecord: {
            handler () {
                this.initialize();
            }
        },
        sheet: {
            handler (val) {               
                if(!val) this.updateRecord();
            }
        },
        studentTermMarks: {
            deep: true,
            handler(){
                this.getAverage();
            }
        },
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getStudentRecord',
            studentTermMarks: 'termReports/getStudentTermMarks',
            formClassAssignment: 'termReports/getFormClassAssignment',            
            sheet: 'termReports/getSheet',
            overlay: 'termReports/getOverlaySubjectRecords',
            courseWorkOnly: 'termReports/getCourseWorkOnly', 
            employeePost: 'termReports/getEmployeePost', 
            editPrincipalComment: 'auth/getEditPrincipalComment',          
        }),
       
    },
    methods: {
        ...mapMutations({            
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaveError: 'termReports/setError',
            setSaved: 'termReports/setSaved',
            setCommentsSheet: 'termReports/setSheet',
            setCommentsSheetTitle: 'termReports/setSheetTitle',
            setCommentsSheetModel: 'termReports/setSheetModel',
            setCommentsMaxLength: 'termReports/setSheetMaxLength',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating', 
        }),
        ...mapActions({
            getTermMarks: 'termReports/getTermMarks',
            postStudentDetails: 'termReports/postStudentTermDetails',
            // setErrorResponse: 'termReports/setErrorResponse', 
        }),
        initialize () {
            this.headers = [];
            this.noDataText = '';
            if(!this.formClassAssesment) {
                    this.headers = [
                        {text: 'Subject', align: 'left', value: 'abbr', width: '150'},
                        {text: 'Max Mark', align: 'center', value: 'max_mark', width: '70'},
                        {text: 'Earned', align: 'center', value: 'exam_mark', width: '70'},
                        {text: '%', value: 'percentage', width: '70'},
                        {text: '', align: 'left', value: 'action'}
                    ]
            }

            else if(this.formClassAssesment == "Interim Test" || this.formClassAssesment == "Forthnightly Test"){
                this.headers = [
                    {text: 'Subject', align: 'left', value: 'abbr', width: '150'},
                    {text: 'Max Mark', align: 'left', value: 'max_mark1', width: '70'},
                    {text: 'Earned', align: 'left', value: 'mark1', width: '70'},
                    {text: 'Max Mark', align: 'left', value: 'max_mark2', width: '70'},
                    {text: 'Earned', align: 'left', value: 'mark2', width: '70'},
                    {text: '%', value: 'percentage', width: '70'},
                    {text: '', align: 'left', value: 'action'}
                ]
            }

            if(
                this.formClassAssignment.includes(this.studentRecord.form_class_id) ||
                this.admin
            ){                  
                this.disabled = false;
            }
            else{                   
                this.disabled = true;
            }

            if(this.editPrincipalComment){
                this.principalAccess = true;
            }
        },

        getAverage(){
            let recordCount = 0;
            let markTotal = 0;
            let average = 0;
           
            this.studentTermMarks.forEach(record => {
                recordCount++;
                if(this.courseWorkOnly && !isNaN(record.course_mark)){
                    markTotal += record.course_mark;
                }                
                else if(!isNaN(record.exam_mark)){
                    markTotal += record.exam_mark;
                }
                
            })
            if(recordCount != 0){
                average = (markTotal / recordCount);                
                this.studentAverage = average.toPrecision(3) + ' %';
                //this.studentAverage = markTotal;
            }
            else{
                average = 0;
                this.studentAverage = '-'
            }
            
        },
        saveTeacherComment(){
            if(this.studentRecord.teacher_comment) this.updateRecord();            
        },
        openBottomSheet(post){
            if(post == 'form-teacher'){
                this.setCommentsSheetTitle('Teacher Comments');
                this.setCommentsSheetModel('teacher_comment');
                this.setCommentsMaxLength(400);
            } 
            else if (post == 'principal'){
                this.setCommentsSheetTitle('Principal Comments'); 
                this.setCommentsSheetModel('principal_comment');
                this.setCommentsMaxLength(100)
            }            
            this.setCommentsSheet(true);
        },
        async updateRecord(){
            this.setUpdating(true);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            this.setSavedToDb(false);
            this.setUpdating(false);
            try{
                await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        }, 
        
        signReport () {
            this.dialogSignature = true;
        },

        displaySnackbar (config) {
            this.snackbar.color = config.color;
            this.snackbar.text = config.text;
            this.snackbar.display = true
        },

        closeSignatureDialog () {
            this.dialogSignature = false;
        },

        setSignature (signature) {
            this.principalSignature = signature;
        }
    }
}
</script>

<style>
    ::v-deep .v-text-field__slot textarea{
        line-height: 1rem;
        font-size: 13px;
    }
</style>
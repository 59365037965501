<template>
    <v-card
        width="400"
        flat
        class="mx-auto mt-3 px-4"
    >
        <v-row
            v-for="(item, i) in fields"
            :key="i"
        >
            <v-col>
                <v-text-field
                    v-if="item.type == 'text'"
                    v-model="record[item.model]"
                    :ref="item.ref"
                    :label="item.label"
                    @blur="update"
                    hide-details
                ></v-text-field>

                <v-select
                    v-else-if="item.type == 'select' && !item.multiple"
                    v-model="record[item.model]"
                    :ref="item.ref"
                    :label="item.label"
                    :items="item.items"
                    @blur="update"
                    hide-details
                   
                ></v-select>

                <v-select
                    v-else-if="item.type == 'select' && item.multiple"
                    v-model="record[item.model]"
                    :ref="item.ref"
                    :label="item.label"
                    :items="item.items"
                    @blur="update"
                    hide-details
                    chips
                    multiple
                ></v-select>

                <DatePicker
                    v-else-if="item.type == 'date'"
                    :label="item.label"
                    :model="item.model"
                    :initial-date="record[item.model]"
                    v-on:date-selected="dateSelected"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import DatePicker from './DatePickerProfile.vue'
export default {
    components: {
        DatePicker,
    },

    created: function () {
        //
    },

    props: {
        initialRecord: {
            type: Object,
            default: () => {
                return {}
            }
        },
        employeePosts: {
            type: Array,
            default: () =>{
                return []
            }
        },
        formClasses: {
            type: Array,
            default: () =>{
                return []
            }
        },
        subjects: {
            type: Array,
            default: () =>{
                return []
            }
        },
    },

    watch: {
        initialRecord: {
            deep: true,
            handler (val) {
                console.log(val)
                this.record = {...val}
            }
        }
    },

    data: function () {
        return {
            record: {...this.initialRecord},
            fields: [
                {label: 'Teacher Registration Number', model: 'teacher_number', type: 'text', ref: 'teacher_number' },
                {label: 'MOE Email', model: 'email_moe', type: 'text', ref: 'email_moe' },
                {label: 'IHRIS Employee ID', model: 'ihris_id', type: 'text', ref: 'ihris_id' },
                {label: 'Date of Entry to Public Service', model: 'entry_date_public_service', type: 'date', ref: 'entry_date_public_service' },
                {label: 'Date of Entry to Teaching Service', model: 'entry_date_teaching_service', type: 'date', ref: 'entry_date_teaching_service' },
                {label: 'Date of Assumption at Current School', model: 'assumption_date_school', type: 'date', ref: 'assumption_date_school' },
                {label: 'Substantive Post', model: 'substantive_post_id', type: 'select', multiple: false, items: [...this.employeePosts], ref: 'substantive_post_id'},
                {label: 'Date of Assumption of Substantive Post', model: 'assumption_date_substantive', type: 'date', ref: 'assumption_date_substantive'},
                {label: 'Acting Post', model: 'acting_post_id', type: 'select', multiple: false, items: [...this.employeePosts], ref: 'acting_post_id'},
                {label: 'Date of Assumption of Acting Post', model: 'assumption_date_acting', type: 'date', ref: 'assumption_date_acting'},
                {label: 'Department(s) to which Assigned [Subject/Level]', model: 'department', type: 'text', ref: 'department'},
                {label: 'Class(es) to which Assigned', model: 'teacher_class', type: 'select', multiple: true, ref: 'teacher_class', items: [...this.formClasses]},
                {label: 'Subjects(s) taught to each Asssigned class', model: 'subjects_taught', multiple: true, type: 'select', items: [...this.subjects], ref: 'subject'},
            ],
        }
    },

    methods: {
        ...mapMutations({
            setEmployeeEmploymentDetails: 'profile/setEmployeeEmploymentDetails'
        }),

        update () {
            const { teacher_class, subjects_taught, ...otherDetails } = this.record
            let teacherClasses = null;
            let subjectsTaught = null;
            if(teacher_class){
                teacherClasses = teacher_class.join();
            }
            if(subjects_taught){
                subjectsTaught = subjects_taught.join();
            }
           
            this.setEmployeeEmploymentDetails({ ...otherDetails, teacher_class: teacherClasses, subjects_taught: subjectsTaught });
        },

        dateSelected(value) {
            this.record[value.model] = value.date;
            this.update();
        }
    }
}
</script>
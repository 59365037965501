import axios from 'axios';

class UploadFileService {
    upload(file, id, file_type, category, onUploadProgress, fileDescription = null) {
        let formData = new FormData();

        formData.append("file", file);       
        formData.append('id', id);
        formData.append('file_type', file_type);
        formData.append('category', category);
        formData.append('file_description', fileDescription)

        return axios.post("/api/upload-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    getPicture($student_id) {
        return axios.get("/api/student-picture/"+$student_id)
    }

    uploadPictures(files, onUploadProgress) {
        let formData = new FormData();
        for(let i = 0; i <files.length; i++){
            formData.append("files[]", files[i]);
        }

        return axios.post("/api/upload-pictures", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        })
    }
}

export default new UploadFileService();
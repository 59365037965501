<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"
            v-show="expand"
            light
        >
            <v-card-title
                class="primary white--text"
            >
                Select Teacher
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="teachers"
                    :search="search"
                    hide-default-footer
                    height="40vh"
                    disable-pagination

                    :loading="loading"
                >
                    <template v-slot:top>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search Teacher"
                            hide-details

                            clearable
                        ></v-text-field>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            color="primary"
                            @click="selectTeacher(item)"
                            small
                        >
                            Select
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            displayTeachers: 'markSheet/getDisplayTeachers',
            employeeId: 'auth/getEmployeeId',
            getUser: 'auth/getUser',
            term: 'app/getTerm',
        }),

        user () {
            return JSON.parse(this.getUser);
        }
    },

    watch: {
        displayTeachers: {
            handler (val) {
                console.log(`Display Teachers: ${val}`);
            }
        },

    },

    data() {
        return {
            headers: [
                { text: '', value: 'name'},
                { text: '', value: 'actions', width: '100', sortable: false },
            ],
            teachers: [],
            search: '',
            itemsPerPage: 100,
            loading: false,
            expand: false,

        };
    },

    methods: {
        ...mapMutations({
            setSelectedTeacher: 'markSheet/setSelectedTeacher',
            setMarksheetLoading: 'markSheet/setMarkSheetLoading',
            setDisplayLessons: 'markSheet/setDisplayLessons',
            setDisplayTeachers: 'markSheet/setDisplayTeachers',
            setSelectedFormClass: 'markSheet/setSelectedFormClass',
        }),

        ...mapActions({
            getEmployees: 'employees/getEmployees',
        }),

        async initialize () {
            this.loading = true;
            this.setMarksheetLoading(true);
            const { last_name } = this.user; 
            console.log(last_name)
            try {
                let currentEmployee = null;
                const { data } = await this.getEmployees();
                data.forEach(record => {
                    record.name = record.last_name + ', ' + record.first_name;
                    if(record.id != this.user.employee_id){
                        this.teachers.push(record);
                    }
                    else{
                        currentEmployee = record;
                    }
                })
                if(currentEmployee) this.teachers.unshift(currentEmployee);
            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error);
            }
            this.loading = false;
            this.setMarksheetLoading(false);
            setTimeout(() => {
                this.expand = true;
            }, 600)
        },

        selectTeacher (item) {
            this.setSelectedTeacher(item);
            this.expand = false;
            this.setDisplayTeachers(false);
            this.setSelectedFormClass(item.form_class_id);
            this.setMarksheetLoading(true);
            this.$emit('expand-test');
        },
        
    }
}
</script>
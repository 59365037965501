import axios from 'axios'

export default{
    namespaced: true,
    state: { 
       overlay: false,
       overlaySubjectRecords: false,       
       loading: false,
       expand: false,
       academicTermId: null,
       academicYearId: null,
       selectedClass: null,
       studentRecord: {},
       studentsRecords: [],
       pagination: {},
       studentTermMarks: [],
       employeeId: '',
       formClassAssignment: [],
       formDeanAssignments: [],
       saveStatus: '',
       saving: false,
       error: false,
       saved: false,
       savedToDb: false,
       currentPage: null,
       sheet: false,
       sheetTitle: '',
       sheetModel: '',
       sheetMaxLength: 100,
       updating: false,
       deletedTermMark: {},
       editedTermMark: {},
       subjects: [],
       courseWorkOnly: false,
       employees: [], 
       employeePost: null,     
    },
    getters: {         
        getOverlay(state){
            return state.overlay
        },
        getOverlaySubjectRecords(state){
            return state.overlaySubjectRecords
        },
        getLoading(state){
            return state.loading
        },
        getExpand(state){
            return state.expand
        },
        getAcademicTermId(state){
            return state.academicTermId
        },
        getAcademicYearId(state){
            return state.academicYearId
        },
        getSelectedClass(state){
            return state.selectedClass
        },
        getStudentRecord(state){
            return state.studentRecord
        },
        getPagination(state){
            return state.pagination
        },
        getStudentTermMarks(state){
            return state.studentTermMarks
        },
        getEmployeeId(state){
            return state.employeeId
        },
        getFormClassAssignment(state){
            return state.formClassAssignment
        },
        getFormDeanAssignments(state){
            return state.formDeanAssignments
        },
        getSaveStatus(state){
            return state.saveStatus
        },
        getSaving(state){
            return state.saving
        },
        getError(state){
            return state.error
        },
        getSaved(state){
            return state.saved
        },
        getSavedToDb(state){
            return state.savedToDb
        },
        getCurrentPage(state){
            return state.currentPage
        },
        getStudentsRecords(state){
            return state.studentsRecords
        },
        getSheet(state){
            return state.sheet
        },
        getSheetTitle(state){
            return state.sheetTitle
        },
        getSheetMaxLength(state){
            return state.sheetMaxLength
        },
        getSheetModel(state){
            return state.sheetModel
        },
        getUpdating(state){
            return state.updating
        },
        getDeletedTermMark(state){
            return state.deletedTermMark
        },
        getEditedTermMark(state){
            return state.editedTermMark
        },
        getSubjects(state){
            return state.subjects
        },
        getCourseWorkOnly(state){
            return state.courseWorkOnly
        },
        getEmployees(state){
            return state.employees
        },
        getEmployeePost (state) {
            return state.employeePost
        }
    },
    mutations: {       
        setOverlay(state, value){
            state.overlay = value
        },
        setOverlaySubjectRecords(state, value){
            state.overlaySubjectRecords = value
        },      
        setLoading(state, value){
            state.loading = value
        },
        setExpand(state, value){
            state.expand = value
        },
        setAcademicTermId(state, value){
            state.academicTermId = value
        },
        setAcademicYearId(state, value){
            state.academicYearId = value
        },
        setSelectedClass(state, value){
            state.selectedClass = value
        },
        setStudentRecord(state, value){
            state.studentRecord = value
        },
        setPagination(state, value){
            state.pagination = value
        },
        setStudentTermMarks(state, value){
            state.studentTermMarks = value
        },
        setEmployeeId(state, value){
            state.employeeId = value
        },
        setFormClassAssignment(state, value){
            state.formClassAssignment = value
        },
        setFormDeanAssignments(state, value){
            state.formDeanAssignments = value
        },
        setSaveStatus(state, value){
            state.saveStatus = value
        },
        setSaving(state, value){
            state.saving = value
        },
        setError(state, value){
            state.error = value
        },
        setSaved(state, value){
            state.saved = value
        },
        setSavedToDb(state, value){
            state.savedToDb = value
        },
        setCurrentPage(state, value){
            state.currentPage = value
        },
        setStudentsRecords(state, value){
            state.studentsRecords = value
        },
        setSheet(state, value){
            state.sheet = value
        },
        setSheetTitle(state, value){
            state.sheetTitle = value
        },
        setSheetMaxLength(state, value){
            state.sheetMaxLength = value
        },
        setSheetModel(state, value){
            state.sheetModel = value
        },
        setUpdating(state, value){
            state.updating = value
        },
        setDeletedTermMark(state, value){
            state.deletedTermMark = value
        },
        setSubjects(state, value){
            state.subjects = value
        },
        setEditedTermMark(state, value){
            state.editedTermMark = value
        },
        setCourseWorkOnly(state, value){
            state.courseWorkOnly = value
        },
        setEmployees(state, value){
            state.employees = value
        } ,
        setEmployeePost(state, value) {
            state.employeePost = value
        }       
    },
    actions: {
        getFormClassesList({ rootGetters }){
            let url = '/api/form-classes-teachers';
            if(rootGetters['printViewReports/getAcademicYearId']){
                url += '/' + rootGetters['printViewReports/getAcademicYearId']
            }
            return axios.get(url);
        },

        getStudentTermDetails({ getters }, page_url){
            let url = '/api/students-registered/'
            + getters.getAcademicTermId + '/'
            + getters.getSelectedClass.form_class_id + '/'
            + getters.getSelectedClass.employee_id;
            page_url = page_url || url;
            return axios.get(page_url);
        },
        getStudentTermMarks({getters}){
            let url = '/api/student-mark-records/'  
            + getters.getStudentRecord.student_id + '/'
            + getters.getAcademicTermId;
            return axios.get(url);
        },

        getFormTeacherAssignment({ getters }){
            let url = '/api/form-teacher-class/'
            + getters.getEmployeeId + '/'
            + getters.getAcademicYearId;
            return axios.get(url);
        },
        postStudentTermDetails( {getters }){
            let url = '/api/term-details';
            return axios.post(url, getters.getStudentRecord);
        },
        getFormDeanAssignments({ getters }){
            let url = 'api/form-dean-assignments/'
            + getters.getEmployeeId;
            return axios.get(url);
        },
        deleteStudentTermMark({ getters }){
            let url = 'api/student-term-mark';
            return axios.delete(url, {
                data: getters.getDeletedTermMark
            })
        },
        updateTermMarkSubject({ getters }){
            let url = 'api/student-term-mark-update';
            return axios.post(url, getters.getEditedTermMark);
        },
        getTermConfiguration(){
            let url = 'api/term-configuration';
            return axios.get(url);
        },

        getEmployeePost ({ getters }) {
            const url = 'api/employee-post/' + getters.getEmployeeId;
            return axios.get(url);
        }
        
    }
}    
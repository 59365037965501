import  axios  from "axios"

export default {
    namespaced: true,
    state: {
        markSheetOverlay: false,
        markSheetLoading: false,
        expandLessons: false,
        displayLessons: false,
        displayTeachers: false,
        totalStudents: null,
        teacherLessons: [],
        lessonSelected: {
            lesson: '',
            className: '',
            subjectCode: '',
            subjectTitle: '',
            formLevel: ''
        },
        termMarkRecords: [],
        displayPresetComment: false,
        updatedTermMarkRecord:{},
        studentSelected: '',
        selectedRecordIndex: -1,
        presetComments: [],
        presetComment: '',
        selectedTeacher: '',
        expandTeachers: false,
        test: null, 
        assesmentCriteria: [],
        fortnightlyAssesmentsTimetable: [],
        selectedFormClass: null,
        formClassAssesment: null,
    },    
    getters: {
        getDisplayLessons (state) {
            return state.displayLessons
        },

        getDisplayTeachers (state) {
            return state.displayTeachers
        },

        getMarkSheetOverlay(state){
            return state.markSheetOverlay
        },
        getMarkSheetLoading(state){
            return state.markSheetLoading
        },
        getExpandLessons(state){
            return state.expandLessons
        },        
        getTeacherLessons(state){
            return state.teacherLessons
        },
        getLessonSelected(state){
            return state.lessonSelected
        },
        getTermMarkRecords(state){
            return state.termMarkRecords
        },
        getTotalStudents(state){
            return state.totalStudents
        },
        getDisplayPresetComment(state){
            return state.displayPresetComment
        },
        getUpdatedTermMarkRecord(state){
            return state.updatedTermMarkRecord
        },
        getStudentSelected(state){
            return state.studentSelected
        },
        getSelectedRecordIndex(state){
            return state.selectedRecordIndex
        },
        getPresetComments(state){
            return state.presetComments
        },        
        getPresetComment(state){
            return state.presetComment
        },

        getSelectedTeacher (state) {
            return state.selectedTeacher
        },

        getExpandTeachers (state) {
            return state.expandTeachers
        },

        getTest (state) {
            return state.test
        },

        getAssesmentCriteria (state) {
            return state.assesmentCriteria
        },

        getForthnightlyAssesmentsTimetable (state) {
            return state.fortnightlyAssesmentsTimetable
        },

        getSelectedFormClass (state) {
            return state.selectedFormClass
        },

        getFormClassAssesment (state) {
            return state.formClassAssesment
        },
    },
    mutations: {
        setDisplayLessons (state, value) {
            state.displayLessons = value
        },

        setDisplayTeachers (state, value) {
            state.displayTeachers = value
        },
        
        setMarkSheetOverlay(state, value){
            state.markSheetOverlay = value
        },
        setMarkSheetLoading(state, value){
            state.markSheetLoading = value
        },
        setExpandLessons(state, value){
            state.expandLessons = value
        },
        setTeacherLessons(state, value){
            state.teacherLessons = value
        },        
        setLessonSelected(state, value){
            state.lessonSelected = value
        },
        setTermMarkRecords(state, value){
            state.termMarkRecords = value
        },
        setTotalStudents(state, value){
            state.totalStudents = value
        },
        setDisplayPresetComment(state, value){
            state.displayPresetComment = value
        },
        setUpdatedTermMarkRecord(state, value){
            state.updatedTermMarkRecord = value
        },
        setStudentSelected(state, value){
            state.studentSelected = value
        },
        setSelectedRecordIndex(state, value){
            state.selectedRecordIndex = value
        },
        setPresetComments(state, value){
            state.presetComments = value
        },       
        setPresetComment(state, value){
            state.presetComment = value
        },

        setSelectedTeacher (state, value) {
            state.selectedTeacher = value
        },

        setExpandTeachers (state, value) {
            state.expandTeachers = value
        },

        setTest (state, value) {
            state.test = value
        },

        setAssesmentCriteria (state, value) {
            state.assesmentCriteria = value
        },

        setFortnightlyAssesmentsTimetable (state, value) {
            state.fortnightlyAssesmentsTimetable = value
        },

        setSelectedFormClass (state, value) {
            state.selectedFormClass = value
        },

        setFormClassAssesment (state, value) {
            state.formClassAssesment = value
        }
    },
    actions: {
        getLessons(context, id){
            const url = '/api/teacher-lessons/' + id;
            return axios.get(url);
        },

        getStudents({getters},{ academicTermId, employeeId, test, forthnightlyTestId}){
            let url = '/api/teacher-lesson-students/'
            + getters.getLessonSelected.className + '/'           
            + academicTermId + '/'
            + getters.getLessonSelected.subjectCode + '/'
            + test + '/'
            + employeeId 
            
            if(forthnightlyTestId){
                url += '/' 
                + forthnightlyTestId;
            }
            return axios.get(url);
        },

        getPresetCommentsList(){
            let url = '/api/preset-comments';
            return axios.get(url);
        },

        save ({getters}) {
            let url = '/api/term-marks';
            return axios.post(url, getters.getUpdatedTermMarkRecord);
        },

        getForthnightlyAssesments ({ getters }) {
            const url = '/api/term-marks-forthnightly-assesments';
            return axios.get(url, {
                params: {
                    form_class_id: getters.getSelectedFormClass
                }
            })
        },

        getSubjectAssesmentCriteria ({ getters }) {
            const url = '/api/subject-assesment-criteria/' + getters.getLessonSelected.subjectCode;
            return axios.get(url, { 
                params: {
                    formClass: getters.getLessonSelected.className
                }
             });
        },

        getForthnightlyAssesmentsTimetable ({ getters }) {
            const url = '/api/forthnightly-assesment-timetable'
            return axios.get(url, {
                params: {
                    form_class_id: getters.getSelectedFormClass
                }
            })
        },

        getFormTeacherClass ({ rootGetters }) {
            const { employee_id } =  JSON.parse(rootGetters['auth/getUser']);
            const url = '/api/form-teacher-class/' + employee_id + '/' +  rootGetters['app/getAcademicYearId']
            return axios.get(url);
        },

        getTermConfiguration () {
            const url = '/api/term-configuration';
            return axios.get(url);
        },

        getTermAssesmentAssignment ({ getters }) {
            const url = '/api/term-assesment-assignment/' + 
            getters.getSelectedFormClass
            return axios.get(url);
        }
    }
}
<template>
    <v-dialog
       v-model="dialog"
        persistent
        width="600"
    >
        
        <v-card
            class="pa-4"
        >
            <v-card-title>Term Assesments Configuration</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    fixed-header
                    height="50vh"
                    :loading="loading"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <TermAssesmentRecord 
                            v-bind:item="item"
                            v-bind:subjects="subjects"
                            v-bind:formClasses="formClasses"
                        ></TermAssesmentRecord>
                    </template> 
                </v-data-table>
            </v-card-text>
            <v-card-actions>                
                <v-row>
                    
                    <v-spacer></v-spacer>               
                    <v-btn                    
                        depressed
                        @click="close"
                    >
                        Close
                    </v-btn>
                </v-row>                
            </v-card-actions>

            <v-overlay
                :value="loading"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>
    </v-dialog>
</template>      

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TermAssesmentRecord from './SettingsTermAssesmentsRecord.vue';
export default {
    components: {
        TermAssesmentRecord,
    }, 

    data: () => ({ 
        loading: false,
        items: [],
        headers:[
            { text: 'Academic Year', value: 'academicYear'},
            { text: 'Term', value: 'term' },
            { text: 'Edit', value: 'actions', sortable: false },
        ],
        subjects: [],
        formClasses: [],
    }),

    created () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            dialog: 'settings/getDialogTermAssesments',
        })
    },

    methods: {
        ...mapMutations({
            setDialog: 'settings/setDialogTermAssesments',
        }),

        ...mapActions({
            getTerms: 'settings/getTermAssesmentsTerms',
            getSubjects: 'settings/getTermAssesmentsSubjects',
            getFormClasses: 'settings/getTermAssesmentsFormClasses',
        }),

        async initialize () {
            console.log('initializing...')
            this.loading = true;
            this.formClasses = [];
            try {
                const promiseTerms = this.getTerms();
                const promiseSubjects = this.getSubjects();
                const promiseFormClasses = this.getFormClasses();

                const [
                    { data:dataTerms },
                    { data:dataSubjects },
                    { data:dataFormClasses }
                ] = await Promise.all([
                    promiseTerms,
                    promiseSubjects,
                    promiseFormClasses
                ])
                
                this.mapTerms(dataTerms)
                this.subjects = dataSubjects;
                this.formClasses = dataFormClasses;
                this.formClasses.unshift('All');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
            this.loading = false;
        },

        mapTerms (terms) {
            terms.forEach(term => {
                let yearStart = term.academic_year_id.toString().substring(0,4);
                let yearEnd = term.academic_year_id.toString().substring(4);
                term.academicYear = `${yearStart}-${yearEnd}`
                term.assesments.forEach(assesment => {
                    assesment.form_class_id = assesment.form_class_id || 'All'
                })
            });
            this.items = terms;
        },

        close () {
            this.setDialog(false)
        }
    }
}
</script>
<template>
    <v-card
        width="500"
        flat
        class="mx-auto mt-3 "
        light
    >
        <v-system-bar
            color="primary"
            dark
            class="py-4"
        >
            <v-spacer></v-spacer>
            <v-btn 
                small 
                icon
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-system-bar>
        <v-toolbar
            color="primary"
            dark
            dense
        >
            <v-toolbar-title>Documents Uploaded</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-row
                v-for="(item, i) in documents"
                :key="i"
            >
                <v-col>
                    <UploadFiles
                        :file-label="item.label"
                        :employee-id="employeeId"
                        :file-type="item.type"
                        :initial-url="item.url"
                        :initial-file-id="item.fileId"
                        :file="item"
                        v-on:delete-file="deleteFile"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn 
                        outlined 
                        block
                        color="primary"
                        @click="addMoreFiles"
                    >Add More Files</v-btn>
                </v-col>
            </v-row>

        </v-container>

        
    </v-card>
</template>

<script>
import UploadFiles from "./UploadFiles";
export default {
    components: {
        UploadFiles
    },

    props: {
        employeeId: {
            type: Number,
            default: 0
        },

        initialDocuments: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    mounted: function(){
        this.initialize()
    },

    data(){
        return {
            documents: [],
        }
    },

    methods: {
        initialize () {
            if(this.initialDocuments.length == 0){
                this.documents = [
                    {
                        label: '', 
                        placeholder: 'Click to upload document', 
                        type: 'document',
                        url: '',
                    },
                ]
                return
            }
            
            this.initialDocuments.forEach(document => {
                this.documents.push({
                    label: document.description,
                    placeholder: 'Click to upload document',
                    type: 'document',
                    url: document.url,
                    fileId: document.id,
                })
            })
            
        },

        addMoreFiles () {
            this.documents.push({
                label: '', 
                placeholder: 'Click to upload document', 
                type: 'document',
                url: '',
            })
        },

        deleteFile (file) {
            let index = this.documents.indexOf(file)
            if(this.documents.length == 1) return;
            this.documents.splice(index,1);
        },

        close () {
            this.$emit('close');
        }
    }
}
</script>
<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">                                                
        <v-card
            width="400"
            flat
            class="mx-auto mt-3 px-4"
        >
            <!-- <v-alert
                text
                type="error"
                v-if="errorMessage"
            >
                {{ errorMessage }}
            </v-alert>

            <v-alert
                dense
                text
                type="success"
                v-if="successMessage"
            >
                {{ successMessage }}
            </v-alert> -->
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="first_name"
                        v-model="record.first_name"
                        label="First Name"
                        hide-details="auto"
                        :rules="rules"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.first_name.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.first_name.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.first_name.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="last_name"
                        v-model="record.last_name"
                        label="Last Name"
                        hide-details="auto"
                        :rules="rules"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.last_name.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.last_name.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.last_name.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">                                            
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"                   
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="record.date_of_birth"
                            label="Date of Birth (optional)"                                                    
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="fields.date_of_birth.saveProgress"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.date_of_birth.saveSuccess"
                                        small
                                        color="green"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.date_of_birth.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                        </template>
                        <v-date-picker
                            ref="picker"
                            v-model="record.date_of_birth"                                                
                            min="1950-01-01"
                            @change="saveDate"                                                
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="birth_certificate_pin"
                        v-model="record.birth_certificate_pin"
                        label="Birth Certificate Pin"
                        hide-details="auto"
                        :rules="rules"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.birth_certificate_pin.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.birth_certificate_pin.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.birth_certificate_pin.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-select
                        ref="gender"
                        v-model="record.gender"
                        :items="gender"
                        label="Gender (optional)"
                        hide-details
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.gender.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.gender.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.gender.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        ref="form_class_id"
                        v-model="record.form_class_id"
                        :items="formClasses"
                        item-text="id"
                        item-value="id"
                        label="Class (optional)"
                        hide-details="auto"
                        clearable
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.form_class_id.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.form_class_id.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.form_class_id.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <!-- <v-autocomplete
                        ref="employee_id"
                        v-model="record.employee_id"
                        :items="teacherClasses"
                        item-text="text"
                        item-value="id"
                        label="Teacher (optional)"
                        hide-details="auto"
                    >                        
                    </v-autocomplete> -->
                    <v-autocomplete
                        ref="employee_id"
                        v-model="record.employees"
                        :items="teacherClasses"
                        item-text="text"
                        item-value="id"
                        chips
                        small-chips
                        multiple
                        label="Teachers (optional)"
                        hide-details="auto"
                    >                        
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-select
                        ref="house_id"
                        v-model="record.house_id"
                        :items="houses"
                        item-text="name"
                        item-value="id"
                        label="House (optional)"
                        hide-details="auto"
                    >                        
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">                                            
                    <v-menu
                        ref="menuEntryDate"
                        v-model="menuEntryDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"                   
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="record.entry_date"
                            label="Entry Date (optional)"                                                    
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="fields.entry_date.saveProgress"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.entry_date.saveSuccess"
                                        small
                                        color="green"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.entry_date.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                        </template>
                        <v-date-picker
                            ref="pickerEntryDate"
                            v-model="record.entry_date"                                                
                            min="1950-01-01"
                            @change="saveDate"                                                
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card>                                                
    </v-tab-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import InputAutoComplete from './InputAutoComplete.vue';
export default {
    components: {
        // InputAutoComplete
    },

    created: function () {
        this.record = { ...this.studentRecord };
        // console.log('created');
        this.initialize();
        // this.setFields();
    },
    
    props:{       
        
        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        },

        newStudent: {
            type: Boolean,
            default: false,
        },

        teachers: {
            type: Array,
            default: function() {
                return [];
            }
        },

        studentId: {
            
            default: null,
        },

        houses: {
            type: Array,
            default: function () {
                return [];
            }
        }
    }, 

    watch: {       

        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }, 
        
        studentRecord: {
            handler () {
                this.record = { ...this.studentRecord };
                // this.resetFields();
                // console.log('watch');
            }
        },

        studentId: {
            handler (val) {
                console.log('student id changed');
                this.record.student_id  = val;
            }
        }
    },

    data: () => ({
        gender: ['M', 'F'],       
        rules: [
            value => !!value || 'Required'
        ],
        menu: false,
        menuEntryDate: false,
        record: {
            student_id: null,
            first_name: null,
            last_name: null,
            other_name: null,
            date_of_birth: null,
            birth_certificate_pin: null,
            gender: null,
            form_class_id: null,
            employee_id: null,
            entry_date: null,
            serial_number: null,
        },
        fields: {
            first_name: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            last_name: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            date_of_birth: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            birth_certificate_pin: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            gender: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            form_class_id: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },            
            entry_date: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },            
        },
        errorMessage: null,
        successMessage: null,
        // teacherClasses: [],
    }),

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent',
        }),

        teacherClasses () {
            if(this.record.form_class_id){
                return this.teachers.filter(record => {
                    return record.form_class_id == this.record.form_class_id;
                })
            }
            return [...this.teachers];
        }
    },

    methods: {
        ...mapActions({
            postStudent: 'students/postStudent',
            postClassRegistration: 'students/postClassRegistration',
            getEmployees: 'employees/getEmployees',
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
            setClassRegistration: 'students/setClassRegistration',
        }),  
        
        initialize () {
            // console.log("data general initializing");
            // this.teacherClasses = [...this.teachers];
            // console.log(this.teacherClasses);
        },

        saveDate(date){
            this.$refs.menu.save(date);
            this.$refs.menuEntryDate.save(date);
        },

        validate () {
            let formHasErrors = false;
            this.setEditedStudent({
                birth_certificate_pin: this.record.birth_certificate_pin,
                date_of_birth: this.record.date_of_birth,
                first_name: this.record.first_name,
                form_class_id: this.record.form_class_id,
                gender: this.record.gender,
                other_name: this.record.other_name,
                id: this.record.student_id, 
                prev_id: this.record.prev_id,               
                last_name: this.record.last_name,
                employee_id: this.record.employee_id,
                house_id: this.record.house_id,
                entry_date: this.record.entry_date,
                employees: this.record.employees,
                serial_number: this.studentRecord.serial_number,
                picture: this.record.picture
            });

            // this.setClassRegistration({
            //     id: this.record.student_id,
            //     form_class_id: this.record.form_class_id
            // })
            
            Object.keys(this.record).forEach( f => {
                if(
                    (
                        f === 'birth_certificate_pin' ||
                        f === 'first_name' ||
                        f === 'last_name'
                    ) &&
                    !this.record[f]
                ){
                    formHasErrors = true;
                    this.$refs[f].validate(true);
                    this.errorMessage = "Please fill out the missing fields.";
                }                
            })
            
            if(formHasErrors) return false;

            return true;

            // console.log(this.record.student_id);

            // if(this.record.student_id){
            //     this.saveFeedback(model, "saveProgress");
            //     this.update(model);
            // }
            // else{
            //     return true;
            // }
            
        },

        async update (newStudent = false) {
            let message = newStudent ? "Student Successfully added." : "Student Record updated."
            this.errorMessage = null;
            this.successMessage = null;
            try {
                const { data: { student } } = await this.postStudent();
                // const response = await this.postStudent();
                this.record.student_id = student.id;
                console.log(this.record)
                if(newStudent) this.$emit('enable-tabs');
                this.$emit('update-students', student.id);
                this.$emit('show-snackbar', {text: message, color: "primary"});
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                // this.saveFeedback(model, "saveError");
                // this.errorMessage = "An error has occured."
                this.$emit('show-snackbar', {text: "An error has occured", color: "red"})
            }           
                     
        },
       

        saveFeedback (model, status) {
            // console.log(model);
            if(!model) return;

            let saveIcons = this.fields[model];
            Object.keys(saveIcons).forEach(key => {
                saveIcons[key] = false;
            });
            
            this.$nextTick(() => {
                saveIcons[status] = true;
            })
        },

        resetFields () {
            Object.keys(this.fields).forEach(key => {
                key.saveProgress = false,
                key.saveError = false,
                key.saveSuccess = false
            })
        },

        filterClasses () {
            if(this.record.form_class_id){
                this.teacherClasses = this.teachers.filter(record => {
                    return record.form_class_id == this.record.form_class_id;
                })
                return;
            }

            this.teacherClasses = [...this.teachers];
        }
    }
}
</script>
<template>
     <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
             <v-container 
                class="fill-height justify-center" 
                fluid
            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col>
                        <v-card 
                            max-width="400"
                            class="elevation-12 pa-4 mx-auto">
                            <v-row
                                justify="center"
                            >
                                <v-img
                                    src="../../assets/logo.png"
                                    max-width="150"
                                    contain
                                    class="d-flex"
                                ></v-img>
                            </v-row>
                            <v-row no-gutters>                               

                                <v-col cols="12">
                                    <v-window v-model="step">
                                        <v-window-item 
                                            :value="1"
                                            transition="slide-x-transition"
                                        >
                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="getloginErrors"
                                                    class="red--text"
                                                >
                                                    <div>{{ getloginErrors }}</div>
                                                </v-alert>    
                                            </div>
                                            <div class="text-center font-weight-light primary--text">Welcome to</div>
                                            <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>  
                                            <v-card-text>                                                
                                                <div class="primary--text text-h5 text-left">
                                                   <v-form 
                                                        class="pa-5"
                                                        @submit.prevent
                                                    >
                                                        <v-text-field
                                                            label="Username"
                                                            placeholder="Enter username"
                                                            name="id"                                                
                                                            type="text"
                                                            color="primary"
                                                            v-model="formData.name"
                                                            clearable
                                                            autofocus
                                                            v-on:keyup="keyPress"
                                                            auto-complete="off"
                                                        >
                                                            <v-icon
                                                                slot="prepend"
                                                                color="primary"
                                                            >
                                                                mdi-account
                                                            </v-icon>
                                                        </v-text-field>
                                                        <div class="text-right mt-4 mb-4">                                                
                                                            <v-btn 
                                                                color="primary"
                                                                @click="nextStep" 
                                                                dark
                                                                block
                                                            >NEXT</v-btn>
                                                        </div>
                                                    </v-form>     
                                                </div>                                            
                                            </v-card-text>
                                            
                                        </v-window-item>

                                        <v-window-item
                                            :value="2"
                                            transition="slide-x-transition"
                                        >
                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="getloginErrors"
                                                    class="red--text"
                                                >
                                                    {{ getloginErrors }}
                                                </v-alert>    
                                            </div>
                                            <div class="text-center font-weight-light primary--text">Welcome to</div>
                                            <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>
                                            <v-card-text>                                                
                                                <div class="primary--text text-h5 text-left">
                                                    <slot name="header"></slot>    
                                                </div>                                            
                                            </v-card-text>
                                            <v-form 
                                                class="pa-5"
                                                @submit.prevent="submit"
                                            >
                                                <v-text-field
                                                    v-show="password"
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    :label="passwordLabel"
                                                    :placeholder="passwordPlaceholder"
                                                    :hint="passwordHint"
                                                    persistent-hint
                                                    @click:append="show = !show"                                                    
                                                    name="password"
                                                    v-model="formData.password"
                                                    clearable
                                                    autofocus
                                                    color="primary"
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="primary"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                            

                                                <div class="text-right mt-4  mb-4">
                                                    <v-btn 
                                                        color="primary"
                                                        @click="submit" 
                                                        dark
                                                        block
                                                    >
                                                        LOGIN
                                                    </v-btn>
                                                </div>
                                            </v-form>   
                                        </v-window-item>

                                        <v-window-item :value="3">

                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="resetPasswordErrors"
                                                    class="red--text"
                                                >
                                                    
                                                </v-alert>    
                                            </div>

                                            <v-card-text>
                                                <div class="primary--text text-h5 text-left">Reset Password</div>                                            
                                            </v-card-text>                                            

                                            <v-form 
                                                class="pa-5"
                                                @submit.prevent
                                            >
                                                <v-text-field                                                            
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="New Password"                                                            
                                                    @click:append="show = !show"                                                    
                                                    name="new-password"
                                                    v-model="formData.newPassword"
                                                    color="primary"                                                            
                                                    autofocus
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="primary"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>

                                                <v-text-field                                                            
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="Confirm Password"                                                            
                                                    @click:append="show = !show"                                                    
                                                    name="confirm-password"
                                                    v-model="formData.confirmPassword" 
                                                    color="primary"
                                                    class="mt-4"                                                                                                                       
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="primary"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                            
                                        
                                                <div class="text-right mt-6">                                                
                                                    <v-btn 
                                                        color="primary" 
                                                        @click="changePassword" 
                                                        dark
                                                        block
                                                    >CHANGE PASSWORD</v-btn>
                                                </div>
                                            </v-form>

                                        </v-window-item>

                                    </v-window>
                                </v-col>

                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="overlay"
                    opacity="0.8"
                    z-index="15"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-container>           
        </v-main>
        
    </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import msalInstance from '../../services/authService';

export default {
    name: 'LoginTermReports',

    data: () => ({        
        resetPasswordErrors: false,
        show: false,
        formData: {
            newPassword: '',
            confirmPassword: '',
        },
        passwordPlaceholder: 'Enter password',
        passwordLabel: 'Password',
        passwordHint: '',
        password: true, 
    }),

    computed: {
        ...mapGetters({
            authenticated: 'auth/getAuthenticated',
            getOverlay: 'auth/getOverlay',
            schoolName: 'app/getSchoolName',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',                     
            overlay: 'auth/getOverlay', 
            step: 'auth/getStep',  
            resetPassword: 'auth/getResetPassword',                   
        }),
        
    },

    methods: {
        ...mapActions({            
            getUser: 'auth/getUser',   
            serverError: 'auth/serverError', 
            signIn: 'auth/signIn', 
            postNewPassword: 'auth/changePassword',       
        }),

        ...mapMutations({
            setUserEmail: 'auth/setUserEmail',
            setAuthenticated: 'auth/setAuthenticated',
            setUser: 'auth/setUser',
            setLoginError: 'auth/setLoginErrors',
            setOverlay: 'auth/setOverlay',
            setUserName: 'auth/setUserName',
            loginError: 'auth/setLoginErrors', 
            setResetPassword: 'auth/setResetPassword',
            setPermissions: 'auth/setPermissions', 
            setStep: 'auth/setStep', 
            setCredentials: 'auth/setCredentials', 
            setNewPassword: 'auth/setNewPassword',                                           
        }),        

        // async signIn () {
        //     this.setOverlay(true);            
        //     try {
        //         const loginResponse = await msalInstance.loginPopup({})
        //         const { account } = loginResponse;
        //         this.setUserEmail(account.username);
        //         const { data: { permissions, employee } } = await this.getUser();
                
        //         const user = {
        //             initials: employee.first_name[0]  +
        //             employee.last_name[0],
        //             first_name: employee.first_name,
        //             last_name: employee.last_name,
        //             employee_id: employee.id,
        //             permissions: permissions,
        //             email: account.username 
        //         }

        //         this.setAuthenticated(true);
        //         this.setUser(JSON.stringify(user));

        //         sessionStorage.setItem("user", JSON.stringify(user));                
        //         sessionStorage.setItem("authenticated", "true");               
                
        //         this.$router.replace('/app');
                                
        //     } catch (error) {
        //         if(error.response){
        //             console.log(error.response)
        //             const { data: { message } } = error.response;
        //             this.setLoginError(message);
        //         }
        //         else{
        //             console.log(error);
        //             this.setLoginError("An error has occured. Please contact your administrator");
        //         } 
        //     }
        //     this.setOverlay(false);
        // },       
        
        keyPress (e) {
            if(e.keyCode === 13){
                // this.signIn();
                this.nextStep();
            }
        }, 

        async nextStep () {
            this.setOverlay(true);
            this.setUserName(this.formData.name);

            if(this.formData.name === 'Admin'){
                const user = {
                    initials: 'A',
                    first_name: '',
                    last_name: 'Admin',
                    permissions: [
                        {user_permission_id: 1},
                        {user_permission_id: 2},
                        {user_permission_id: 3},
                        {user_permission_id: 4},
                        {user_permission_id: 5},
                        {user_permission_id: 6},
                        {user_permission_id: 7},
                        {user_permission_id: 8},
                        {user_permission_id: 9},
                        {user_permission_id: 10},
                    ]
                }
                this.setUser(JSON.stringify(user));
                sessionStorage.setItem('user', JSON.stringify(user));
                this.setOverlay(false);
                this.loginError('');
                this.setStep(2);
                return;
            }

            try {
                const { data: {
                    employee: {
                        id, 
                        first_name, 
                        last_name, 
                    },
                    user: {
                        password_reset
                    },
                    permissions 
                    
                } } = await this.getUser();
                // const response = await this.getUser();
                // console.log(response);
                // console.log(permissions);
                // console.log(password_reset);
                const user = {
                    initials: first_name[0] + last_name[0],
                    first_name: first_name,
                    last_name: last_name,
                    employee_id: id,
                    permissions: permissions
                }
                this.setUser(JSON.stringify(user));
                sessionStorage.setItem("user", JSON.stringify(user));
                this.setOverlay(false);
                this.loginError('');
                if(password_reset == 1){
                    this.setResetPassword(true);
                }
                else{
                    this.setResetPassword(false);                    
                }
                this.setStep(2); 

            } catch (error) {
                this.setOverlay(false);
                //console.log(error);
                if(error.response) console.log(error.response);
                else console.log(error);
                if(error.response){
                    this.loginError(error.response.data.message);
                }
                else{
                    this.loginError(`An Error has occured ( ${error})`)
                }
                
            }
            
            
        },

        async submit () {            
            console.log('Authenticating...');
            this.setOverlay(true);
            this.setCredentials(this.formData);
            try {
                await this.signIn();
                console.log('Authenticated.')                
                this.loginError(''); 
                // console.log(this.resetPassword);               
                if(this.resetPassword == 1){
                    this.setStep(3);                    
                }
                else{
                    this.setAuthenticated(true);
                    sessionStorage.setItem("authenticated", "true");  
                    this.$router.replace('/app');
                }                 
            } catch (error) {
                this.setOverlay(false);
                // console.log(error.response);
                this.serverError(error);
                
            }
            this.setOverlay(false);
        },

        
        async changePassword(){
            //console.log(this.formData);
            if(this.formData.newPassword && this.formData.confirmPassword){
                if(this.formData.newPassword == this.formData.confirmPassword){
                    console.log('changing password...');
                    this.setNewPassword(this.formData.confirmPassword);
                    try {
                        await this.postNewPassword();
                        this.setAuthenticated(true);
                        sessionStorage.setItem("authenticated", "true"); 
                        this.$router.replace('/app');                       
                    } catch (error) {
                        console.log(error);
                    }
                }
                else{
                    console.log('passwords dont match')
                }
            }
            else{
                console.log('passwords blank');
            }
        }    
        
    },
}
</script>

<style scoped>
    .container-background{
        background-image: url('../../assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }
       
</style>
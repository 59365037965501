<template>
    <v-dialog
       v-model="dialog"
        persistent
        width="880"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                color="grey darken-2"
                x-small
                v-bind="attrs"
                v-on="on"                        
            >
                <v-icon
                    x-small 
                    left
                    
                >
                    mdi-pencil
                </v-icon>
                Edit
            </v-btn>
        </template>
        <v-card>
            <v-card-title>{{ item.academicYear }} Term {{ item.term }}</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    fixed-header
                    height="60vh"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <TableRow
                            v-bind:record="item"
                            v-bind:subjects="subjects"
                            v-bind:formClasses="formClasses"
                        ></TableRow>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    elevation="0"
                    class="mr-3"
                >
                    Add Assesment
                </v-btn>
                <v-btn
                    depressed
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import TableRow from './SettingsTermAssesmentRecordRow.vue'
export default { 
    components: {
        TableRow,
    },

    props: {
        item: Object,
        subjects: Array,
        formClasses: Array,
    },

    created () {
        this.initialize();
    },

    data: function () { 
        return {
            dialog: false,
            headers: [
                { text: 'Date', value: 'date', sortable: false, width: "180" },
                { text: 'Type', value: 'assesment_type', sortable: false, width: "250" },
                { text: 'Class', value: 'form_class_id', sortable: false, width: "150" },
                { text: 'Subjects', value: 'subjects', sortable: false,}
            ],
            items: [],
            itemsPerPage: -1,
        }
    },

    methods: {
       
        initialize () {
            this.items = [];
            let { assesment_type, assesments } = this.item;
            assesments.forEach(assesment => {
                let subjects = [];
                assesment.subjects.forEach(subject => {
                    subjects.push({
                        id: subject.subject_id,
                        title: subject.title
                    })
                })
                this.items.push({
                    assesment_type,
                    date: assesment.subjects[0]?.test_date,
                    subjects: subjects,
                    form_class_id: assesment.form_class_id
                })
            });
        },

        close () {
            this.dialog = false;
        }
    }
}
</script>
<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="85vw" 
                flat               
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <StudentsAttendance/>
                    
                    <students-attendance
                        v-if="displayAttendance"
                    ></students-attendance>

                    <v-dialog
                        :value="dialogUpload"
                        max-width="780px"
                        persistent
                    >
                        <!-- <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                @click="dialogUpload=true"
                                v-on="on"
                                v-bind="attrs"
                                class="mr-4"
                            >
                                Upload Pictures
                            </v-btn>                            
                        </template> -->

                        <v-card>
                            <v-card-title>
                                Upload Student Pictures
                            </v-card-title>
                            <v-card-text>
                                <v-file-input
                                    v-if="uploadedPictures.length == 0"
                                    v-model="files"
                                    show-size
                                    counter
                                    multiple
                                    label="Select Pictures"
                                    filled
                                    prepend-icon="mdi-camera"
                                    @change="uploadPictures"
                                ></v-file-input>

                                <v-progress-linear
                                    v-if="uploadedPictures.length == 0"
                                    v-model="progress"
                                    color="blue-grey"
                                    height="25"
                                    
                                    reactive
                                >
                                    <strong>{{ progress }} %</strong>
                                </v-progress-linear>

                                <v-data-table
                                    v-else
                                    :headers="headersUpload"
                                    :items="uploadedPictures"
                                    height="60vh"
                                    fixed-header
                                ></v-data-table>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    outlined
                                    @click="dialogUpload=false"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>

                        </v-card>

                        
                             
                    </v-dialog>     


                    <v-dialog
                        :value="dialog"
                        max-width="780px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                @click="dialog=true"
                                v-on="on"
                                v-bind="attrs"
                            >
                                Add New Student
                            </v-btn>                            
                        </template>

                        <StudentsEdit
                            v-if="dialog"                            
                            v-bind:formTitle="formTitle"
                            v-bind:formClasses="formClasses"
                            v-on:close="closeEdit"
                            v-on:update-students="initialize" 
                        ></StudentsEdit>
                             
                    </v-dialog>                    
                    
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="60vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"                        
                        item-key="student_id"                                            
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template  v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                tile
                                color="primary"
                                @click="editStudent(item)"
                                class="mr-6"
                                small
                            >
                                Edit/View
                                <v-icon
                                    small
                                    class="ml-3"
                                >
                                    mdi-pencil
                                </v-icon>
                                
                            </v-btn>

                            <v-btn
                                text
                                outlined
                                @click="deleteStudent(item)"
                                small
                            >    
                                Delete
                                <v-icon
                                    small                                    
                                >
                                    mdi-delete
                                </v-icon>
                                
                            </v-btn> 

                               
                        </template> 

                        <template v-slot:item="props">
                            <tr>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>
                                    <v-img
                                        :src="props.item.picture"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.teacher }}</td>
                                <td>{{ props.item.gender }}</td>
                                <!-- <td>{{ props.item.date_of_birth }}</td> -->
                                <td>{{ props.item.birth_certificate_pin }}</td>
                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        class="mr-6 my-3"
                                        x-small
                                    >
                                        Edit/View
                                        <v-icon
                                            small
                                            right
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        
                                    </v-btn>

                                    <v-btn
                                        text
                                        tile
                                        outlined
                                        @click="deleteStudent(props.item)"
                                        x-small
                                        class="mr-6 mx-3"
                                    >    
                                        Delete
                                        <v-icon
                                            small                                    
                                        >
                                            mdi-delete
                                        </v-icon>
                                        
                                    </v-btn>

                                    <v-btn
                                        tile
                                        @click="registrationForm(props.item)"
                                        x-small
                                        color="primary"
                                        class="my-3"
                                    >    
                                        Reg. Form
                                        <v-icon
                                            small
                                            right                                    
                                        >
                                            mdi-file-outline
                                        </v-icon>
                                        
                                    </v-btn>

                                    <!-- <v-menu
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                medium                           
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-3"
                                            >
                                                mdi-dots-vertical
                                            </v-icon>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(file, index) in documents"
                                                :key="index"
                                                link
                                                @click="displayFile(props.item, file)"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        color="primary"
                                                        v-if="props.item[file.model]"
                                                    >
                                                        mdi-cloud-check
                                                    </v-icon>
                                                    <v-icon
                                                        v-else
                                                        color="red"
                                                    >
                                                        mdi-alert-circle
                                                    </v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    {{ file.title }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>          -->
                                </td>
                            </tr>
                        </template>                       

                    </v-data-table>

                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                    
                </v-card-text> 

                <v-dialog
                    v-model="reportViewer"
                    max-width="90vw"
                    @click:outside="closeReport"
                >
                    <v-card
                    height="90vh"
                    style="position:relative;" 
                    >                
                        <iframe 
                            ref="pdf" 
                            style="width:100%; height:100%" 
                            class="report-viewer"  
                            :src="src"
                        ></iframe>              
                        <v-overlay
                            absolute
                            :value="reportViewerOverlay"
                            color="white"
                            opacity="0.60"
                        >
                            <v-progress-circular
                                indeterminate
                                size="64"
                                color="primary"
                            ></v-progress-circular>
                        </v-overlay> 
                    </v-card>
                </v-dialog>  

                <v-dialog
                    v-model="dialogReport"
                    :max-width="maxWidth"
                    persistent
                >
                    <v-card
                        v-if="!picturePreview"
                        height="80vh"
                    >
                        <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>

                    </v-card>

                    <v-img
                        v-else
                        max-width="200"
                        :src="src"
                        class="mx-auto ma-6"
                    ></v-img>

                    <v-container
                        fluid
                        class="pa-0"
                    >
                        <v-sheet>
                            <v-row
                                justify="end"
                                class="ma-0"
                            >

                                <v-btn
                                    color="primary"
                                    dark
                                    @click="closeRegistrationForm"
                                    class="my-2 mr-4"
                                >
                                    Close
                                </v-btn>

                            </v-row>
                        </v-sheet>
                    </v-container>

                </v-dialog>

            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsEdit from './StudentsEdit';
import StudentsAttendance from './StudentsAttendance.vue';
import UploadService from "../services/UploadFileService";

export default{ 
    components: {         
        StudentsEdit,
        StudentsAttendance, 
    },

    created () {
        this.initialize();
    },    

    data: () => ({
        headers: [
             {text: 'ID', align: 'start', sortable: false, value: 'student_id', width: '80'},
             {text: 'Name', align: 'start', sortable: false, value: 'name', width: '200'},
             {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
             {text: 'Class', align: 'start', sortable: false, value: 'form_class_id', width: '120'},
             {text: 'Teacher', align: 'start', sortable: false, value: 'teacher', width: '120'},
             {text: 'Gender', align: 'start', sortable: false, value: 'gender', width: '70'},
            //  {text: 'Date of Birth', align: 'start', sortable: false, value: 'date_of_birth', width: '120'},
             {text: 'Birth Cert. Pin', align: 'start', sortable: false, value: 'birth_certificate_pin', width: '100'},
             {text: 'Actions', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        search: '',
        loading: true,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '',            
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '', 
        },   
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        formClasses: [],
        displayAttendance: false,  
        files: [],
        progress: 0,  
        dialogUpload: false, 
        headersUpload: [
             {text: 'Picture', align: 'start',  value: 'fileName', width: '100'},
             {text: 'Uploaded', align: 'start', value: 'success', width: '120'},
        ],
        uploadedPictures: [], 
        reportViewer: false,
        reportViewerOverlay: false,
        src: '',  
        documents: [
            {title: 'Birth Certificate', model: 'birth_certificate'},
            {title: 'Immunization Card', model: 'immunization_card'},
            // {title: 'SEA Placement Slip', model: 'sea_slip'},
            // {title: 'Passport Photo', model: 'picture'},
        ],
        maxWidth: '',
        dialogReport: false,
        picturePreview: false,       
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' :  
            this.editedItem.first_name + ' '
            + this.editedItem.last_name
        },
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        }
    },

    watch: { 
        dialogDelete (val) {
            val || this.closeDelete()
        },
        
        dialog (val) {
            if(val && this.editedIndex == -1){
                this.setNewStudent()
            } 
        }
       
    },

    methods: {
        ...mapActions({
            getStudents: 'common/getCurrentStudents',
            getFormClasses: 'common/getFormClasses',
            getStudentStatus: 'students/getStudentStatus',
            delete: 'students/deleteStudent',            
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
        }),

        async initialize(){
            try {
                const promiseStudents = this.getStudents();
                const promiseFormClasses = this.getFormClasses();
                const promiseStudentStatus = this.getStudentStatus();

                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: dataStudentStatuses}
                ] = 
                await Promise.all([
                    promiseStudents, 
                    promiseFormClasses,
                    promiseStudentStatus
                ]);
                
                this.mapStudents(students);
                // console.log(students);
                this.formClasses = formClasses;
                dataStudentStatuses.forEach(record => {
                    if(record.id > 2){
                        this.statuses.push(record);
                    }
                })
                // console.log(this.statuses);               
                this.loading = false;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        },

        mapStudents (data) {
            console.log(data);
            this.students = data.map(record => {
                record.name = record.last_name + ', ' + record.first_name;
                record.prev_id = record.student_id;
                if(!record.picture){
                    record.picture = require("../assets/icons/student.svg")
                }
                return record;
            });
        },

        editStudent(item){
            this.setEditedStudent(item);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        
        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            this.$nextTick(() => {
                console.log(this.$refs['student-status'])
                //this.$refs['student-status'].isActive = true;
            })
           
        },
        
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            //console.log(this.deletedItem);
            this.setEditedStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            try {
                let response = await this.delete();
                console.log(response);
                const { data } = await this.getStudents();
                this.mapStudents(data);             
                this.deleting = false;                               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
            setTimeout(() => {
                this.snack = true;
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
            })           
            
        },

        closeEdit () {
            this.dialog = false;            
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.setEditedStudent(this.editedItem);
                this.editedIndex = -1;
            });            
        },

        setNewStudent () {
            this.setEditedStudent(this.editedItem);
        },

        newStudentClick (attrs) {
            console.log(attrs);
        },

       
        async uploadPictures () {
            this.progress = 0;
            let loadedProgress = 0;
            let loadedFilesCurrent = 0;
            let loadedFilesPrevious = 0;
            let responses = [];
            if(this.files.length === 0){
                // this.pictureUploadMessage = "Please select a file!";
                return;
            }
            // this.pictureUploadMessage = null;
            const batchSize = 20;
            const batches = [];
            for(let i = 0; i < this.files.length;  i+=batchSize){
                batches.push(this.files.slice(i, i+batchSize))
            }

            for(let i = 0; i < batches.length; i++){
                try {
                    const { data } = await UploadService.uploadPictures(
                    batches[i], 
                    (event) => {
                        loadedProgress = Math.round((100 * event.loaded) / event.total);
                        } 
                    )
                    responses.push(...data);
                } catch (error) {
                   if(error.response) console.log(error.response);
                   else console.log(error); 
                }
                loadedFilesCurrent = Math.round((loadedProgress * batches[i].length) / 100);
                this.progress = Math.round(((loadedFilesPrevious + loadedFilesCurrent) * 100) / this.files.length )
                loadedFilesPrevious += loadedFilesCurrent;
            }

            setTimeout(() => {
                this.uploadedPictures = responses;
            }, 600)


        },

        registrationForm ({ student_id }){
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })
            this.src = process.env.VUE_APP_API_URI + "/api/registration-form?id="
            + student_id;
        },

        closeReport () {
            this.src = null;
        }, 

        displayFile (record, file) {            
            let src = record[file.model]
            if(src && file.model != 'picture'){
                this.maxWidth = "80vw";
                this.src = src;
                this.picturePreview = false;
                this.dialogReport = true;
            }
            else if(src && file.model == 'picture'){
                this.maxWidth = "300";
                this.picturePreview = true;
                this.src = src;
                this.dialogReport = true;
            }

        },

        closeRegistrationForm () {
            //
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-dialog{
        max-height: 100%
    }
</style>
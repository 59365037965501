<template>
    <v-row
        justify="center"
    >
        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="900"
                height="80vh"
            >
                <v-card-title
                    class="d-flex primary--text py-2 px-0"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-bold primary--text text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="primary"
                        @click="displayMarkSheetMenu"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkTable
                            v-bind:formClassAssesment="formClassAssesment"
                        />
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    
                    <Lesson 
                        v-if="displayLessons"
                        v-bind:test="test"
                        v-bind:lessonSelected="lessonSelected"
                        v-bind:forthnightlySubjects="forthnightlySubjects"
                        v-bind:teacherClass="teacherClass"
                        v-bind:formClassAssesment="formClassAssesment"
                        v-on:display-tests="displayTests"
                    />
                    
                    <Teacher 
                        v-if="displayTeachers"
                        v-on:expand-test="expandTestOptions"
                    />

                    <TestOptions
                        v-if="expandTest"
                        v-on:display-marksheet="setDisplayMarkSheet"
                        v-on:close-test-options="closeTestOptions"
                        v-on:display-lessons="displayTeacherLessons"
                    />


                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="primary"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
        
    </v-row>    
</template>

<script>
import Header from './MarkSheetHeader'
import Lesson from './MarkSheetLesson'
import Teacher from './MarkSheetTeacher'
import MarkTable from './MarkSheetTable'
import TestOptions from './MarkSheetTests.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'MarkSheet',

    created () {
        this.initialize();
    },
  
    components: {
        Header,
        Lesson,
        Teacher,
        MarkTable,
        TestOptions,
    },

    computed: {
        ...mapGetters({            
            overlay: 'markSheet/getMarkSheetOverlay',
            loading: 'markSheet/getMarkSheetLoading',
            totalStudents: 'markSheet/getTotalStudents',
            admin: 'auth/getAdmin',
            displayLessons: 'markSheet/getDisplayLessons',
            displayTeachers: 'markSheet/getDisplayTeachers',
            getLessonSelected: 'markSheet/getLessonSelected',
            term: 'app/getTerm',
        }), 
    },
    
    watch: {
        //
    },

    data: () => ({
        userTimetable: [],
        expandTest: false,
        test: null,
        lessonSelected: {
            lesson: null,
            test: null
        },
        forthnightlySubjects: [],
        teacherClass: null,
        formClassAssesment: null,
    }),

    methods: {
        ...mapMutations({
            setOverlay: 'markSheet/setMarkSheetOverlay',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading',
            setDisplayLessons: 'markSheet/setDisplayLessons',
            setDisplayTeachers: 'markSheet/setDisplayTeachers',
            setLoading: 'markSheet/setMarkSheetLoading',
            setSelectedFormClass: 'markSheet/setSelectedFormClass',
            setMarkSheetTest: 'markSheet/setTest',
        }),

        ...mapActions({
            getFormClass: 'markSheet/getFormTeacherClass',
            getTermAssesmentAssignment: 'markSheet/getTermAssesmentAssignment',
            getForthnightlyAssesmentsTimetable: 'markSheet/getForthnightlyAssesmentsTimetable',
        }),

        async initialize () {
            this.setOverlay(true);
            this.setLoading(true);
            try {
                const { data } = await this.getFormClass();
                this.teacherClass = data.form_class_id;
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }
            this.displayMarkSheetMenu()               
        },
                      
        async displayMarkSheetMenu () {
            console.log('displaying mark sheet menu...')
            this.setOverlay(true);
            this.setLoading(true);
            if(this.admin){
                this.setDisplayLessons(false);
                this.setDisplayTeachers(true); 
                return;               
            }
            
            this.setSelectedFormClass(this.teacherClass);
            const { data } = await this.getTermAssesmentAssignment();
            
            this.formClassAssesment = data?.detail;
            if(data?.detail && data.detail != "Grade"){
                this.expandTest = true;
                return;
            }

            
            //class level only has one end of term assesment
            const { data: dataForthnightlyAssesment } = await this.getForthnightlyAssesmentsTimetable();

            let test = null;
            if(dataForthnightlyAssesment.length != 0){
                dataForthnightlyAssesment.forEach(value => {
                    test = {
                        title: value.title,
                        forthnightly_test_id: value.forthnightly_test_id,
                        test: 'forthnightly',
                        subjects: value.subjects
                    }
                    
                })
            }
            this.setMarkSheetTest(test);
            this.setDisplayLessons(true);
            
        },

        async expandTestOptions () {
            const { data } = await this.getTermAssesmentAssignment();
            this.formClassAssesment = data?.detail;
            if(data?.detail && data.detail != 'Grade'){
                this.setLoading(true);
                this.expandTest = true;
                return;
            }

            
            this.setDisplayLessons(true);
        },

        closeTestOptions () {
            this.expandTest = false;
        },

        setDisplayMarkSheet (test) {
            switch (test){
                case 'midTerm':
                    this.test = 2;
                    break;
                case 'forthnightly':
                    this.test = 3;
                    break;
                default:
                    this.test = 1        
            }
            this.lessonSelected.lesson = this.getLessonSelected.lesson;
            this.lessonSelected.test = this.test;
        },
        
        displayTeacherLessons (subjects) {
            this.forthnightlySubjects = subjects;
            this.setDisplayLessons(true);
        },

        displayTests () {
            this.setLoading(true);
            this.setDisplayLessons(false);
            this.expandTest = true;
        }
       
    }
}
</script>
<template>
     <v-expand-transition>
        <v-card
            class="mx-auto"
            v-show="expand"
            light                        
        >    
            <v-app-bar dark color="primary">
                <v-card-title>Select Assesment</v-card-title>
            </v-app-bar>
            <v-container>
                <v-row
                    v-for="(assesment, i) in assesments"
                    :key=i
                >
                    <v-col>
                        <v-btn
                            
                            @click="setTest(assesment)"
                            
                            color="primary"
                            outlined
                            block
                        >
                            {{ assesment.title }}
                        </v-btn>
                    </v-col>
                </v-row>
                
            </v-container>
        </v-card>
    </v-expand-transition>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { format, parseISO } from 'date-fns'
export default {
    props: {
        //
    },

    data: function () {
        return {
            expand: false,
            assesments: [],
        }
    },

    mounted: function () {
        this.initialize();
    },

    methods: {
        ...mapMutations({
            setMarkSheetTest: 'markSheet/setTest',
            setLoading: 'markSheet/setMarkSheetLoading',
        }),

        ...mapActions({
            getForthnightlyAssesmentsTimetable: 'markSheet/getForthnightlyAssesmentsTimetable',
        }),
        
        async initialize () {
            // let assesmentTitle = null;
            try {
                const { data } = await this.getForthnightlyAssesmentsTimetable()
                console.log(data)
                if(data.length != 0){
                    data.forEach(value => {
                        let testDate = value.test_date ? ' - ' + format(parseISO(value.test_date), 'do MMM') : "";
                        this.assesments.push(
                            {
                                // title: 'Forthnightly Test - ' + format(parseISO(value.test_date), 'do MMM'),
                                title: value.title + testDate,
                                forthnightly_test_id: value.forthnightly_test_id,
                                // open: test.open,
                                test: 'forthnightly',
                                subjects: value.subjects
                            }
                        )
                    })
                    this.setLoading(false);
                    setTimeout(() => {
                        this.expand = true;
                    }, 600)
                    this.expand = true;
                    return;
                }

            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }
        },

        setTest (test) {
            this.expand = false;
            this.setMarkSheetTest(test);
            setTimeout(() => {
                // this.$emit('display-marksheet', test.test);
                this.$emit('display-lessons', test.subjects);
                this.$emit('close-test-options');
            }, 700)
        },
    }
}
</script>
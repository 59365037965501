<template>
    <v-row>         
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="500"
                
            >
                    <v-card-title
                        class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                    >
                        <v-spacer></v-spacer>
                        <span>Print / View Reports</span>
                        <v-spacer></v-spacer>                   
                    </v-card-title>
                    <v-card-text
                        style="height: 60vh; overflow-y: auto;"
                    >
                        <v-list two-line>
                            <v-list-item-group
                                active-class="primary--text"
                                v-model="selectedReport"
                            >
                                <template v-for="(item, index) in items">                                   
                                    <v-list-item 
                                        :key="item.title"
                                        @click="displayReport(item)"
                                    >
                                       <template v-slot:default="{ active }">
                                           <v-list-item-content>
                                               <v-list-item-title>
                                                    {{ item.title}}
                                               </v-list-item-title>
                                               <v-list-item-subtitle
                                                    class="text--primary"
                                               >
                                                    {{ item.subtitle }}
                                               </v-list-item-subtitle>
                                           </v-list-item-content>
                                           <v-list-item-action>
                                               <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                               <v-icon
                                                    v-else
                                                    color="primary"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                           </v-list-item-action>
                                       </template>
                                   </v-list-item>

                                   <v-divider
                                        v-if="index < items.length -1"
                                        :key="index"
                                   ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>

                    <v-overlay
                        absolute
                        :value="overlay"
                        color="grey"
                        opacity="0.6"
                   >
                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"
                            v-show="loading"
                            color="primary"
                        ></v-progress-circular>
                        
                        <v-expand-transition>                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                    <span>Select Class</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn> 
                                </v-card-title>
                                <v-list 
                                    light
                                    v-if="displayAcademicPeriod"
                                >
                                    <v-list-item>                                        
                                        <v-autocomplete
                                            v-model="academicPeriod"
                                            :items="academicPeriods"
                                            item-text="text"
                                            item-value="value"                                                
                                            dense
                                            filled
                                            color="blue-grey lighten-2"
                                            prepend-icon="mdi-calendar"
                                            hide-details=""
                                            @change="academicPeriodChanged"
                                        ></v-autocomplete>                                        
                                    </v-list-item>
                                </v-list>
                                <div style="max-height: 400px;  overflow-y: auto">    
                                    <v-list 
                                        v-if="listGroup"
                                        light
                                    >
                                        <v-list-group
                                            v-for="item in formClassesFilter"
                                            :key = "item.title"
                                            v-model="item.active"
                                            :prepend-icon="item.action"
                                            no-action
                                        >
                                            <template v-slot:activator>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-list-item
                                                v-for="subItem in item.items"
                                                :key="subItem.title"
                                                @click="setSelectedClass(subItem)"                                       
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title >
                                                        {{ subItem.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                    <v-list
                                        v-else
                                    >
                                        <v-list-item-group
                                            v-model="selectedFormLevel"
                                            color="primary"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in formLevels"
                                                :key="i"
                                                @click="setSelectedFormLevel(item)" 
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>
                                                        {{ item.icon }}
                                                    </v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </div>

                                <v-overlay
                                    absolute
                                    :value="overlaySelectClass"
                                    color="grey"
                                    opacity="0.6"
                                >
                                    <v-progress-circular 
                                        transition="fade-transition" 
                                        indeterminate 
                                        size="64"
                                        color="primary"
                                    ></v-progress-circular>
                                </v-overlay>
                            </v-card> 
                        </v-expand-transition>

                        <v-expand-transition>                            
                            <v-card
                                max-width="600"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expandSelectMarkSheet"
                                light
                            >
                                <v-card-title  class="text-subtitle-2 pa-2 white--text d-flex justify-center primary">
                                    <v-spacer></v-spacer>
                                    <span>Select Marksheet</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="closeMarkSheetOptions"
                                        class="ml-3"
                                    >
                                        <v-icon small>mdi-window-close</v-icon>
                                    </v-btn> 
                                </v-card-title>
                                <v-card-text>
                                    <v-list>
                                        <v-list-item-group color="primary">
                                            <template 
                                                v-for="(item, i) in markSheetsFilter"
                                                >
                                                <v-list-item
                                                    :key="i"
                                                    @click="setSelectedMarkSheet(item)"
                                                    class="mt-2 mark-sheet"
                                                >
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item>

                                                
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions 
                                    class="px-4"
                                    v-if="formatOptions"
                                >
                                    <v-radio-group 
                                        row
                                        v-model="markSheetFormat"
                                    >
                                        <v-radio
                                            label="PDF"
                                            value="pdf"
                                        ></v-radio>
                                        <v-radio
                                            label="Spreadsheet"
                                            value="spreadsheet"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-actions>
                            </v-card>
                        </v-expand-transition>      

                         <v-expand-transition>                            
                            <v-card
                                max-width="600"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expandSelectStudent"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                   <span>Select Student</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        :headers="headersStudents"
                                        :items="students"
                                        :search="searchStudent"
                                        :loading="loadingStudents"
                                        fixed-header
                                        height="40vh"
                                    >
                                        <template v-slot:top>
                                            <v-text-field
                                                v-model="searchStudent"
                                                label="Search Student"
                                                append-icon="mdi-magnify"
                                                single-line
                                                clearable
                                            ></v-text-field>
                                        </template>

                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-btn
                                                depressed 
                                                color="primary"
                                                @click="setSelectedStudent(item)"
                                                small
                                            >Select</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                         </v-expand-transition>            
                   </v-overlay>    
                </v-card>
        </v-col>

        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            persistent
        >
            <v-card
               height="90vh"
               style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:92%" 
                    class="report-viewer"  
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        depressed
                        color="primary"
                        @click="closeReport"
                    >
                        Close Report
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
    </v-row>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize()
    },
    
    data: () => ({
        selectedReport: null,
        markSheets: [
           
        ],
        markSheetsFilter: [],
        items: [
            { 
                title: 'Report Cards by Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'report-card-class', 
            },            
            { 
                title: 'Class Mark Sheets', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-class', 
            },            
            { 
                title: 'Class Lists', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'class-list', 
            }, 
            // { 
            //     title: 'Device / Internet Report', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'device-internet', 
            // },           
            // { 
            //     title: 'Subject Choices Marksheets By Class', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'marksheet-subject-choice-class', 
            // },            
            // { 
            //     title: 'Subject Choices Marksheets By Student', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'marksheet-subject-choice-student', 
            // },            
            // { 
            //     title: 'NCSE Marksheets', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'marksheet-ncse' 
            // },            
            // { 
            //     title: 'Registration Status Report', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'registration-status' 
            // },
            // { 
            //     title: 'School Feeding Report', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'school-feeding' 
            // },              
            // { 
            //     title: 'Student Contact Information', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'contact-information' 
            // },
            //  { 
            //     title: 'Student Ethnic Group Statistics', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'ethnic-group-statistics' 
            // },
            // { 
            //     title: 'Student Religion Statistics', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'religion-statistics' 
            // },
            // { 
            //     title: 'Student Age Statistics', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'age-statistics' 
            // },            
            // { 
            //     title: 'ASR', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'asr' 
            // },            
        ],
        overlay: false,
        loading: false,
        expand: false,
        expandSelectStudent: false,
        expandSelectMarkSheet: false,
        formClasses: [],
        formClassesFilter: [],
        academicPeriod: null,        
        academicPeriods: [],        
        reportViewer: false,
        reportViewerOverlay: false,
        src: null,
        reportSelected: null,
        formLevelFilter: null,
        displayAcademicPeriod: false,
        students: [],
        selectedStudent: null,
        headersStudents: [],
        searchStudent: '',
        loadingStudents: false,
        activeFormLevel: '',
        listGroup: false,
        formLevels: [],
        selectedFormLevel: null,
        markSheetFormat: 'pdf',
        testId: 1,
        selectedFormClass: null,
        formatOptions: false,
        forthnightlyTests: false,
        overlaySelectClass: false,
        academicYearId: null,
        assesmentTitle: null,
        assesmentId: null,
    }),

    watch: {
        markSheetFormat (val) {
            console.log(val);
        }
    },

    computed: {
        ...mapGetters({
            admin: 'auth/getAdmin',
            getUser: 'auth/getUser',
        }),

        user () {
            return JSON.parse(this.getUser);
        }
    },

    methods:{
        ...mapMutations({
            setAcademicYearId: 'printViewReports/setAcademicYearId',
        }),

        ...mapActions({
            getFormClassesList: 'termReports/getFormClassesList',
            getMarkSheetTerms: 'printViewReports/getMarkSheetTerms',
            getReportCardTerms: 'printViewReports/getReportCardTerms',
            getStudents: 'common/getCurrentStudents',
            getClassListYears: 'printViewReports/getClassListYears',
            downloadASR: 'printViewReports/downloadASR',
        }),

        async initialize () {
            this.overlay = true;
            this.loading = true;
            await this.mapFormClasses();
            this.filterFormClasses();
            if(this.admin){
                this.items.push(
                    { 
                        title: 'Registration Status', 
                        subtitle: '', 
                        icon: 'mdi-printer-search',
                        report: 'registration-status', 
                    }, 
                    { 
                        title: 'Registration Forms', 
                        subtitle: '', 
                        icon: 'mdi-printer-search',
                        report: 'registration-forms', 
                    }, 
                    { 
                        title: 'Employee Information', 
                        subtitle: '', 
                        icon: 'mdi-printer-search',
                        report: 'employee-information', 
                    }, 
                )
            }
            this.loading = false;
            this.overlay = false;
        },

        async mapFormClasses () {
            this.formClasses = [];
            this.formClassesFilter = [];
            try {
                const { data } = await this.getFormClassesList();
                if(data.length > 0){                    
                    let firstYearClasses = [];
                    let secondYearClasses = [];
                    let std1Classes = [];
                    let std2Classes = [];
                    let std3Classes = [];
                    let std4Classes = []; 
                    let std5Classes = []; 
                    data.forEach(record => {
                        let title = record.first_name[0] + ". " + record.last_name;
                        
                        // let title = record.first_name[0] + ". " + record.last_name;
                        if(record.coTeachers){
                            let coTeachersArray = [];
                            coTeachersArray.push(title);
                            // title = record.first_name[0] + ". " + record.last_name;
                            // title += " / " + record.coTeachers.join(' / ')
                            record.coTeachers.forEach(coTeacher => {
                                coTeachersArray.push(coTeacher.name);
                            })
                            title = coTeachersArray.join('/'); 
                        }
                        
                        switch(record.form_class_id){
                            case "1st Year":
                                firstYearClasses.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "2nd Year":
                                secondYearClasses.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "Std 1":
                                std1Classes.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "Std 2":
                                std2Classes.push({
                                    title: title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "Std 3":
                                std3Classes.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "Std 4":
                                std4Classes.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                            case "Std 5":
                                std5Classes.push({
                                    title,
                                    form_class_id: record.form_class_id,
                                    employee_id: record.employee_id
                                });
                                break;
                        }                
                    });
                    this.formClasses.push({
                        title: '1st Year Infants',
                        action: 'mdi-school',                
                        items: firstYearClasses
                    });
                    this.formClasses.push({
                        title: '2nd Year Infants',
                        action: 'mdi-school',                
                        items: secondYearClasses
                    });
                    this.formClasses.push({
                        title: 'Standard 1',
                        action: 'mdi-school',                
                        items: std1Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 2',
                        action: 'mdi-school',                
                        items: std2Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 3',
                        action: 'mdi-school',                
                        items: std3Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 4',
                        action: 'mdi-school',                
                        items: std4Classes
                    });
                    this.formClasses.push({
                        title: 'Standard 5',
                        action: 'mdi-school',                
                        items: std5Classes
                    });
                    // this.setLoading(false)
                    // setTimeout(() => this.setExpand(true));                          
                }               
            } catch (error) {
                console.log(error);
            }
        },

        filterFormClasses () {
            this.listGroup = true;
            this.formClassesFilter = [];
            
            if(this.admin){
                this.formClassesFilter = this.formClasses.map(
                    value => {
                        value.active = false
                        return value;
                    }
                );

                return;
            }
            
            //teachers only see their class
            this.formClasses.forEach(value => {
                value.active = false;
                let items = [];
                value.items.forEach(item => {
                    if(item.employee_id == this.user.employee_id){
                        items.push(item);
                        value.items = items;
                        this.formClassesFilter.push(value);
                    }
                })
            })
            
        },

        setFormLevels () {
            this.listGroup = false;            
            if(this.formLevels.length == 0){                
                this.formClasses.forEach(value => {
                    this.formLevels.push({
                        icon: 'mdi-school',
                        text: 'Form ' + value.formLevel,
                        value: value.formLevel
                    })
                })
            }
        },

        async displayReport(item){
            let { report } = item;
            switch(report){
                case 'report-card-class':
                    // this.filterFormClasses();
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.loading = true; 
                    this.reportSelected = report;                  
                    this.setReportCardTerms();                    
                    break
                case 'marksheet-class':
                    // this.filterFormClasses();
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.loading = true; 
                    this.markSheetMenu();                   
                    this.reportSelected = report;                                  
                    break
                case 'class-list':
                    this.overlay = true;
                    this.loading = true; 
                    // this.filterFormClasses();
                    await this.setClassListYears();
                    this.displayAcademicPeriod = true;
                    this.reportSelected = report;
                    this.loading = false;
                    setTimeout(() => {
                        this.expand = true;
                    })
                    break
                case 'marksheet-subject-choice-class':
                    this.displayAcademicPeriod = false;                    
                    this.filterFormClasses(3);
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand = true;
                    break;
                case 'marksheet-subject-choice-student':
                    this.reportSelected = report;
                    this.loadingStudents = true;
                    this.formLevelFilter = 3;
                    this.setStudents();
                    this.overlay = true;
                    this.expandSelectStudent = true;
                    break;
                case 'contact-information':
                    this.filterFormClasses();
                    this.displayAcademicPeriod = false;
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand= true;
                    break;
                 case 'school-feeding':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);
                    break; 
                case 'device-internet':
                    this.setFormLevels();
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand = true;                    
                    break;
                case 'asr':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.download();
                    break;
                case 'ethnic-group-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                  
                    break;                   
                case 'religion-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;
                case 'age-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;                         
                case 'registration-status':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;                         
                case 'registration-forms':
                    this.displayAcademicPeriod = false;
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand = true;
                    break;                         
                case 'employee-information':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report); 
                    break;                         
            }
            
        },

        async markSheetMenu(){
            try {
                const { data } = await this.getMarkSheetTerms();
                data.forEach((record) => {
                    let forthnightlyTests = false;
                    let assesmentType = "";
                    if(record.forthnightly_tests.length > 0){
                        forthnightlyTests = true;
                        assesmentType = record.forthnightly_tests[0].title;
                    } 
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id,
                        academic_year_id: record.academic_year_id,
                        forthnightlyTests,
                        assesmentType
                    });
                })
                this.academicPeriod = data[0].academic_term_id;
                this.loading = false;
                // this.expandSelectMarkSheet = true;
                this.$nextTick(function(){
                    this.expand= true;
                })
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async setReportCardTerms () {            
            try {
                const { data } = await this.getReportCardTerms();                
                data.forEach(record => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });                                       
                })
                this.academicPeriod = data[0].academic_term_id;
            } catch (error) {
                if(error.response) console.log(error.reponse);
                else console.log(error);
            }

            this.loading = false;
            this.$nextTick(function(){
                this.expand = true;
            })

        },

        async setClassListYears () {
            try {
                const { data } = await this.getClassListYears();
                data.forEach(value => {
                    this.academicPeriods.push({
                        text: value.start + '-' + value.end,
                        value: value.academic_year_id

                    })
                    this.academicPeriod = value.academic_year_id;
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        setSelectedClass(formClass){
            if(this.reportSelected == 'marksheet-class'){
                this.expand = false;
                this.selectedFormClass = formClass;
                let assesmentType = null;

                this.academicPeriods.forEach(period => {
                    if(period.value == this.academicPeriod && period.forthnightlyTests){
                        assesmentType = period.assesmentType;
                        return;
                    }
                })

                if(assesmentType == 'Forthnightly Tests'){
                    this.markSheets = [
                        { title: 'Forthnightly', test: 3, assesment_id: 1},
                        { title: 'End of Term', test: 3, assesment_id: 2},
                    ]
                }

                else if(assesmentType == 'Interim'){
                    this.markSheets = [
                        { title: 'Interim', test: 3, assesment_id: 1},
                        { title: 'End of Term', test: 3, assesment_id: 2},
                    ]                   
                } 
                
                else if(assesmentType == 'Mid Term'){
                    this.markSheets = [
                        { title: 'Mid Term', test: 3, assesment_id: 1},
                        { title: 'End of Term', test: 3, assesment_id: 2},
                    ]    
                }

                this.markSheetsFilter = [...this.markSheets];
                
                setTimeout(() => {
                    this.expandSelectMarkSheet = true;
                },700)
                
                return;
            }

            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc(this.reportSelected, formClass);         
                      
        },

        setSelectedFormLevel (formLevel) {
            console.log(formLevel);
            // this.selectedFormLevel = formLevel;
            // this.reportViewer = true;
            // this.reportViewerOverlay = true;
            // this.setReportSrc(this.reportSelected); 
        },

        setReportSrc (report, formClass = null) {
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    this.reportViewerOverlay = false;
                }
            })

            switch(report){
                case 'report-card-class':  
                    this.src = process.env.VUE_APP_API_URI + 
                    `/api/report-card?academic_term_id=${this.academicPeriod}&form_class_id=${formClass.form_class_id}&employee_id=${formClass.employee_id}`;  
                    console.log(this.src)
                    break;
                case 'marksheet-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/mark-sheet?academic_term_id="+
                    this.academicPeriod + "&form_class_id=" + formClass.form_class_id + "&employee_id=" + 
                    formClass.employee_id + "&test_id=" +
                    this.testId + "&assesment_title=" + this.assesmentTitle +"&assesment_id=" + this.assesmentId;
                    console.log(this.src)
                    break;
                case 'class-list':                    
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/class-list/" + 
                    formClass.form_class_id + "/" + formClass.employee_id + "/"
                    + this.academicPeriod;
                    break;
                case 'marksheet-subject-choice-class':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+formClass;
                    break; 
                case 'marksheet-subject-choice-student':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+formClass+"/"+this.selectedStudent;
                    break;
                case 'contact-information':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/student-contact/"+formClass;
                    break;        
                case 'school-feeding':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/school-feeding";
                    break;        
                case 'device-internet':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/device-internet/"+this.selectedFormLevel;
                    break;
                case 'ethnic-group-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/ethnic-group-statistics";
                    break;         
                case 'religion-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/religious-group-statistics";
                    break;         
                case 'age-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/student-age-statistics";
                    break;  
                case 'registration-status': 
                    this.src = process.env.VUE_APP_API_URI + "/api/registration-status-report";
                    break;            
                case 'registration-forms': 
                    this.src = process.env.VUE_APP_API_URI + "/api/registration-form?form_class=" + formClass.form_class_id + "&employee_id=" + formClass.employee_id;
                    break;  
                case 'employee-information': 
                    this.src = process.env.VUE_APP_API_URI + "/api/employee-data";
                    break;  
                
            }  
        },

        close(){
            this.expandSelectStudent = false;
            this.$nextTick(() => {
                this.expand = false;
                this.selectedReport = null;
                setTimeout(() => { this.overlay = false}, 1000);
            })
            
        },

        closeReport(){
            this.src = '';
            this.reportViewer = false;
            if(this.reportSelected == 'registration-status' || this.reportSelected == 'employee-information'){
                this.overlay = false;
                return;
            }
            this.expandSelectMarkSheet = false;
            this.expand = true;
            this.forthnightlyTests = false;
        },

        setSelectedStudent (item) {
            this.selectedStudent = item.student_id;
            this.setSelectedClass(item.form_class_id);
        },

        async setStudents () {
            this.headersStudents = [
                { text: 'ID', value: 'student_id', width: 100 },
                { text: 'Name', value: 'name' },
                { text: 'Form Class', value: 'form_class_id', width: 100 },
                { text: '', value: 'actions', width: 100, sortable: false }
            ];

            try {
                const { data } = await this.getStudents();
                this.students = data.map(value => {
                    value.name = value.first_name + ' ' + value.last_name;
                    return value;
                })
                if(this.formLevelFilter){
                    this.students = data.filter(value => {
                        if(value.form_level == this.formLevelFilter)
                        value.name = value.first_name + ' ' + value.last_name;
                        return value;
                    })
                }
                this.loadingStudents = false;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async download () {
            this.loading = true;
            try {
                const { data} = await this.downloadASR();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([data])
                );
                link.setAttribute('download', '2021-2022 Secondary Schools ASR ver. 2 UPDATED.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.overlay = false;
        }, 
        
        setSelectedMarkSheet (item) {
            this.testId = item.test;
            this.assesmentTitle = item.title;
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.assesmentId = item.assesment_id;
            this.setReportSrc('marksheet-class', this.selectedFormClass);  
        },

        async academicPeriodChanged () {
            this.academicPeriods.forEach(period => {
                if(period.value == this.academicPeriod){
                    this.academicYearId = period.academic_year_id;
                }
            })
            this.setAcademicYearId(this.academicYearId);
            this.overlaySelectClass = true;
            await this.mapFormClasses();
            this.filterFormClasses();
            this.overlaySelectClass = false;
        },

        closeMarkSheetOptions () {
            this.expandSelectMarkSheet = false;
             setTimeout(() => {
                this.expand = true;
            },700)
        }
    },
   
    
}
</script>

<style scoped>
    ::v-deep .mark-sheet.v-list-item--link {
        border: 1px solid gainsboro;
    }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{on:{"click":_vm.recordSelected}},[_c('td',[_vm._v(_vm._s(_vm.markRecord.count))]),_c('td',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.markRecord.name)+" ")]),(!_vm.grade && !_vm.assesmentCriteriaCol)?_c('td',[_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","dense":"","hide-details":"","type":"number","min":"0","max":"100","disabled":"","filled":""},model:{value:(_vm.markRecord.max_mark),callback:function ($$v) {_vm.$set(_vm.markRecord, "max_mark", $$v)},expression:"markRecord.max_mark"}}),_c('v-btn',{staticClass:"my-2",attrs:{"disabled":"","block":"","height":"20","text":""}})],1):_vm._e(),(!_vm.grade && !_vm.assesmentCriteriaCol)?_c('td',[_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","dense":"","hide-details":"","type":"number","min":"0","max":_vm.markRecord.max_mark,"oninput":"if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"},on:{"blur":_vm.recordChanged},model:{value:(_vm.markRecord.mark),callback:function ($$v) {_vm.$set(_vm.markRecord, "mark", $$v)},expression:"markRecord.mark"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"block":"","height":"20","depressed":""},on:{"click":function($event){return _vm.setAttendance(_vm.assesment, true)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.assesment.button)+" ")])]}}],null,false,1857631048)},[_c('span',[_vm._v(_vm._s(_vm.assesment.tooltip))])])],1):_vm._e(),(!_vm.grade && !_vm.assesmentCriteriaCol)?_c('td',[_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","dense":"","hide-details":"","filled":"","disabled":""},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}}),_c('v-btn',{staticClass:"my-2",attrs:{"disabled":"","block":"","height":"20","text":""}})],1):_vm._e(),(_vm.grade && !_vm.assesmentCriteriaCol)?_c('td',[_c('v-select',{attrs:{"items":_vm.gradingScheme,"item-text":"grade","item-value":"grade","hide-details":"","outlined":"","dense":""},on:{"change":_vm.recordChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.chip)?_c('v-chip',{attrs:{"close":""},on:{"click:close":_vm.closeChip}},[_vm._v(" "+_vm._s(item.grade)+" ")]):_vm._e()]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.grade)+" : "+_vm._s(data.item.range)+" ")]}}],null,false,182797224),model:{value:(_vm.markRecord.grade),callback:function ($$v) {_vm.$set(_vm.markRecord, "grade", $$v)},expression:"markRecord.grade"}})],1):_vm._e(),(_vm.assesmentCriteriaCol)?_vm._l((_vm.assesmentCriteria),function(criteria){return _c('td',{key:criteria.id},[_c('v-select',{attrs:{"items":_vm.gradingSchemeCriteria,"item-text":"grade","item-value":"grade","hide-details":"","outlined":"","dense":"","chips":"","small-chips":"","deletable-chips":""},on:{"change":function($event){return _vm.recordChanged()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.grade)+" : "+_vm._s(data.item.detail)+" ")]}}],null,true),model:{value:(_vm.markRecord.grades[criteria.id]),callback:function ($$v) {_vm.$set(_vm.markRecord.grades, criteria.id, $$v)},expression:"markRecord.grades[criteria.id]"}})],1)}):_vm._e(),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.saveStatus.saved),expression:"saveStatus.saved"}],staticClass:"mr-2",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-all ")])]}}])},[_c('span',[_vm._v("Saved")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.saveStatus.error),expression:"saveStatus.error"}],attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}])},[_c('span',[_vm._v("Error")])]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.saveStatus.saving),expression:"saveStatus.saving"}],attrs:{"indeterminate":"","color":"primary","size":"16","width":"3"}}),_c('v-btn',{staticClass:"my-2",attrs:{"disabled":"","block":"","height":"20","text":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
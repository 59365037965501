<template>
    <tr>
        <td
            v-if="editTermReport"
        >
            <v-autocomplete
                v-model="recordSubject"
                :items="subjects"
                dense
                item-text="abbr"
                item-value="id"
                hide-details
                solo
                flat
                class="text-caption"
                @change="updateRecord"
                :readonly="!editTermReport"
                :filled="!editTermReport"            
            ></v-autocomplete>           
        </td>
        <td
            v-else
        >
            {{ record.abbr }}
        </td>        
        <td v-if="!fortnightlyAssesment">
            {{ record.max_mark }}
        </td>
        <td v-if="!fortnightlyAssesment">
            {{ examMark }}
        </td>
        <td
            v-if="fortnightlyAssesment"
        >
            {{ record.max_mark1}}
        </td>
        <td
            v-if="fortnightlyAssesment"
        >
            {{ record.mark1}}
        </td>
        <td
            v-if="fortnightlyAssesment"
        >
            {{ record.max_mark2}}
        </td>
        <td
            v-if="fortnightlyAssesment"
        >
            {{ record.mark2}}
        </td>
        <!-- <td>
            {{ record.conduct }}
        </td> -->
        <td
           
        >
            {{ record.percentage}}
        </td>
        <!-- <td>
            <v-autocomplete
                v-model="recordEmployee"
                :items="employees"
                dense
                item-text="last_name"
                item-value="id"
                hide-details
                solo
                flat
                class="text-caption"
                @change="updateRecord"
                :readonly="!editTermReport"
                :filled="!editTermReport"            
            >
                <template v-slot:selection="{item}">
                    {{ item.first_name[0] + item.last_name[0]}}
                </template>

                <template v-slot:item=data>
                    {{ data.item.first_name[0] + ". " + data.item.last_name}}
                </template>
            </v-autocomplete> 
        </td> -->
        <td
            class="px-0"
        >
            <v-btn 
                icon               
                @click="deleteSubject"
                v-show="editTermReport"
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        record: Object,
        fortnightlyAssesment: Boolean,
    },
    mounted: function () { 
        this.recordSubject = this.record.subject_id;
        this.recordEmployee = this.record.employee_id;
        // console.log(this.record);
    },
    watch: {
        record: {
            handler () {                
                this.recordSubject = this.record.subject_id;
                this.recordEmployee = this.record.employee_id;                
            }
        }
    },
    computed: {
        ...mapGetters({
            subjects: 'termReports/getSubjects',
            editTermReport: 'auth/getEditTermReport',
            employees: 'termReports/getEmployees',
        }),
        courseMark(){
            if(this.record.course_attendance == 2){
                return 'Abs';
            }
            else if(this.record.course_attendance == 3){
                return 'NW';
            }
            return this.record.course_mark;
        },
        examMark(){
            if(this.record.exam_attendance == 2){
                return 'Abs';
            }
            return this.record.mark;
        }
    },
    data: () => ({
        recordSubject: null,
        recordEmployee: null,
    }),
    methods: {
        ...mapMutations({
            setDeletedTermMark: 'termReports/setDeletedTermMark',
            setOverlay: 'termReports/setOverlaySubjectRecords',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setEditedTermMark: 'termReports/setEditedTermMark',
        }),
        ...mapActions({
            deleteStudentTermMark: 'termReports/deleteStudentTermMark',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            updateTermMarkSubject: 'termReports/updateTermMarkSubject',            
        }),
        async deleteSubject () {            
            this.setOverlay(true);           
            this.setDeletedTermMark(this.record);
            try {
                let response = await this.deleteStudentTermMark();
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        },
        async updateRecord () {
            this.setOverlay(true);             
            this.setEditedTermMark({
                student_id: this.record.student_id,
                academic_term_id: this.record.academic_term_id,
                prev_subject_id: this.record.subject_id,
                new_subject_id: this.recordSubject,
                employee_id: this.recordEmployee,
            });
            try {
                let response = await this.updateTermMarkSubject();
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);   
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        },        
    }
}
</script>


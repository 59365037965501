<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="700"
    >
        <v-window
            v-model="window"
        >
            <v-window-item>
                <v-card
                    class="pa-4"
                >
                    <v-card-title>Post Reports</v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="terms"
                        fixed-header
                        height="50vh"
                        :loading="loading"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                color="primary"
                                outlined
                                @click="editViewTerm(item)"
                                small
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                >
                                    mdi-pencil
                                </v-icon>
                                Edit
                            </v-btn>
                        </template>
                    </v-data-table>

                    <v-card-actions class="">
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="close"
                            depressed
                            small
                        >Close</v-btn>
                        
                    </v-card-actions>
                </v-card>
            </v-window-item>

            <v-window-item>
                <v-card
                    class="pa-4"
                >
                    <v-card-title>{{ title }}</v-card-title>
                        <v-data-table
                        v-model="selected"
                        :headers="headersClasses"
                        :items="classes"
                        fixed-header
                        height="50vh"
                        :loading="loading"
                        :items-per-page=-1
                        show-select
                        item-key="form_class_title"
                    >
                        <template v-slot:[`item.posted`]="{ item }">
                            <v-icon
                                v-if="item.posted === 1"
                                color="green"
                            >
                                mdi-check
                            </v-icon>
                            <v-icon
                                v-else
                                color="red"
                            >
                                mdi-close
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="primary"
                            class="mr-3"
                        >Unpost Selected</v-btn>
                        <v-btn 
                            color="primary"
                            class="mr-3"
                            @click="postSelected"
                        >Post Selected</v-btn>
                        <v-btn 
                            text
                            color="red"
                            @click="window = 0"
                            outlined
                        >Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-window-item>

            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
        </v-window>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';

export default {
    components: {
        //        
    },

    created: function () {
        this.initialize();
    },    

    data: () => ({        
        overlay: false,       
        height: '500',
        selectedItem: -1,
        snackbar: false,
        snackText: '',
        snackColor: '',
        loading: false,   
        terms: [],
        classes: [],
        headers:[
            { text: 'Academic Year', value: 'year' },
            { text: 'Term', value: 'term' },
            { text: 'Posted', value: 'posted' },
            { text: 'Date Posted', value: 'date_posted'},
            { text: 'Edit', value: 'actions', sortable: false },
        ],
        headersClasses: [
            { text: 'Class', value: 'form_class_title' },
            { text: 'Posted', value: 'posted' },
            { text: 'Date Posted', value: 'date_posted'},
        ],
        window: 0,
        title: null, 
        selected: [],  
        isPosted: false,
    }),
    
    watch: {
        //
    },

    computed: {
        ...mapGetters({
            dialog: 'settings/getDialogPostReports',
        })
    },

    methods: {
        ...mapMutations({
            setDialog: 'settings/setDialogPostReports',
            setSelectedItem: 'settings/setSelectedItem',
            setPostReportsAcademicTermId: 'settings/setPostReportsAcademicTermId',
            setFormClassesNotPosted: 'settings/setFormClassesNotPosted',
        }),
        
        ...mapActions({
            getTermReports: 'settings/getTermReports',
            getFormClassesList: 'termReports/getFormClassesList',
            postTermReports: 'settings/postTermReports',
        }),

        async initialize () {
            this.loading = true;
            try {
                const promiseTermReport = this.getTermReports();
                const promiseFormClasses = this.getFormClassesList();
                const [
                    { data: dataTermReports },
                    { data: dataFormClasses }
                ] = await Promise.all([
                    promiseTermReport,
                    promiseFormClasses
                ])
                this.mapTermReports(dataTermReports);
                this.mapFormClasses(dataFormClasses);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
            this.loading = false;
        },

        mapTermReports (data) {
            let posted = null;
            let academicYearId = null;
            this.terms = [];
            data.forEach(record => {
                let date_posted = null;
                academicYearId = record.academic_year_id.toString();
                posted = 'No';
                if(record.posted === 1) posted = 'Yes';
                if(record.date_posted){
                    date_posted = format(new Date(record.date_posted), 'iii, d MMM yyyy');
                }
                this.terms.push({
                    year: academicYearId.substr(0,4) + '-' + academicYearId.substr(4),
                    term: record.term,
                    termId: record.academic_term_id,
                    posted,
                    date_posted
                })
            })
        },

        mapFormClasses (data) {
            this.classes = [];
            this.selected = [];
            data.forEach(record => {
                let formTeachers = [];
                let formTeachersIds = [];
                formTeachers.push(`${record.first_name[0]}. ${record.last_name}`);
                formTeachersIds.push(record.employee_id);
                if(record.coTeachers){
                    record.coTeachers.forEach(coTeacher => {
                        formTeachers.push(coTeacher.name);
                        formTeachersIds.push(coTeacher.employee_id);
                    })
                }
                this.classes.push({
                    form_class_title: `${record.form_class_id} - ${formTeachers.join('/')}`,
                    form_class_id: record.form_class_id,
                    form_class_teachers: formTeachersIds,
                })
            })
            // this.selected = [...this.classes];
        },

        async editViewTerm (item) {
            this.overlay = true;
            this.title = `Term ${item.term} ${item.year}`
            this.setPostReportsAcademicTermId(item.termId);
            await this.getPostReportRecords();
            this.overlay = false;
            this.window = 1;
        },

        async getPostReportRecords () {
            try {
                const { data } = await this.getTermReports();
                this.mapSelectedTerm(data);
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
        },

        postedReportsCheck () {
            this.classes.forEach(value => {
                if(value.posted === 1) this.isPosted = true;
            })
        },

        mapSelectedTerm (data) {
            const defaultRecord = data.find(item => item.form_class_id === null);
            this.classes.forEach((record, index) => {
                let date_posted = defaultRecord.date_posted ? format(new Date(defaultRecord.date_posted), 'dd-MM-yyyy hh:mm:ss a') : defaultRecord.date_posted
                let updatedRecord = {...record, posted: defaultRecord.posted, date_posted}
                // record.posted = defaultRecord.posted;
                // record.date_posted = defaultRecord.date_posted;
                data.forEach(value => {
                    if(value.form_class_id && value.form_class_id === record.form_class_id){
                       let classRecord = record.form_class_teachers.find(item => item === value.employee_id);
                       if(classRecord){
                        // record.posted  = value.posted;
                        // record.date_posted = value.date_posted;
                        date_posted = value.date_posted ? format(new Date(value.date_posted), 'dd-MM-yyyy hh:mm:ss a') : value.date_posted
                        updatedRecord = {...record, posted: value.posted, date_posted}
                       }
                    }
                })
                this.classes.splice(index, 1, updatedRecord);
            })
            this.postedReportsCheck();
            this.selected = [...this.classes];
        },

        async postSelected () {
            this.overlay = true;
            const notSelected = this.classes.filter(obj1 => {
                return !this.selected.some(obj2 => {
                    return obj1.form_class_id === obj2.form_class_id && 
                    this.isEqual(obj1.form_class_teachers, obj2.form_class_teachers)
                })
            })

            const selectedAndNotPosted = this.selected.filter(obj => {
                return obj.posted === 0
            })

            if(!this.isPosted && notSelected.length === 0){
                //no classes posted post all

            }
            else if(!this.isPosted && notSelected.length !== 0) {
                //no classes posted not all selected
                this.setFormClassesNotPosted(notSelected)
                // console.log(formClassesNotPosted)
            }
            else if(this.isPosted && notSelected.length === 0){
                //some classes posted all selected
                selectedAndNotPosted.forEach(record => {
                    record.posted = 1
                })
                console.log(selectedAndNotPosted)
                this.setFormClassesNotPosted(selectedAndNotPosted)
            }
            else if(this.isPosted && notSelected.length !== 0){
                 selectedAndNotPosted.forEach(record => {
                    record.posted = 1
                })
                console.log(selectedAndNotPosted)
                this.setFormClassesNotPosted(selectedAndNotPosted)
            }

            try {
                const response = await this.postTermReports();
                console.log(response)
                await this.getPostReportRecords();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
            this.overlay = false;
        },

        isEqual (array1, array2) {
            array1.sort();
            array2.sort();
            if(JSON.stringify(array1) === JSON.stringify(array2)){
                return true;
            }
            return false;
        },

        close () {            
            this.setDialog(false);
            this.setSelectedItem({item: -1});
            this.$nextTick(() => {
                this.saved = false;
                this.error = false;
                this.errorMessage = null;
            })
        },

    }

}
</script>
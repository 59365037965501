<template>
    <v-col>
        <v-sheet
            width="250"
        >
           <v-list>
               <template v-for="(item, index) in behaviourMatrix">
                    <v-list-item
                    :key="index"
                    >
                        <v-list-item-content
                            
                        >
                            <v-card
                                flat                                
                                style="font-size: 0.7rem" 
                                width="150"
                            >
                                {{ item.title }}
                            </v-card>                              
                        </v-list-item-content>
                        <v-card
                            flat
                            width="70"
                        >
                            <v-select
                                :items="ratings"
                                item-value="abbr"                                
                                dense
                                hide-details
                                full-width
                                outlined
                                :disabled="item.disabled"
                                :filled="item.disabled"
                                v-model="studentRecord[item.model]"
                                @input="updateRecord"                    
                            >
                                <template v-slot:selection="{item}">
                                    {{item.abbr}}
                                </template>
                                <template v-slot:item=data>
                                    {{ data.item.abbr }} - {{ data.item.text }}
                                </template>
                            </v-select>
                        </v-card>  
                   </v-list-item>
               </template>               
           </v-list>
        </v-sheet>
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
     props: {
        admin: Boolean,
    },

    data: () => ({
        ratings: [
            {text: 'Consistently', abbr: 'C'},
            {text: 'Sometimes', abbr: 'S'},
            {text: 'Rarely', abbr: 'R'},            
        ],
        behaviourMatrix: [
            {title: 'Communicates positively with others', model: 'communication', disabled: true},
            {title: 'Works co-operatively with others', model: 'cooperation', disabled: true},
            {title: 'Shows respect for others and property', model: 'respect', disabled: true},
            {title: 'Behaves responsibly', model: 'responsibility', disabled: true},
            {title: 'Displays a positive attitude', model: 'attitude', disabled: true},
            {title: 'Makes appropriate judgement', model: 'judgement', disabled: true},
            {title: 'Completes assignments', model: 'complete_assignment', disabled: true},
            {title: 'Participates in class', model: 'class_participation', disabled: true},
            {title: 'Shows self-confidence in  learning', model: 'self_confidence', disabled: true},
            {title: 'Punctual attendance to class', model: 'punctual', disabled: true},
        ]
    }),
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getStudentRecord',
            formClassAssignment: 'termReports/getFormClassAssignment', 
        })
    },
    watch: {
        studentRecord: {
            handler () {
                if(
                    this.formClassAssignment.includes(this.studentRecord.form_class_id) ||
                    this.admin
                ){
                    this.behaviourMatrix.forEach(element => {
                        element.disabled = false;
                    })                    
                }
                else{
                    this.behaviourMatrix.forEach(element => {
                        element.disabled = true;
                    })
                }
            }
        }
    },
    methods: {
         ...mapActions({
            getStudentTermDetails: 'termReports/getStudentTermDetails',            
            postStudentDetails: 'termReports/postStudentTermDetails',        
        }),
        ...mapMutations({
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaved: 'termReports/setSaved',
            setSaveError: 'termReports/setError',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating', 
        }),
        async updateRecord(){
            this.setUpdating(true);
            this.setSavedToDb(false);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);            
            try{
                await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                this.setUpdating(false);
            } catch (error) {
                console.log(error.response);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);                
            }        
        }, 
    }
}
</script>
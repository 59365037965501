<template>
    <v-container>
        <v-row
            
        >
            <div
                v-for="(item, i) in menuItems"
                :key="i"
            >
                <v-col
                    v-if="item.display"
                    class="flex-grow-0"                
                >
                    <v-hover >
                        <template v-slot:default=" { hover }">
                            <v-card
                                width="220"
                                height="150"
                                @click="display(item.option)"
                                elevation="4"
                                class="ma-4"
                            >
                                <v-card-title
                                    class="d-flex justify-center pb-0"
                                >
                                    <v-img
                                        :src="item.src"
                                        max-width="50"
                                        height="50"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-card-title>
                                <v-card-title 
                                    class="subtitle-1 d-flex justify-center"
                                >
                                    {{ item.title }}
                                </v-card-title>
                                <v-card-subtitle
                                    class="caption d-flex justify-center"
                                >
                                    {{ item.subtitle }}
                                </v-card-subtitle>
                                <v-fade-transition>
                                    <v-overlay
                                        v-if="hover"
                                        absolute
                                        color="primary"
                                    ></v-overlay>
                                </v-fade-transition>
                            </v-card>
                        </template>
                    </v-hover>
                </v-col>  
            </div>  
        </v-row>
    </v-container>    
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',

    created(){
        this.initialize();
    },

    data: () => ({
        menuItems: [
            {
                id: 1,
                icon: 'mdi-keyboard',
                title: 'Enter Marks ',
                subtitle: '',
                src: require("../assets/icons/enter-marks.png"),
                option: 'markSheet',
                display: false
            },
            {
                id: 2,
                icon: 'mdi-pencil',
                title: 'Edit/View Term Details ',
                subtitle: '',
                src: require('../assets/icons/term-reports.png'),
                option: 'termDetails',
                display: false
            },
            {
                id: 3,
                icon: 'mdi-pencil',
                title: 'Print/View Reports',
                subtitle: 'Print Report Cards, Mark Sheets, etc.',
                src: require('../assets/icons/print-view-reports.png'),
                option: 'reports',
                display: false
            },
            {
                id: 4,
                icon: 'mdi-pencil',
                title: 'Employees',
                subtitle: 'Add / Edit Teachers, Timetables',
                src: require('../assets/icons/teacher.png'),
                option: 'employees',
                display: false
            },
            {
                id: 6,
                icon: 'mdi-pencil',
                title: 'Students',
                subtitle: 'View/Edit Current Student Records',
                src: require('../assets/icons/edit-view-students.png'),
                option: 'students',
                display: false
            },
            {
                id: 7,
                icon: 'mdi-pencil',
                title: 'Reset Password',
                subtitle: 'Reset Employee Passwords',
                src: require('../assets/icons/reset-password.png'),
                option: 'resetPassword',
                display: false
            },           
            {
                id: 8,
                icon: 'mdi-pencil',
                title: 'Settings',
                subtitle: '',
                src: require('../assets/icons/settings.png'),
                option: 'settings',
                display: false
            }, 
            {
                icon: 'mdi-pencil',
                title: 'Profile',
                subtitle: '',
                src: require('../assets/icons/profile.png'),
                option: 'profile',
                display: true
            }, 
        ],
        menu: '',  
    }),

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            menuOptions: 'mainMenu/getMenuOptions',                     
        }),
        
    },
   
    methods: {
        ...mapMutations({            
            setWelcomeMessage: 'mainMenu/setWelcomeMessage',            
            setAdmin: 'auth/setAdmin',
            setEditTermMark: 'auth/setEditTermMark',
            setEditPrincipalComment: 'auth/setEditPrincipalComment',                                              
        }),

        initialize () {  
            const { last_name, permissions } = JSON.parse(this.user);   
            
            if(last_name === 'Admin') {
                this.menuItems.forEach(value => {
                    if(value.title === 'Profile') value.display = false;
                })
            }   
            permissions.forEach(permission => {
                this.menuItems.findIndex(item => {
                    if(item.id === permission.user_permission_id){
                        item.display = true;
                    }
                })
                
                if(permission.user_permission_id == 9){
                    this.setEditTermMark(true);
                    this.setAdmin(true);
                }
                else if(permission.user_permission_id == 10){
                    this.setEditPrincipalComment(true);
                    sessionStorage.setItem('editPrincipalComment', true);
                }
            })
        },
        
        display (menu) {
            console.log(menu);
            this.setWelcomeMessage(false);
            Object.keys(this.menuOptions).forEach(key => {
                if(key == menu) this.menuOptions[key] = true;
                else this.menuOptions[key] = false;
            })
            // console.log(this.menuOptions);
        }

    }
}
</script>
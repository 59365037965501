<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">
        <v-card
            width="450"
            flat            
            class="mx-auto mt-3 px-4"
        >
           
            <v-card-text
                v-for="(section, i) in fields"
                :key="i"
                class="py-0"
            >
                <v-row 
                    v-if="section.group"
                    class="font-weight-light"
                >
                    {{ section.title }}
                </v-row>
                <v-row
                    v-if="section.group"
                >                    
                    <v-col
                        class="flex-grow-0 pl-0 mr-4"
                        v-for="field in section.items"
                        :key="field.model"
                    >
                        <v-card
                            width="150"
                            height="40"
                            flat                            
                        >
                            <v-checkbox                            
                                v-model="record[field.model]"                       
                                :label="field.label"
                                class="mt-0"
                                style="font-size: 0.7rem"
                                @change="update(field.model)"                                
                            >
                                <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="field.saveProgress"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveSuccess"
                                            small
                                            color="green"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-checkbox>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!section.group"
                >
                    <v-col>
                        <v-text-field
                            :label="section.label"
                            v-model="record[section.model]"
                            v-if="section.textField"
                            class="mt-0"
                            @blur="update(section.model)"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="section.saveProgress"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="section.saveSuccess"
                                        small
                                        color="green"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="section.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                        <v-textarea
                            :label="section.label"
                            v-model="record[section.model]"
                            v-if="section.textArea"
                            rows="1"
                            auto-grow
                            class="mt-0"
                            @blur="update(section.model)"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="section.saveProgress"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="section.saveSuccess"
                                        small
                                        color="green"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="section.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-tab-item>     
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        record: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },

    watch: {
        record: {
            handler () {
                this.setFields();
            }
        }
    },

    data: () => ({
        fields: []
    }),

    methods: {
        ...mapActions({
            postDataMedical: 'students/postDataMedical',
        }),

        ...mapMutations({
            setDataMedical: 'students/setDataMedical',
        }),

        setFields () {
            this.fields = [
                {
                    title: "Immunization Records",
                    group: true,
                    items: [
                        {
                            label: "Chicken Pox",
                            model: "chicken_pox",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Diphtheria",
                            model: "diphtheria",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Hepatitis",
                            model: "hepatitis",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Measles",
                            model: "measles",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Polio",
                            model: "polio",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Rheumatic Fever",
                            model: "rheumatic_fever",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Tetanus",
                            model: "tetanus",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Typhoid",
                            model: "typhoid",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: "Yellow Fever",
                            model: "yellow_fever",
                            checkbox: true,
                            immunization: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                    ]
                },
                     
                {
                    title: "Health Conditions",
                    group: true,
                    items: [
                        {
                            label: "Asthma",
                            model: "asthma",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Diabetes",
                            model: "diabetes",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Epilepsy",
                            model: "epilepsy",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Poor Eyesight",
                            model: "poor_eyesight",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "Poor Hearing",
                            model: "poor_hearing",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                        {
                            label: "TB",
                            model: "tb",
                            checkbox: true,
                            saveProgress: false,
                            saveSuccess: false,
                            saveError: false,                    
                        },
                    ]
                },

                {
                    label: "Allergies",
                    model: "allergies",
                    textArea: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                },

                {
                    label: "Other Health Conditions",
                    model: "other",
                    textArea: true,
                    saveProgress: false,
                    saveSuccess: false,
                    saveError: false,
                }
            ]
        },

        async update (model) {
            this.setDataMedical(this.record);
            this.saveFeedback(model, 'saveProgress');
            try {
                await this.postDataMedical();
                this.saveFeedback(model, 'saveSuccess');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveFeedback(model, 'saveError');
            }
        },

        saveFeedback (model, status) {
            let [field] = this.fields.filter(field => {
                if(field.group){
                    let fieldSearch = field.items.filter(item => {
                        if(item.model == model)
                        return field;
                    })
                    if(fieldSearch.length != 0) return field;                     
                }
                if(field.model == model) return field;
            })
            
            if(field.group){
                [field] = field.items.filter(field => {
                    if(field.model == model) return field;
                })
            }

            Object.keys(field).forEach(key => {
                    if(key == 'saveProgress' || key == 'saveSuccess' || key ==  'saveError')
                    field[key] = false;
                });
                this.$nextTick(() => {
                    field[status] = true;
            })
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
        font-size: 0.8rem;
    }

    ::v-deep .v-text-field__slot textArea{
        font-size: 16px;
    }
</style>
<template>
    <v-card
        width="400"
        flat
        class="mx-auto mt-3 px-4"
    >
        <v-row
            v-for="(item, i) in fields"
            :key="i"
        >
            <v-col>
                <v-text-field
                    v-if="item.type == 'text'"
                    v-model="record[item.model]"
                    :ref="item.ref"
                    :label="item.label"
                    @blur="update"
                    hide-details
                ></v-text-field>

                <v-select
                    v-else-if="item.type == 'select'"
                    v-model="record[item.model]"
                    :ref="item.ref"
                    :label="item.label"
                    :items="item.items"
                    @blur="update"
                    hide-details
                   
                ></v-select>

                <DatePicker
                    v-else-if="item.type == 'date'"
                    :label="item.label"
                    :model="item.model"
                    :initial-date="record[item.model]"
                    v-on:date-selected="dateSelected"
                />
            </v-col>
        </v-row>

        
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import DatePicker from './DatePickerProfile.vue'
export default {
    components: {
        DatePicker,
    },
    props: {
        initialRecord: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    watch: {
        initialRecord: {
            deep: true,
            handler (val) {
                this.record = {...val}
            }
        }
    },

    data(){
        return {
            record: {
                title: null,
                first_name: null,
                last_name: null,
                other_name: null,
                gender: null,
                date_of_birth: null,
                marital_status: null,
            },
            fields: [
                {label: 'Title', model: 'title', type: 'select', items: ['Mr.', 'Mrs.', 'Ms.'], ref: 'title' },
                {label: 'First Name', model: 'first_name', type: 'text', ref: 'first_name' },
                {label: 'Last Name', model: 'last_name', type: 'text', ref: 'last_name' },
                {label: 'Other Name(s)', model: 'other_name', type: 'text', ref: 'other_name' },
                {label: 'Sex', model: 'gender', type: 'select', items: ['Male', 'Female'], ref: 'gender' },
                {label: 'Date of Birth', model: 'date_of_birth', type: 'date', ref: 'date_of_birth'},
                {label: 'Marital Status', model: 'marital_status', type: 'select', items: ['Single', 'Married', 'Separated', 'Divorced', 'Widowed'], ref: 'martial_status'},
            ]
        }
    },

    methods: {
        ...mapMutations({
            setEmployeePersonal: 'profile/setEmployeePersonal',
        }),

        update () {
            this.setEmployeePersonal(this.record);
        },

        dateSelected(value) {
            this.record[value.model] = value.date;
            this.update();
        }
    }
}
</script>
<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            <v-card-title
                class="primary white--text"                
            >
                <v-btn
                    fab
                    small
                    color="primary"
                    dark
                    depressed
                    class="mr-2"
                    @click="previous"
                    v-if="admin"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                Select Subject Class
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="close"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            
            <v-card-text
                style="height: 60vh; overflow-y: scroll;"
                class="px-0"
            >
                <v-list 
                    light              
                >
                    <v-list-item-group >
                        <v-list-item
                            v-for="(item, i) in teacherLessons"
                            :key="i"
                            @click="selectTest(item)"
                        >
                            <v-list-item-avatar
                                v-if="item.displayIcon"
                            >
                                <v-icon class="primary white--text">
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title >
                                    {{ item.lesson }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
        </v-card>
    </v-expand-transition>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default { 
    props: {
        test: Number,
        lessonSelected: {
            type: Object,
            default () {
                return {
                    lesson: "",
                    test: "",
                }
            }
        },
        forthnightlySubjects: Array,
        teacherClass: String,
        formClassAssesment: {
            type: String,
            default: null
        },
    },

    created: function () {        
        this.initialize();
    },

    data: () => ({        
        records: [],
        expand: false,
    }),

    computed: {
        ...mapGetters({            
            academicTermId: 'app/getAcademicTermId',
            getUser: 'auth/getUser',
            teacherLessons: 'markSheet/getTeacherLessons',
            selectedTeacher: 'markSheet/getSelectedTeacher',
            admin: 'auth/getAdmin',
            markSheetTest: 'markSheet/getTest',
            term: 'app/getTerm',
        }),

        user () {
            return JSON.parse(this.getUser);
        }, 

    },
    
    watch: {
        test: {
            handler (val) {
                this.setLesson(val);
            }
        },

        lessonSelected: {
            handler (val) {
                this.setLesson(val.test);
            },
            deep: true,            
        }
    },

    methods: {
        ...mapActions({
           getLessonRecords: 'markSheet/getLessons',
           getStudents: 'markSheet/getStudents',
           getPresetCommentsList: 'markSheet/getPresetCommentsList',
           getTermConfiguration: 'termReports/getTermConfiguration',
           getSubjectAssesmentCriteria: 'markSheet/getSubjectAssesmentCriteria',
           getTermAssesmentAssignment: 'markSheet/getTermAssesmentAssignment',            
        }),

        ...mapMutations({
            setMarksheetLoading: 'markSheet/setMarkSheetLoading',
            setLessonSelected: 'markSheet/setLessonSelected',
            setTeacherLessons: 'markSheet/setTeacherLessons',
            setTermMarkRecords: 'markSheet/setTermMarkRecords',
            setTotalStudents: 'markSheet/setTotalStudents',
            setPresetComments: 'markSheet/setPresetComments',
            setModal: 'app/setModal',
            setModalTitle: 'app/setModalTitle',
            setModalIcon: 'app/setModalIcon',
            setModalMessage: 'app/setModalMessage',
            setCourseWorkOnly: 'termReports/setCourseWorkOnly',
            setDisplayTeachers: 'markSheet/setDisplayTeachers', 
            setDisplayLessons: 'markSheet/setDisplayLessons',
            setOverlay: 'markSheet/setMarkSheetOverlay',
            setAssesmentCriteria: 'markSheet/setAssesmentCriteria',
            setSelectedFormClass: 'markSheet/setSelectedFormClass',
        }),

        async initialize () {
            this.setMarksheetLoading(true);  
            let employeeID = this.selectedTeacher ? this.selectedTeacher.id : this.user.employee_id;
            let teacherClass = this.teacherClass || this.selectedTeacher.form_class_id
            if(this.formClassAssesment && this.formClassAssesment !== "Grade"){
                this.setTeacherLessons(this.forthnightlySubjects.map(value => {
                    value.lesson = `${teacherClass} ${value.title}`;
                    value.className = teacherClass;
                    value.subjectCode = value.subject_id;
                    value.subjectTitle = value.title;
                    value.form_class_id = teacherClass;
                    value.icon = 'mdi-account-group';
                    value.displayIcon = true
                    return value;
                }))

                this.setMarksheetLoading(false);
                setTimeout(() => {
                    this.expand = true;
                }, 600)
                return;

            }

            try {                
                const { data } = await this.getLessonRecords(employeeID);
                this.setTeacherLessons([]); 
                this.setSelectedFormClass(this.teacherClass);
                data.forEach(lessonRecord =>{
                    let subject = lessonRecord.title;
                    this.teacherLessons.push({
                        lesson: lessonRecord.form_class_id + ' ' + subject,
                        className: lessonRecord.form_class_id,                    
                        subjectCode: lessonRecord.subject_id,
                        subjectTitle: subject,
                        formLevel: lessonRecord.form_level,
                        max_mark: lessonRecord.max_mark,
                        form_class_id: lessonRecord.form_class_id,
                        icon: 'mdi-account-group',
                        displayIcon: true,
                    })
                })
                
                this.setTeacherLessons(this.teacherLessons);
                           
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.displayError(error);
            }            
            this.setMarksheetLoading(false);
            setTimeout(() => {
                this.expand = true;
            }, 600) 

        },
        
        async selectTest (lesson) {
            this.expand = false;
            this.setLessonSelected(lesson);
            if(
               this.formClassAssesment == "Grade"
            ){
                await this.setSubjectAssesmentCriteria();
                this.setLesson();
                return;
            }

            this.setLesson();

        },

        async setSubjectAssesmentCriteria () {
            try {
                const { data } = await this.getSubjectAssesmentCriteria();
                this.setAssesmentCriteria(data);
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
        },


        async setLesson(test = 3){
            console.log('setting lesson...')
            this.expand = false;
            let employeeId = this.selectedTeacher ? this.selectedTeacher.id : this.user.employee_id;
            this.setDisplayLessons(false);
            // if(this.markSheetTest?.forthnightly_test_id) test = 3;
            setTimeout(() => {
                this.setMarksheetLoading(true);
            }) 
            this.setTermMarkRecords([]);
            
            try {
                const promiseStudents = this.getStudents({
                    academicTermId: this.academicTermId, 
                    employeeId: employeeId,
                    test: test,
                    forthnightlyTestId: this.markSheetTest?.forthnightly_test_id
                });

                const { data: { total, data }}
                = await  promiseStudents;
                // console.log(data);
                this.updateTermMarkRecords(data);
                this.setTotalStudents(total);
                             
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.displayError(error);
            }
            this.setMarksheetLoading(false);
            setTimeout(() => {
                this.setOverlay(false);
            }, 700)
        },

        updateTermMarkRecords(records){
            let termMarkRecords = [];
            let count = 0;
            records.forEach(record =>{
                record.count = ++count;
                record.name =  record.first_name + ' ' + record.last_name;
                termMarkRecords.push(record);
            })
             this.setTermMarkRecords(termMarkRecords);
        },        

        displayError(error){
            this.setModalIcon('error');
            this.setModalMessage(error);
            this.setModal(true);
        },

        previous () {
            this.expand = false;
            this.setDisplayLessons(false);
            setTimeout(() => {
                this.setDisplayTeachers(true);
            }, 600)

        },

        close () {
            this.expand = false;
            setTimeout(() => {
                this.$emit('display-tests');
            }, 600)
        }


    }
}
</script>
<template>
    <v-dialog
        :value="dialog"
        max-width="900px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                @click="dialog=true"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
            >
                View Attendance
            </v-btn>                            
        </template>

        <!-- <div style="position:relative; height:40px">
            <v-btn fab dark small color="red" style="position:absolute">
                <v-icon dark>mdi-close</v-icon>
            </v-btn>
        </div> -->

        <v-card pa-3 flat width="900px" >
            <v-system-bar  window color="primary" dark>
                <v-spacer></v-spacer>
                <v-btn small icon @click="dialog=!dialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>

            <v-toolbar color="primary" dark lights-out>
                <v-toolbar-title> Student Attendance - {{ formattedDate }} </v-toolbar-title>
            </v-toolbar>

            <v-container fluid>
                <v-row>
                    <v-col cols="8">
                        <v-card  flat>
                            <v-card-title class="font-weight-black primary--text">Student Attendance - {{ formattedDate }} </v-card-title>
                            <v-card-text>                    
                                <ChartBar
                                    v-if="dataLoaded"
                                    :chart-data="chartData"
                                ></ChartBar>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn text color="primary">Total Attendance: {{ totalAttendance }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary">Late Attendance: {{ lateAttendance }}</v-btn>
                            </v-card-actions>

                            <v-overlay
                                absolute
                                :value="overlay"                    
                                color="grey lighten-5"
                                opacity="0.9" 
                            >
                                <v-progress-circular
                                    indeterminate 
                                    size="64"                        
                                    color="primary"
                                ></v-progress-circular>
                            </v-overlay>
                        </v-card>
                    </v-col>

                    <v-col
                        cols="4"
                        class="d-flex align-end flex-column"
                    >
                        <v-date-picker
                            label="Select Date Range"
                            v-model="selectedDate"
                            elevation="6"
                            @change="setDate"
                            full-width
                        ></v-date-picker>
                        <v-btn
                            outlined
                            block
                            class="mt-auto flex-grow-0"
                            height="40px"
                            @click="closeAttendance"
                        >
                            Close Attendance
                        </v-btn>
                    </v-col>
                </v-row> 

            </v-container>




        </v-card>              
    </v-dialog>    
</template>

<script>
import ChartBar from './ChartBar.vue'
import { format, parseISO } from 'date-fns';
import { mapActions, mapMutations } from 'vuex';
export default {
    components: {
        ChartBar,
    },

    created () {
        // console.log('created')
        // this.initialize();
        
    },

    watch: {
        dialog: function (val) {
            if(val) this.initialize();
        }
    },

    data: () => ({
        dialog: false,
        selectedDate: null,
        dataLoaded: false,
        chartData: {
            labels: ['1st Year', '2nd Year', 'Std 1', 'Std 2', 'Std 3', 'Std 4', 'Std 5'],
            datasets: []
        },
        overlay: false,
        totalAttendance: '--',
        lateAttendance: '--',
        formattedDate: null,
    }),

    methods: {
        ...mapActions({
            getAttendanceSummaryData: 'studentAttendance/getAttendanceSummaryData',
        }),

        ...mapMutations({
            setAttendanceDate: 'studentAttendance/setAttendanceDate',
        }),

        initialize () {
            const todayFormatted = format(new Date(), 'yyyy-MM-dd');
            this.setAttendanceDate(todayFormatted);
            this.formattedDate = format(new Date(), 'EEEE, d LLLL yyyy');
            this.selectedDate = todayFormatted;
            this.getChartData();
        },

        setDate () {
            // this.setAttendanceSummaryDates(this.dateRange);
            // this.$emit('set-date-range', this.dateRange);
           
            // let selectedDate = this.dateRange[0];
            // if(this.dateRange.length > 1){
            //     if(this.compareDates(this.dateRange[0], this.dateRange[1]) > 0){
            //         //first date bigger than second
            //         selectedDate = this.dateRange[1]
            //     }
            // } 
            this.formattedDate = format(parseISO(this.selectedDate), 'EEEE, d LLLL yyyy');
            this.setAttendanceDate(this.selectedDate)
            this.getChartData();            
        },

        async getChartData () {
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceSummaryData();
                if(!Array.isArray(data)) 
                this.mapChartData(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        mapChartData (data)
        {
            let dataSets = [];
            let dataPresent = [];
            let dataAbsent = [];
            this.chartData.datasets = [];
            const [startDate] = Object.values(data);
            const { attendance_records } = startDate;
            const { attendance_school: { attendance: attendanceSchool } } = startDate;
            const { attendance_late: { attendance: attendanceLate }} = startDate;

            this.totalAttendance = attendanceSchool;
            this.lateAttendance = attendanceLate;
            Object.keys(attendance_records).forEach(formLevel => {
                dataPresent.push(attendance_records[formLevel].attendance);
                dataAbsent.push(attendance_records[formLevel].total - attendance_records[formLevel].attendance)
            })

            dataSets.push(
                {
                    label: 'Present',
                    data: dataPresent,
                    // backgroundColor:'rgba(54, 162, 235, 0.2)',
                    backgroundColor:'rgba(48, 88, 172, 0.4)',
                }
            )
            dataSets.push(
                {
                    label: 'Absent',
                    data: dataAbsent,
                    backgroundColor: 'rgba(190, 190, 190, 0.2)',                           
                }
            )
            this.chartData.datasets = dataSets;
            this.dataLoaded = true;
        },

        clearDates ()
        {
            this.dateRange = [];
        },

        closeAttendance ()
        {
            this.dialog = false;
        }


    }
}
</script>

<style scoped>
    .v-system-bar--window .v-icon{
        margin-right: 0px;
    }
</style>
<template>
    <v-card
        class="pa-6 mt-4 mx-auto" 
        width="700"
        flat
        outlined
        elevation="4"
        color="secondary"
    >  
        <v-row 
            no-gutters justify="center" 
            align="center"
            v-if="!src"
        >
            <v-col>
                <v-text-field
                    v-if="!fileLabel"
                    v-model="fileDescription"
                    label="Document Type (e.g. Marriage Certificate)"
                    placeholder=" " 
                    class="mb-3"
                    :rules="[() =>  !!fileDescription || 'Please enter a Document Type']"
                    ref="fileDescription"
                ></v-text-field>
                <v-file-input
                    show-size
                    :label="fileLabel"
                    @change="selectFile"
                    outlined
                    dense
                    placeholder="Click to upload file"
                    prepend-icon=""
                    ref="fileInput"
                ></v-file-input>
            </v-col>
        </v-row>

        <v-row
            v-if="currentFile && !src"
            
        >
            <v-progress-linear
                v-model="progress"
                color="light-blue"
                height="25"
                reactive
                
            >
                <strong>{{ progress }} %</strong>
            </v-progress-linear>
        </v-row>

        <v-alert 
            class="mt-4"
            v-if="message" 
            :type="alertType"
            border="left" 
            dark
        >
            {{ message }}
        </v-alert>

        <v-row
            v-if="src"           
        >
            <v-col
                elevation="0"
                cols="12"
            >
                
                <v-btn
                    text
                    outlined
                    @click="displayFilePreview()"
                    block                        
                >
                    {{ fileDescription }}
                </v-btn> 
            </v-col>
        </v-row>

        <v-row
            v-if="src" 
        >    
            <v-spacer></v-spacer>
            <v-col> 
                <v-btn                        
                    text
                    outlined
                    small
                    @click="deleteFile"
                    class="mr-4"
                >
                    Delete File
                </v-btn>
            </v-col>
            <v-col class="pr-0">    
                <v-btn                        
                    text
                    outlined
                    small
                    @click="resubmitFile"
                >
                    Resubmit File
                </v-btn>
                
            </v-col>
            
        </v-row>

        <v-dialog
            v-model="filePreview"
            :max-width="maxWidth"
            persistent
        >   
            <v-card
                v-if="!photoPreview"
                height="80vh" 
            >
                <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>                
            </v-card>

            <v-img
                v-else
                max-width="200"
                :src="src"
                class="mx-auto ma-6"
            ></v-img>

            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        
                        <v-btn                                            
                            color="primary"
                            dark
                            @click="closeFilePreview"                                            
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                        
                    </v-row>
                </v-sheet>
            </v-container>                        
        </v-dialog>
    </v-card>
</template>

<script>
import UploadService from "../services/UploadFileService";
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    name: "upload-files",

    props: {
        fileType: String,
        fileLabel: String,
        employeeId: Number,
        initialUrl: String,
        initialFileId: Number,
        file: Object,
    },

    watch: {
        fileLabel: {
            handler (val) {
                console.log(val)
            }
        }
    },

    mounted: function (){
        this.initialize();
    },
    
    computed: {
        ...mapGetters({
            studentId: 'auth/getUserID'
        }),

    },

    data: () => ({
        currentFile: undefined,
        progress: 0,
        message: "",
        filePreview: false,
        photoPreview: false,
        src: null,
        maxWidth: '',
        fileId: null,
        alertType: 'success',
        fileDescription: null,
    }),

    methods: {
        ...mapActions({
            deleteEmployeeFile: 'profile/deleteFile',
        }),

        ...mapMutations({
            setEmployeeFileId: 'profile/setEmployeeFileId',
        }),

        async initialize () {
            if(this.initialUrl){
                this.src = this.initialUrl
            }
            if(this.initialFileId){
                this.fileId = this.initialFileId
            }
            if(this.file.label) this.fileDescription = this.file.label
            // this.fileDescription = this.file.label
        },

        selectFile (file) {
            this.progress = 0;
            this.currentFile = file;
            this.upload();
        },

        upload () {
            if (!this.currentFile) {
                this.alertType = 'error'
                this.message = "Please select a file!";
                return;
            }

            this.message = "";       
            
            if(!this.fileDescription){
                this.$refs['fileDescription'].validate(true)
                this.$refs['fileInput'].reset()
                this.currentFile = null
                return;
            }

            UploadService.upload(this.currentFile, this.employeeId, this.fileType, 'employees', (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            }, this.fileDescription)
                .then((response) => {
                    this.currentFile = undefined;
                    this.message = response.data.message;
                    setTimeout(() => {
                        this.src = response.data.url
                        this.message = null;
                        this.fileId = response.data.file.id
                    }, 600);
                })
                .catch((error) => {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.progress = 0;
                    this.alertType = 'error'
                    this.message = error?.response.data?.errors?.file[0] || "Could not upload the file!";
                    this.currentFile = undefined;
                } )
        },

        displayFilePreview () {
            this.maxWidth = "90vw";
            this.filePreview = true;
        },

        closeFilePreview () {
            this.filePreview = false;
        },

        resubmitFile () {
            // this.fileInfos = [];
            this.src = null;
            this.currentFile = undefined;
            this.message = null;
        },

        async deleteFile () {
            this.src = null;
            this.setEmployeeFileId(this.fileId);
            try {
                const { data } = await this.deleteEmployeeFile();
                this.alertType = 'success'
                this.message = data.message
                this.$emit('delete-file', this.file)
                setTimeout(() => {
                    this.message = ""
                    this.src = ""
                    this.fileDescription = ""
                    this.$refs['fileDescription'].reset()
                }, 800)
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
        }
    }
}
</script>